/* global window, $, ic, ac, googletag, pbjs */
'use strict';

(function(window) {
    'use strict';
    var hasInit, hasPrebidInit, lastHover,
        guidSeed = 0;

    // we rely on Promises here
    if (typeof Promise === 'undefined') {
        require('promises', false);// include synchronously
    }

    var opts = {
        // Default DFP Premium account for DMV.org licensed by Operative
        //defaultAccount: 102313759,
        // GAM Small-Business account operated by DMV.org
        defaultAccount: 421496808,
        // Set page-level attributes by setting this BEFORE calling dfp() for the first time
        pageTargeting: {},

        // PreBidJS configuration
        // FYI: "districtm" is an alias of "appnexus"
        // Build w/ cd Prebid.org && ./node_modules/.bin/gulp build-bundle-dev --modules=appnexusBidAdapter,ixBidAdapter,criteoBidAdapter,aolBidAdapter,sovrnBidAdapter,rhythmoneBidAdapter,openxBidAdapter,rubiconBidAdapter,pubmaticBidAdapter
        preBidTimeout: 1000,

        // Automatically refresh all slots after X seconds
        autoRefreshTimeout: 30 * 1000,
        autoRefreshLimit: 30,
        autoRefreshOnVisible: false,
        autoRefreshExcludeAdvertisers: [
            4481912587, // Esurance
            4503458779, // State Farm
            38526759, //The Control Group
        ],

        // prebidAnalytics: {
        //     provider: 'roxot',
        //     options: { publisherIds: ['1c10a452-dd5c-4ada-a870-7d84d003e2ce'] }
        //     // another option:
        //     // provider: 'ga', // no additional params, will use global instance ga()
        // },

        prebidConfig: {
            // sends hb_pb_<advertiser> targeting keys, requires special DFP setup
            enableSendAllBids: true,
            // "highly recommended" by OpenX
            userSync: { iframeEnabled: true },
            // Mapping delivered via Asana ticket from Adam
            priceGranularity: {
                buckets: [
                    { min:  0, max:  6, increment: 0.01 /*,precision:2 <<default */ },
                    { min:  6, max: 10, increment: 0.05 },
                    { min: 10, max: 15, increment: 0.10 },
                    { min: 15, max: 20, increment: 0.25 },
                    { min: 20, max: 30, increment: 0.50 }
                ]
            }
        },

        prebidBidderConfig: {
            // @see http://prebid.org/dev-docs/examples/adjust-price.html
            districtm: {
                bidCpmAdjustment: function(bidCpm) {
                    return bidCpm * 0.9;// this is a rev-share partner
                }
            }
            // IndexExchange
            // not used: ix: { firstPartyData: { category:'News' }, timeout: 800 }
        },

        // Global Header-Bidder configuration as relayed by Adam/Marlon
        bidderConfig: {
            // "<adapter_name>": {
            //   "_all": { <params to merge into every object> }
            //   "<placement_name>": true (activated), false|null|undefined (skipped), <object> (hash of sizes)
            //       { "< size | * >": function(size,globalParams), <object> (hash of options/IDs) }
            // }

            // @see http://prebid.org/dev-docs/bidders/appnexus.html
            appnexus: {
                Top: {
                    '160x600': { placementId: 12822545 },
                    '300x250': { placementId: 12822547 },
                    // '300x50' : { placementId: 12822550 },
                    // '300x600': { placementId: 12822552 },
                    // '320x100': { placementId: 12822555 },
                    '320x50' : { placementId: 12822558 },
                    '728x90' : { placementId: 12822562 },
                    // '970x90' : { placementId: 12822563 },
                    // '970x250': { placementId: 12822564 }
                },
                Inline: {
                    '300x250': { placementId: 12822548 },
                    // '300x50' : { placementId: 12822551 },
                    // '320x100': { placementId: 12822556 },
                    '320x50' : { placementId: 12822559 },
                    '728x90' : { placementId: 12822561 }
                },
                Button: {
                    '160x600': { placementId: 12822544 },
                    '300x250': { placementId: 12822546 },
                    // '300x50' : { placementId: 12822549 },
                    // '300x600': { placementId: 12822553 },
                    // '320x100': { placementId: 12822554 },
                    '320x50' : { placementId: 12822557 },
                    '728x90' : { placementId: 12822560 },
                    // '970x90' : { placementId: 12822565 }
                }
            },

            // @see https://github.com/prebid/Prebid.js/blob/master/modules/ixBidAdapter.md
            ix: {
                Top: {
                    '160x600': { siteId: '254323' },
                    '300x250': { siteId: '254324' },
                    // '300x600': { siteId: '254327' },
                    //No ID: '300x50': { siteId:?? },
                    // '320x100': { siteId: '254339' },
                    '320x50' : { siteId:'254339' },
                    '728x90' : { siteId: '254329' },
                    // '970x90' : { siteId: '254332' },
                    '970x250': { siteId: '254334' }
                },
                Inline: {
                    '300x250': { siteId: '254326' },
                    //No ID: '300x50': { siteId:?? },
                    // '320x100': { siteId: '254341' },
                    '320x50' : { siteId: '254341' },
                    '728x90' : { siteId: '254331' }
                },
                Bottom: {
                    '160x600': { siteId: '254322' },
                    '300x250': { siteId: '254325' },
                    // '300x600': { siteId: '254328' },
                    //No ID: '300x50': { siteId:?? },
                    // '320x100': { siteId: '254340' },
                    '320x50' : { siteId: '254340' },
                    '728x90' : { siteId: '254330' },
                    // '970x90' : { siteId: '254333' },
                    '970x250': { siteId: '254335' }
                }
            },

            // @see http://prebid.org/dev-docs/bidders/criteo.html
            criteo: {
                Top: {
                    '160x600': { zoneId: 1155415 },
                    '300x250': { zoneId: 1155417 },
                    //Do not use: '300x50' : { zoneId: 1155420 },
                    // '300x600': { zoneId: 1155422 },
                    // '320x100': { zoneId: 1155427 },
                    //Do not use: '320x50' : { zoneId: 1155430 },
                    '728x90' : { zoneId: 1155435 },
                    '970x90' : { zoneId: 1155437 },
                    '970x250': { zoneId: 1155438 }
                },
                Inline: {
                    '300x250': { zoneId: 1155418 },
                    // '300x50' : { zoneId: 1155421 },
                    // '320x100': { zoneId: 1155428 },
                    '320x50' : { zoneId: 1155432 },
                    '728x90' : { zoneId: 1155434 }
                },
                Bottom: {
                    '160x600': { zoneId: 1155414 },
                    '300x250': { zoneId: 1155416 },
                    // '300x50' : { zoneId: 1155419 },
                    // '300x600': { zoneId: 1155423 },
                    // '320x100': { zoneId: 1155424 },
                    '320x50' : { zoneId: 1155429 },
                    '728x90' : { zoneId: 1155433 },
                    '970x90' : { zoneId: 1155439 },
                    '970x250': { zoneId: 1155441 }
                }
            },

            aol: {
                _all: { network: '10075.1' },
                Top: {
                    '160x600': { placement: 4771438 },
                    '300x250': { placement: 4771441 },
                    // '300x50' : { placement: 4771461 },
                    // '300x600': { placement: 4771453 },
                    // '320x100': { placement: 4771464 },
                    '320x50' : { placement: 4771463 },
                    '728x90' : { placement: 4771457 },
                    '970x90' : { placement: 4771445 },
                    '970x250': { placement: 4771435 }
                },
                Inline: {
                    '300x250': { placement: 4771436 },
                    // '300x50' : { placement: 4771471 },
                    // '320x100': { placement: 4771474 },
                    '320x50' : { placement: 4771466 },
                    '728x90' : { placement: 4771433 }
                },
                Bottom: {
                    '160x600': { placement: 4771443 },
                    '300x250': { placement: 4771442 },
                    // '300x50' : { placement: 4771460 },
                    // '300x600': { placement: 4771440 },
                    // '320x100': { placement: 4771462 },
                    '320x50' : { placement: 4771470 },
                    '728x90' : { placement: 4771451 },
                    '970x90' : { placement: 4771444 },
                    '970x250': { placement: 4771455 }
                }
            },

            /* Disabled Oct-2018, replaced by Rubicon/Pubmatic
            sovrn: {
                Top: {
                    '160x600': { tagid: 550089 },
                    '300x250': { tagid: 550091 },
                    //No ID: '300x50' : { tagid: ?? },
                    '300x600': { tagid: 550093 },
                    //No ID: '320x100': { tagid: ?? },
                    '320x50' : { tagid: 550095 },
                    '728x90' : { tagid: 550099 }
                    //No ID: '970x90' : { tagid: ?? },
                    //No ID: '970x250': { tagid: ?? },
                },
                Inline: {
                    '300x250': { tagid: 550092 },
                    //No ID: '300x50' : { tagid: ?? },
                    //No ID: '320x100': { tagid: ?? },
                    '320x50' : { tagid: 550097 },
                    '728x90' : { tagid: 550100 }
                },
                Bottom: {
                    '160x600': { tagid: 550088 },
                    '300x250': { tagid: 550090 },
                    //No ID: '300x50' : { tagid: ?? },
                    '300x600': { tagid: 550094 },
                    //No ID: '320x100': { tagid: ?? },
                    '320x50' : { tagid: 550096 }
                    //No ID: '728x90' : { tagid: ?? },
                    //No ID: '970x90' : { tagid: ?? },
                    //No ID: '970x250': { tagid: ?? },
                }
            },
            */

            /* Disabled Oct-2018, replaced by Rubicon/Pubmatic
            rhythmone: {
                _all: { placementId: 74435 },
                Top: {
                    '*': {}
                    // They accept any size
                    // '160x600': {},
                    // '300x250': {},
                    // '300x50' : {},
                    // '300x600': {},
                    // '320x100': {},
                    // '320x50' : {},
                    // '728x90' : {},
                    // '970x90' : {},
                    // '970x250': {},
                },
                Inline: {
                    '*': {}
                    // They accept any size
                    // '300x250': {},
                    // '300x50' : {},
                    // '320x100': {},
                    // '320x50' : {},
                    // '728x90' : {},
                },
                Bottom: {
                    '*': {}
                    // They accept any size
                    // '160x600': {},
                    // '300x250': {},
                    // '300x50' : {},
                    // '300x600': {},
                    // '320x100': {},
                    // '320x50' : {},
                    // '728x90' : {},
                    // '970x90' : {},
                    // '970x250': {},
                }
            },
            */

            districtm: {
                Top: {
                    '160x600': { placementId: 12798635 },
                    '300x250': { placementId: 12798647 },
                    // '300x50' : { placementId: 12798664 },// has duplicate
                    // '300x600': { placementId: 12798658 },
                    // '320x100': { placementId: 12798664 },// has duplicate
                    '320x50' : { placementId: 12798664 },// has duplicate
                    '728x90' : { placementId: 12798673 },
                    // '970x90' : { placementId: 12798674 },
                    // '970x250': { placementId: 12798678 }
                },
                Inline: {
                    '300x250': { placementId: 12798651 },
                    // '300x50' : { placementId: 12798665 },// has duplicate
                    // '320x100': { placementId: 12798665 },// has duplicate
                    '320x50' : { placementId: 12798665 },// has duplicate
                    '728x90' : { placementId: 12798671 }
                },
                Bottom: {
                    '160x600': { placementId: 12798632 },
                    '300x250': { placementId: 12798639 },
                    // '300x50' : { placementId: 12798662 },// has duplicate
                    // '300x600': { placementId: 12798660 },
                    // '320x100': { placementId: 12798662 },// has duplicate
                    '320x50' : { placementId: 12798662 },// has duplicate
                    '728x90' : { placementId: 12798670 },
                    // '970x90' : { placementId: 12798679 },
                    // '970x250': { placementId: 12798680 }
                }
            },

            openx: {
                _all: { delDomain: 'dmv-org-d.openx.net' },
                Top: {
                    '160x600': { unit: 539821768 },
                    '300x250': { unit: 539821772 },
                    // '300x50' : { unit: 539821791 },
                    // '300x600': { unit: 539822051 },
                    // '320x100': { unit: 539822057 },
                    '320x50' : { unit: 539822062 },
                    '728x90' : { unit: 539822068 }
                    //No ID: '970x90' : { unit: ?? },
                    //No ID: '970x250': { unit: ?? },
                },
                Inline: {
                    '300x250': { unit: 539821775 },
                    // '300x50' : { unit: 539822013 },
                    // '320x100': { unit: 539822059 },
                    '320x50' : { unit: 539822064 },
                    '728x90' : { unit: 539822080 }
                },
                Bottom: {
                    '160x600': { unit: 539821767 },
                    '300x250': { unit: 539821769 },
                    // '300x50' : { unit: 539821789 },
                    // '300x600': { unit: 539822072 },
                    // '320x100': { unit: 539822056 },
                    '320x50' : { unit: 539822060 },
                    '728x90' : { unit: 539822069 }
                    //No ID: '970x90' : { unit: ?? },
                    //No ID: '970x250': { unit: ?? },
                }
            },

            rubicon: {
                _all: { accountId: 13646, siteId: 184146 },
                Top: {
                    '*': { zoneId: 897314 }
                },
                Inline: {
                    '*': { zoneId: 897316 }
                },
                Bottom: {
                    '*': { zoneId: 897312 }
                }
            },

            pubmatic: {
                _all: { publisherId: '157410' },
                Top: {
                    '160x600': { adSlot: '1609155@160x600' },
                    '300x250': { adSlot: '1609169@300x250' },
                    // '300x50':  { adSlot: '1620723@300x50' },
                    '300x600': { adSlot: '1609170@300x600' },
                    // '320x100': { adSlot: '1620729@320x100' },
                    '320x50':  { adSlot: '1620732@320x50' },
                    '728x90':  { adSlot: '1609171@728x90' },
                    // '970x90':  { adSlot: '1609172@970x90' },
                    // '970x250': { adSlot: '1609173@970x250' }
                },
                Inline: {
                    '300x250': { adSlot: '1609166@300x250' },
                    // '300x50':  { adSlot: '1620722@300x50' },
                    // '320x100': { adSlot: '1620728@320x100' },
                    '320x50':  { adSlot: '1620731@320x50' },
                    '728x90':  { adSlot: '1609167@728x90' }
                },
                Bottom: {
                    '160x600': { adSlot: '1609154@160x600' },
                    '300x250': { adSlot: '1609161@300x250' },
                    // '300x50':  { adSlot: '1609174@300x50' },
                    '300x600': { adSlot: '1609162@300x600' },
                    // '320x100': { adSlot: '1620727@320x100' },
                    '320x50':  { adSlot: '1620730@320x50' },
                    '728x90':  { adSlot: '1609163@728x90' },
                    // '970x90':  { adSlot: '1609164@970x90' },
                    // '970x250': { adSlot: '1609165@970x250' }
                }
            }

        } // bidderConfig
    };
    //add support for determining if the tab is currently active
    var hidden;
    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
    }
    opts.pageVisibility = hidden;



    dfp.size = dfp_size;
    dfp.lazy = dfp_lazy;

    dfp.o = opts;// exposed for debugging ONLY!
    // use dfp('set', '<KEY>', {obj}) to update

    var d = window.dfp;
    window.dfp = dfp;
    if (d) {
        runQueue(d, dfp);
        runQueue(d.lazy, dfp_lazy);
    }

    /**
     * Execute any queue items.
     * @param {Object} shim
     * @param {Function} func
     */
    function runQueue(shim, func) {
        if (shim && (shim = shim.q)) {
            for (var i = 0; i < shim.length; i++) {
                func.apply(null, Array.prototype.slice.call(shim[i]));
            }
        }
    }

    /**
     * API endpoint for configuring and showing DFP ads.
     *
     * Examples:
     * dfp('lazy', [300x250], 'div-gpt-1234', {bidconfig:'Top'});
     * dfp('set', 'bidderConfig', {appNexus:{'300x250':{placementId:1234}}});
     * dfp(null, [300x250], 'div-gpt-1234', {bidconfig:'Top'});
     *
     * @param slotName  The DFP name for the desired slot
     * @param {Array} dimensions  Single array or array of arrays with width/height as indices 0/1
     * @param {string|Element|jQuery} slotID     The ID of destination element, can be anything but recommend "dfp-[MID]"
     * @param {object=} targetOpts optional Hash of custom attributes to string/array value targets
     */
    function dfp(slotName, dimensions, slotID, targetOpts) {
        if (slotName === 'lazy') {
            return dfp_lazy(null, dimensions, slotID, targetOpts);
        }
        // if (slotName === 'size') {
        //     return dfp_size(dimensions, slotID, targetOpts);
        // }
        if (slotName === 'refresh') {
            return dfp_refresh(dimensions, slotID, targetOpts);
        }
        if (slotName === 'fillrules') {
            return dfp_fillrules(dimensions, slotID, targetOpts);
        }
        if (slotName === 'set') {
            if (typeof dimensions === 'string') {
                // variant: dfp('set', 'bidderConfig', {foobar:1});
                var o = {};
                o[dimensions] = slotID;
                dimensions = o;
            }

            if (hasPrebidInit && dimensions.prebidConfig) {
                throw "PreBid.setConfig() has already been called, cannot change configuration";
            }

            if (hasInit && dimensions.pageTargeting) {
                console.error("DFP page-level targeting has already been applied");
                window.Errsnag && Errsnag("DFP page-level targeting has already been applied");
                var params = dimensions.pageTargeting;
                delete dimensions.pageTargeting;
                googletag_cmd(function() {
                    setDfpPageTargeting(params);
                });
            }

            deepExtend(opts, dimensions);

            if (hasPrebidInit && dimensions.prebidAnalytics) {
                console.error("PreBid analytics settings will only apply to subsequent calls");
                window.Errsnag && Errsnag("PreBid analytics being changed after init");
                pbjs.enableAnalytics(opts.prebidAnalytics);
            }
            if (hasPrebidInit && dimensions.prebidBidderConfig) {
                pbjs.bidderConfig = opts.prebidBidderConfig;
                console.error("PreBid bidderConfig will only apply to subsequent calls");
                window.Errsnag && Errsnag("PreBid bidderConfig being changed after init");
            }

            return;
        }

        if (Array.isArray(slotName)) {
            targetOpts = slotID;
            slotID = dimensions;
            dimensions = slotName;
            slotName = null;
        }

        return dfp_main(slotName, dimensions, slotID, targetOpts);
    }

    /**
     * Convenience method for including and showing a DFP slot.
     *
     * Used in AdvertPro zones for 728x90 header and 160x600 left-nav/right-rail. Search for "DFP" in Apro.
     *
     * @param {string=} slotName  The DFP name for the desired slot
     * @param {Array} dimensions  Single array or array of arrays with width/height as indices 0/1
     * @param {jQuery|Node|string} slotID     The ID of destination element, can be anything but recommend "dfp-[MID]"
     * @param {object=} targetOpts optional Hash of custom attributes to string/array value targets
     * @returns {Promise}
     * @see https://developers.google.com/doubleclick-gpt/reference#googletag
     */
    function dfp_main(slotName, dimensions, slotID, targetOpts) {
        // if (slotName && !/^\/\d+/.test(slotName)) {
        //     console.error("DFP: Invalid slotName:", slotName);
        //     if (window.Errsnag) Errsnag("DFP: Invalid slotName: " + slotName);
        // }

        try {
            var adContainer = resolveSlotIDtoElement(slotID);
            if (adContainer.hasDFPSlot) {
                throw "Container is already initialized";
            }

            return setupAndShowSlot(slotName, dimensions, adContainer, targetOpts);

        } catch (e) {
            console.error('DFP:', e, slotID, arguments);
            if (window.Errsnag) {
                Errsnag("DFP: " + e + ": " + slotID);
            }
        }
    }

    /**
     * @param {string} slotName
     * @param {Array} dimensions
     * @param {Element} adContainer
     * @param {object} [targetOpts=]
     * @returns {Promise}
     */
    function setupAndShowSlot(slotName, dimensions, adContainer, targetOpts) {
        if (!hasInit) {
            // expose hasInit publicly as dfp.init just in case we need it later
            hasInit = dfp.init = 1;
            googletag_cmd(googletagInit);

            // provide debug messages with a URL flag
            if (/DFP_DEBUG/.test(location.search)) {
                $.fn && $(document).on('dfp.loading dfp.loaded dfp.failed dfp.filled dfp.unfilled prebid.failed prebid.auction', function(e) {
                    console.log('DFP_DEBUG [' + e.type + '.' + e.namespace + ']', e.target, Array.prototype.slice.call(arguments, 1));
                });
            }
        }

        // Normalize targeting options
        targetOpts = targetOpts || {};
        targetOpts.refresh = targetOpts.refresh || '0';
        if(typeof dfp.o.pageVisibility !== "undefined"){
            targetOpts.inactive = document[dfp.o.pageVisibility].toString();
        }
        adContainer.dfpTargetOpts = targetOpts;
        adContainer.dfpDimensions = dimensions;

        if (/DFP_DEBUG/.test(location.search)) {
            console.log("DFP_DEBUG [setup].container:", adContainer);
            console.log("DFP_DEBUG [setup].dimensions:", dimensions);
            console.log("DFP_DEBUG [setup].targetOpts", targetOpts);
        }

        var slotID = adContainer.id;
        // if (document.getElementById(slotID) !== adContainer) {
        //     throw "ID is bad";
        // }
        if (!slotID) {
            adContainer.id = slotID = 'container-dfp-generated-' + (++guidSeed);
        }
        var hasDfpSlot = adContainer.hasDFPSlot;
        adContainer.hasDFPSlot = true;

        // For now, the script must fail OR the ad must not fill in order to be marked as "failed"
        // FUTURE: provide proper "timeout" for an ad loading where a separate demand partner can
        //  fill the slot if DFP/Prebid doesn't fill it within X seconds.
        //setTimeout(checkElementIsFailed.bind(adContainer), 3000);

        slotName = normalizeSlotName(slotName);
        adContainer.dfpSlotName = slotName;

        ic('cpm_dfp_' + (/\/\d+\/(.+)/.test(slotName) ? RegExp.$1 : slotName));

        // attempt to limit the size list to the max-width of the container
        if (typeof targetOpts.filterSizes !== 'undefined') {
            if (targetOpts.filterSizes) {
                try {
                    dimensions = filterDimensionsArray(adContainer, dimensions);
                } catch (e) {
                    console.log('dfp-filterDimensionsArray:', e);
                }
            }
            delete targetOpts.filterSizes;
        }

        if (!hasDfpSlot) {
            // Define/configure this slot
            googletag_cmd(function () {
                var slot = googletag.defineSlot(slotName, dimensions, slotID);
                if (!slot) {// will fail every now and then for random reasons
                    console.error('Failed to create slot:', slotID, dimensions);
                    if (window.Errsnag) Errsnag('Failed to create slot: ' + slotID);
                    return;
                }

                // store so we can call refresh() if needed
                adContainer.dfpSlot = slot;

                // add to global service handler
                slot.addService(googletag.pubads());
                // collapse the DIV if the ad is empty, but not until after the ad is determined to be empty
                slot.setCollapseEmptyDiv(true, false);
            });
        }

        googletag_cmd(function() {
            if (typeof targetOpts === 'object') {
                for (var i in targetOpts) {
                    if (!targetOpts.hasOwnProperty(i)) continue;
                    var c = targetOpts[i];
                    if (targetOpts.hasOwnProperty(i)
                        && c // is truthy, avoids NULL and empty string
                        // if is an object, then must have a custom toString() method
                        && (typeof c !== 'object' || c.toString !== Object.prototype.toString)
                    ) {
                        // set custom targeting key-value
                        adContainer.dfpSlot.setTargeting(i, targetOpts[i]);
                    }
                }
            }

            googletag.enableServices();
            // Required to actually queue up the request
            googletag.display(slotID);
        });

        var promise;

        // July-2020: Header-Bidders GLOBALLY DISABLED! Now using MediaFuse's wrapper
        var bidderConfig = false;//buildBidderConfig(targetOpts.bidconfig, dimensions);
        if (bidderConfig) {
            // Cyclic boundaries:
            // - load DFP -> define slots (no timeout set)
            // - load PreBid -> add slots (1000ms)
            // - dfp() called -> PreBid starts (domContentLoaded or 100ms)
            // - PreBid starts -> PreBid ends (2000ms)
            // - PreBid starts -> DFP runs (2000ms)
            // - PreBid ends -> DFP runs (no waiting)

            var mediaTypes = { banner: {
                sizes: dimensions // << required by PreBid v1 and IX bidder
            } };
            var nativeOption = targetOpts.prebidNative;
            if (nativeOption) {
                delete targetOpts.prebidNative;

                // To add native styles:
                // dfp(null, sizes, $myElement, { prebidNative: {
                //    type: 'image' << assume defaults
                //    // ... or specify custom requirements:
                //    image: { required:true, sizes:[150,50] },
                //    title: { required:true, len:80 },
                //    sponsoredBy: { required:true },
                //    clickUrl: { required:true },
                //    body: { required:true },
                //    icon: { required:true, sizes:[50,50] }
                // } });
                // @see http://prebid.org/dev-docs/show-native-ads.html#native-object
                mediaTypes.native = typeof nativeOption === 'object' ? nativeOption
                    // assume defaults
                    : { type: 'image' };
            }

            var auctionPromise = waitForAuction({
                code: slotID,
                // old way: sizes: dimensions, mediaType: 'banner',
                mediaTypes: mediaTypes,
                bids: bidderConfig
            });

            var refreshFunc = function() {
                return refreshSlot(slotID);
            };
            promise = auctionPromise.then(refreshFunc, refreshFunc);

            // separately, trigger a jQuery event
            var triggerAuctionEvt = function(auction) {
                try {
                    $(adContainer).trigger(auction ? 'prebid.auction' : 'prebid.failed', auction);
                } catch (e) {
                    console.error("DFP: error in .trigger(prebid.auction):", e);
                }
            };
            auctionPromise.then(triggerAuctionEvt, triggerAuctionEvt);

        } else {
            promise = refreshSlot(slotID);
        }

        if (!hasDfpSlot) {
            // Layman's click-tracking in iFrames: set a global reference to the current slotName
            // Must wait for DOM to be ready because function might be called before element
            $(function () {
                var productName = slotName.replace(/^\/\d+\//, 'cpm_dfp_');
                // When mouse is over an iframe, mark which one
                $(adContainer).on('focus mouseover', function () {
                    // match ic() syntax in slotName auto-generation above
                    lastHover = productName;
                    // In order for the blur() to work, we need to ensure _top is active!
                    // I'll admit this is not standard process, might confuse other code, and fails regularly
                    // but it's the only way to attempt window.focus() behavior.
                    try {
                        var el = document.activeElement;
                        if (!el || el === document.body) { // no element is selected?
                            window.focus(); // re-attempt focus()
                        }
                    } catch (e) {
                        console.log('err trying window.focus():', e);
                    }

                }).on('blur mouseout', function () {
                    lastHover = 0;
                });
            });
        }

        function trigger() {
            try {
                $(adContainer).trigger('dfp.' + this, [slotName, dimensions, targetOpts]);
            } catch (e) {
                console.error('DFP: error in .trigger(dfp.%s)', this,  e);
            }
        }

        trigger.call('loading');

        // without affecting the original promise, add our own on-* handlers
        promise.then(trigger.bind('loaded'), trigger.bind('failed'));

        return promise;
    }

    function normalizeSlotName(slotName) {
        if (slotName && /^\w+/.test(slotName)) {
            // is path only, so add default account ID as prefix
            slotName = "/" + opts.defaultAccount + "/" + slotName;
        } else if (!slotName || !/^\/\d+\/.+/.test(slotName)) {
            slotName = "/" + opts.defaultAccount + "/" + (slotName || (/local\./.test(location.hostname) ? "local" : "www"));

            // var path = window.dfpunit && ('' + window.dfpunit),
            //     repl,
            //     networkID = /^\/(\d+)/.exec(slotName),
            //     pagetopic = window.pagetopic;
            //
            // if (!path) {
            //     if (/^\/articles\//.test(location.pathname)) {
            //         path = 'articles';
            //     } else if (/\bsearch\b/.test(location.href)) {
            //         path = 'search';
            //     } else {
            //         // should never hit this, but just in case
            //         path = window.section /* fallback */ || '_' /* totally unknown */;
            //     }
            //     repl = location.hostname.replace(/([^.]+).*/, '$1');
            //     if (repl === 'www') {
            //         repl = 'www2';
            //     }
            //     repl += '/' + path;
            // } else if (path.indexOf('local') === 0) {
            //     // Cleanup local section according to common rules of string-shortening
            //     // Domain prefix hard-coded per Marlon's request that local be a Super Section under www
            //     repl = ('www2/local/' + pagetopic + '/' + pagetopic + '_' + (window.statecode || 'ns')).replace(/([\w\/])( and |\blocal )/gi, '$1');
            // }
            // if (repl) {
            //     path = repl
            //         .toLowerCase()// DFP is case-insensitive, always use lower
            //         .replace(/[^\w\/]+/g, ''); // remove any non alpha/num chars
            // }
            //
            // slotName = '/' // required prefix
            //     + (networkID
            //         ? networkID[1]
            //         // default DFP Premium account for DMV.org licensed by Operative
            //         : opts.defaultAccount)
            //     + '/' + path;
        }

        return slotName;
    }

    /**
     * Attempt to reduce number of sizes by eliminating sizes wider than container's max width.
     * @param {Element} adContainer
     * @param {Array} dimensions
     * @returns {Array}
     */
    function filterDimensionsArray(adContainer, dimensions) {
        var maxWidth = getContainerMaxWidth(adContainer);
        var ns = 'dfp-dimensionFilter:';
        if (maxWidth > 100) {
            if (typeof dimensions[0] === 'number') {
                dimensions = [dimensions];
            }
            var filtered = [], removed = [], i = 0, cur;
            for (; i < dimensions.length; i++) {
                cur = dimensions[i];
                if (cur[0] <= maxWidth) {
                    filtered.push(cur);
                } else {
                    removed.push(cur.join('x'));
                }
            }

            // never return an empty array
            if (!filtered.length) {
                console.debug(ns, adContainer, 'no size was >' + maxWidth + 'px, using original list');
            } else if (removed.length) {
                console.log(ns, adContainer, 'maxWidth=' + maxWidth + 'px, removing: ' + removed.join(', '));
                dimensions = filtered;
            } else {
                // nothing is changing
                console.debug(ns, adContainer, 'maxWidth=' + maxWidth + 'px, all matched');
            }
        } else {
            console.debug(ns, adContainer, 'maxWidth=' + maxWidth + 'px << ignoring bad size');
        }

        return dimensions;
    }

    /**
     * Determine the maximum width of an element based on current width and parent.
     * @param {Element} adContainer
     * @returns {number} Max width in pixels, or 0 if unknown
     */
    function getContainerMaxWidth(adContainer) {
        function toInt(num) { return parseInt(num, 10) || 0; }
        function styleOf(element) {
            var styles = window.getComputedStyle(element);
            return function(name, isInt) {
                var v = styles.getPropertyValue(name);
                return isInt ? toInt(v) : v;
            };
        }

        var width = 0, maxWidth = 0;
        try {
            // set variable immediately, everything else might throw error
            width = adContainer.clientWidth; // no border

            var elStyle = styleOf(adContainer);

            // adjust down using computed value
            width = (width || elStyle('width', 1))
                - elStyle('padding-left', 1) // computed value
                - elStyle('padding-right', 1) // computed value
            ;


            // max-width is a COMPUTED VALUE, not a USED VALUE, so the string might contain "%"
            var maxWidthStyle = elStyle('max-width');
            if (/px/.test(maxWidthStyle)) { // is fixed size?
                maxWidth = toInt(maxWidthStyle);
            }

            if (/absolute|fixed/i.test(elStyle('position'))) {
                // the parent's dimensions no longer matter
                // this div is the last level where we will be able to determine a width
                // unfortunately, we STILL don't know if a width was SET for this element
                // should we return 0 and throw it away, or do we rely on it??

            } else {

                var float = elStyle('float');
                // var display = elStyle('display');
                // var canTrustWidth = false;
                // if (!/none/i.test(float) && /block/i.test(display)/* && /static|relative/i.test(position)*/) {
                //     // we hope this is 90% of cases!
                //     canTrustWidth = true;
                // }

                var widthS = adContainer.style.width;
                var isRelativeToParent = true;
                if (/px/.test(widthS) // width is specified, parent doesn't matter
                    || (/none/i.test(float) && !/%/.test(widthS))
                ) {
                    isRelativeToParent = false;
                }

                var parent;
                if (isRelativeToParent
                    && (parent = adContainer.parentElement)
                    && parent.tagName !== 'BODY'
                ) {
                    //
                    // Recurse to parent element
                    //
                    var parentWidth = getContainerMaxWidth(parent);
                    //
                    if (parentWidth > width) {
                        width = parentWidth;

                        var parentStyle = styleOf(parent);
                        var percent;

                        if (/%/.test(widthS)) {
                            percent = toInt(widthS) / 100;
                            if (percent > 0 && percent <= 1) {
                                width = parentWidth * percent;
                            }
                        }

                        if (/%/.test(maxWidthStyle)) {
                            percent = toInt(maxWidthStyle) / 100;
                            if (percent > 0 && percent <= 1) {
                                maxWidth = percent * parentWidth;
                            }
                        }

                        width = width
                            - Math.max(0, parentStyle('padding-left', 1))
                            - Math.max(0, parentStyle('padding-right', 1));
                        // if margin:auto, then margin will be defined
                        // we use margin:auto to center things, so we can't look at this
                            //- elStyle('margin-left', 1)
                            //- elStyle('margin-right', 1);
                    }
                }
            }

            // just don't add margin for now
            //elStyle.getPropertyValue('margin-left');

        } catch (e) {
            console.error("dfp-container-calc:", e);
        }

        if (0 < maxWidth && maxWidth < width) {
            width = maxWidth;
        }
        return 0 < width ? width : 0;
    }

    function googletagInit() {
        var pubads = googletag.pubads();

        // Wait for PreBid to return targeting!
        pubads.disableInitialLoad();

        // Batch AJAX requests to DFP
        if (!/[?&]debug(=|$)/.test(location.search)) { // use ?debug=1 to debug issues
            pubads.enableSingleRequest();
        }

        // Wait for ad to render
        pubads.addEventListener('slotRenderEnded', slotRenderEnded);

        // Set known page-level targeting
        var Krux = window.Krux;
        setDfpPageTargeting(extend({

            // Krux Integration
            kuid: Krux && Krux.user,     // Krux User
            ksg:  Krux && Krux.segments, // Krux Segments

            // Roxot Integration
            // 'roxot-group-id': getRoxotVal('roxot-gid', 10000) || '1001',
            // 'roxot-sector-id': getRoxotVal('roxot-sid') || '101',
            // 'roxot-deep': getRoxotVal('roxot-deep') || '101',
            // 'roxot-event-group-id': getRoxotEvent(),
            // 'roxot-event': getRoxotEvent(),
            // 'roxot-event-deep': getRoxotEvent(),
            // 'roxot-minutes': (new Date).getUTCMinutes().toString(),
            // 'roxot-hours': (new Date).getUTCHours().toString(),
            // 'roxot-day': (new Date).getUTCDay().toString(),

            env: 'PROD',
            txid: window.transID,
            supersec: window.pagesupersection,
            sec: window.pagesection,
            topic: pagetopic,
            topicid: window.navid,
            pageid: window.conid,
            secure: /s/.test(location.protocol) ? 'y' : 'n',
            statecode: statecode,
            county: window.county, // for Local
            city: window.city,     // for Local
            templateid: window.tplid, // template ID
            article_cat: window.tplid === 35 ? window.pagesection : null,
            article_tag: window.tplid === 35 ? window.articletags : null,
            searchcat: window.searchcat // controlled vocabulary
        }, opts.pageTargeting));


        function getRoxotVal(key, denom) {
            try {
                var v = localStorage.getItem(key);
                if (v === null) {
                    v = getRoxotEvent(denom);
                    localStorage.setItem(key, v);
                }
                return v ? '' + v : '';
            } catch (e) {
                console.error('getRoxotVal:', e);
            }
        }
        function getRoxotEvent(num) {
            return String(1 + Math.floor(Math.random() * (num || 100)));
        }

        // Layman's click-tracking in iFrames -- listening for a blur event
        // FYI: if the user clicks one ad then another WITHOUT clicking anywhere else on the page,
        //      then focus will go iframe-to-iframe, NOT through _top, and CANNOT be tracked.
        $(function () {
            var clickMap = dfp.clicks = {}; // init to empty
            $(window).blur(function () {
                // Some ads will have games or videos. Make sure we only register the FIRST ad click.
                if (lastHover && !clickMap[lastHover]) {
                    clickMap[lastHover] = 1;
                    ac($('<a>DFP ad click</a>')[0], lastHover);
                }
            });
        });
    }

    /**
     * Set page-level targeting. Must be called from googletag.cmd.push()
     * @param {Object} params
     */
    function setDfpPageTargeting(params) {
        var pubads = googletag.pubads(), i, cur;
        for (i in params) {
            if (params.hasOwnProperty(i) && (cur = params[i])) {
                cur = String(cur);
                if (i !== 'txid') {
                    cur = cur
                        // Choosing to uppercase everything for consistency
                        .toUpperCase()
                        // Standardize string (chosen so that targeting is easier on DFP side)
                        .replace(/[^A-Z0-9_-]+/g, '_');
                }
                // Google limits us to 40 chars
                cur = cur.substr(0, 40);
                pubads.setTargeting(i, cur);
            }
        }
    }

    /**
     * Helper function to enqueue a googletag-requiring function.
     * @param {Function} func
     */
    function googletag_cmd(func) {
        var gt = (window.googletag || (window.googletag = {}));
        (gt.cmd || (gt.cmd = [])).push(func);
    }


    /**
     * Start a PreBid auction
     */
    var waitForAuction = (function() {

        var preBidQueue = [],
            timer = null;

        /**
         * @param {{}} slot
         * @return Promise Always successful auction
         */
        return function(slot) {
            // Create promise right away
            var p = new Promise(function(resolve, reject) {
                preBidQueue.push({ a: resolve, b: reject, s: slot });
                if (timer === null) {// first time!
                    // Will be called on DOMReady, but just in case page takes too long to load...
                    timer = setTimeout(startAuction, 1000);
                    $(startAuction);
                } else if (!timer) {
                    timer = setTimeout(startAuction, 250);
                }
            });
            // Return as dependent on loadDfp() for correct error handling
            return loadDfp(true).then(function() { return p; });
        };

        function initPbjs() {
            // The DistrictM alias is included in 1.* but missing from 0.34
            // @see https://github.com/prebid/Prebid.js/blob/0.34.4/modules/appnexusBidAdapter.js
            pbjs.aliasBidder('appnexus', 'districtm');

            pbjs.bidderSettings = opts.prebidBidderConfig;

            pbjs.setConfig(opts.prebidConfig);

            // Analytics platform, should be included in all configurations
            // @see http://prebid.org/overview/analytics.html
            if (opts.prebidAnalytics) {
                pbjs.enableAnalytics(opts.prebidAnalytics);
            }

            /* not needed, we're using enableSendAllBids:true, and default params for our adapters are working fine
            // key:"standard" is general settings, all other keys are for each advertiser
            pbjs.bidderSettings = { standard: {
                adserverTargeting: [{
                    key: "custom_bidder_key",
                    val: function(bidResponse) {
                        return bidResponse.bidderCode;
                    }
                }, {
                    key: "hb_adid",
                    val: function(bidResponse) {
                        return bidResponse.adId;
                    }
                }, {
                    key: "custom_bid_price_key",
                    val: function(bidResponse) {
                        var cpm = bidResponse.cpm;
                        if (cpm < 3.00) {
                            return (Math.floor(cpm * 100) / 100).toFixed(2);
                        } else if (cpm < 5.00) {
                            return (Math.floor(cpm * 10) / 10).toFixed(2);
                        } else if (cpm < 20.00) {
                            return (Math.floor(cpm * 2) / 2).toFixed(2);
                        } else {
                            return '20.00';
                        }
                    }
                }]
            } };*/
        }

        function startAuction() {
            timer = 0;
            if (!preBidQueue.length) return;

            var adUnits = [],
                resolves = [],
                rejects = [],
                idList = [],
                sent, auction = null;

            for (var i=0, cur; i<preBidQueue.length; i++) {
                cur = preBidQueue[i];
                adUnits.push(cur.s);
                idList.push(cur.s.code);
                resolves.push(cur.a);
                rejects.push(cur.b);
            }
            preBidQueue = [];

            var pbjs = window.pbjs || (window.pbjs = {});
            (pbjs.que || (pbjs.que = [])).push(function() {
                if (!hasPrebidInit) {
                    hasPrebidInit = 1;
                    initPbjs();
                }

                // This adds to a QUEUE, which never gets emptied
                // Use {adUnits:q} below instead to explicitly start an auction
                pbjs.addAdUnits(adUnits);

                auction = pbjs.requestBids({
                    adUnits: adUnits,
                    timeout: opts.preBidTimeout || 2000,
                    //adUnitCodes: idList, << not including so that any lingering other zones get handled too
                    bidsBackHandler: finishAuction
                });
            });

            // I don't trust PreBid to always respect its timeouts, so always safe-guard:
            setTimeout(finishAuction, (opts.preBidTimeout * 1.1) || 2100);

            /**
             * Place results of the auction into the targeting parameters for the slots.
             * Then refresh the slots so the ads appear.
             */
            function finishAuction() {
                if (sent) return;
                sent = 1;

                // BE SAFE: has pbjs loaded?
                if (pbjs.setTargetingForGPTAsync) {
                    setTargeting();
                } else {
                    // pray this gets loaded in time before single-request DFP loads
                    pbjs.que.push(setTargeting);
                    // give pbjs 50ms to get loaded and get it's act together
                    setTimeout(resolveOrRejectAll, 50);
                }
            }
            function setTargeting() {
                if (auction) {
                    pbjs.setTargetingForGPTAsync(auction.getAdUnitCodes());
                    resolveOrRejectAll(true);
                } else {
                    console.error("Prebid: Auction was never started!");
                    resolveOrRejectAll();
                }
            }
            function resolveOrRejectAll(success) {
                for (var list = success ? resolves : rejects, i = 0; i<list.length; i++) {
                    list[i](auction);
                }
            }
        }
    })();

    /**
     * Call googletag.pubads().refresh() on any waiting slots.
     */
    var refreshSlot = (function() {

        var slotRefreshQueue = [],
            timer = null;

        /**
         * @param {string} slotId
         */
        return function(slotId) {
            // Create promise here to enqueue right away
            var p = new Promise(function(resolve, reject) {
                var isDone = false;
                enqueue(slotId, function() { resolve(); isDone = true; }, reject, 0);
                setTimeout(function() {
                    if (!isDone) {
                        console.error('DFP-refresh timed out');
                        reject('timeout');
                    }
                }, 10000);
            });
            // Return as dependent on loadDfp() for correct error handling
            return loadDfp().then(function() { return p; })
        };

        function enqueue(slotId, resolve, reject, attemptNum) {
            slotRefreshQueue.push([slotId, resolve, reject, attemptNum]);
            if (!timer) {
                timer = setTimeout(doRefresh, timer === null ? 10 : 50);
            }
        }

        function doRefresh() {
            timer = 0;
            if (!slotRefreshQueue.length) return;
            var curQueue = slotRefreshQueue;
            slotRefreshQueue = [];
            googletag_cmd(function() {
                var pubads = googletag.pubads(),
                    idList = curQueue.map(function(a) { return a[0] }),
                    found = [], i, cur,
                    list = pubads.getSlots().filter(function (slot) {
                        var i = idList.indexOf(slot.getSlotElementId());
                        if (i > -1) {
                            found.push(i);
                        }
                        return i > -1;
                    });

                if (list.length) {
                    pubads.refresh(list);

                    for (i = found.length; i--; ) {
                        // remove completed (MUST ITERATE IN REVERSE)
                        cur = curQueue.splice(found[i], 1)[0];
                        // resolve()
                        cur[1]();
                    }
                }

                // reject() all others
                for (i = 0; i < curQueue.length; i++) {
                    cur = curQueue[i];
                    if (cur[3] < 2) {
                        // re-try
                        enqueue(cur[0], cur[1], cur[2], cur[3]++);
                    } else {
                        // reject()
                        curQueue[i][2]();
                    }
                }
            });
        }
    })();

    /**
     * Construct an auction configuration given a placement key and size list.
     *
     * @param {string} placementKey
     * @param {Array} sizes
     * @returns {Array|undefined}
     */
    function buildBidderConfig(placementKey, sizes) {
        if (!placementKey) return;

        if (!sizes || !sizes.length) throw "Empty size list, must specify sizes to use header-bidding";

        if (typeof sizes === 'string') sizes = [sizes];

        // collapseSizesToStrings
        // Special case: DFP allows you to pass in a single dimension instead of array of dimensions
        if (typeof sizes[0] === 'number') sizes = [sizes];

        var sizeStrMap = {}, i, cur;
        for (i = 0; i < sizes.length; i++) {
            cur = sizes[i];
            if (cur.length === 2) {
                sizeStrMap['' + cur[0] + 'x' + cur[1]] = cur;
            } else if (typeof cur === 'string') {
                if (/\dx\d/.test(cur)) {
                    sizeStrMap[cur] = cur.split(/x/).map(parseInt);
                } else {
                    sizeStrMap[cur] = cur;
                }
            }
        }

        var sizeStrings = Object.keys(sizeStrMap);
        if (!sizeStrings.length) {
            console.log("Invalid size list:", sizes);
            throw "No valid sizes found, must specify sizes to use header-bidding";
        }

        var bidderConfig = opts.bidderConfig,
            bidsList = [], bidderName;
        for (bidderName in bidderConfig) {
            if (!bidderConfig.hasOwnProperty(bidderName)) continue;

            var bidderConf = bidderConfig[bidderName];
            if (typeof bidderConf !== 'object' || !bidderConf[placementKey]) continue;


            var bidPlaceConf = bidderConf[placementKey],
                bidsForThisAdvertiser = [],
                bidPlaceSizeConf;

            for (i = 0; i < sizeStrings.length; i++) {
                bidPlaceSizeConf = bidPlaceConf[sizeStrings[i]];
                if (bidPlaceSizeConf) {
                    // extend key _all into every configuration
                    bidPlaceSizeConf = deepExtend(extend({}, bidderConf._all), bidPlaceSizeConf);
                    bidPlaceSizeConf.sizes = [ sizeStrMap[sizeStrings[i]] ];
                    // IndexExchange wants to use "size" instead of "sizes"
                    if (bidderName === 'ix') bidPlaceSizeConf.size = bidPlaceSizeConf.sizes[0];
                    bidsForThisAdvertiser.push(bidPlaceSizeConf);
                }
            }

            if (!bidsForThisAdvertiser.length && (bidPlaceSizeConf = bidPlaceConf['*'])) {
                // Look for catch-all
                // extend key _all into every configuration
                bidPlaceSizeConf = deepExtend(extend({}, bidderConf._all), bidPlaceSizeConf);
                bidsForThisAdvertiser.push(bidPlaceSizeConf);

            } else if (bidsForThisAdvertiser.length > 1) {

                // collapse objects that only differ in size into one bid object with 2 sizes
                var il = bidsForThisAdvertiser.length;
                for (i = 0; i < il - 1; i++) {
                    cur = bidsForThisAdvertiser[i];
                    for (var j = i+1; j < il; j++) {
                        var next = bidsForThisAdvertiser[i + 1];
                        if (is_same(cur, next)) {
                            // push sizes together
                            cur.sizes.push(next.sizes[0]);
                            bidsForThisAdvertiser.splice(j, 1);
                            // fix counters
                            il--;
                            j--;
                        }
                    }
                }
            }

            for (i = 0; i < bidsForThisAdvertiser.length; i++) {
                bidsList.push({bidder: bidderName, params: bidsForThisAdvertiser[i]});
            }
        }

        if (bidsList.length) return bidsList;

        function is_same(a, b) {
            if (!a || !b) return;
            for (var i in a) if (a.hasOwnProperty(i)) {
                if (i !== 'sizes' && a[i] !== b[i]) return;
            }
            for (i in b) if (b.hasOwnProperty(i)) {
                if (i !== 'sizes' && a[i] !== b[i]) return;
            }
            return true;
        }
    }

    /**
     * @param {Object} a
     * @param {Object} b
     * @returns {Object} a
     */
    function extend(a, b) {
        if (b && typeof b === 'object')
            for (var i in b)
                if (b.hasOwnProperty(i)) a[i] = b[i];
        return a;
    }

    /**
     * Recursive extension with custom fast-fail rules
     * @param {Object} a
     * @param {*} b
     * @returns {*}
     */
    function deepExtend(a, b) {
        // false means do nothing
        if (a === false || b === false) return false;
        //if (typeof b === 'undefined') return a;
        if (b && typeof b === 'object') {
            if (!a || a === true || typeof a !== 'object') return b;
            var i, cur;
            for (i in b) {
                if (!b.hasOwnProperty(i)) continue;
                cur = b[i];
                if (typeof cur === 'object') {
                    cur = deepExtend(a[i], cur);
                }
                if (cur === false) {
                    delete a[i];
                } else {
                    a[i] = cur;
                }
            }
        }
        return a;
    }

    /**
     * Refresh a previously-defined DFP slot.
     *
     * @param {Array|boolean} dimensions
     * @param {String|Element} slotID
     * @param {Object=} targetOpts
     */
    function dfp_refresh(dimensions, slotID, targetOpts) {
        var adContainer = resolveSlotIDtoElement(slotID);
        var promise;
        if (!adContainer.hasDFPSlot) {
            promise = dfp_main(null, dimensions, adContainer, targetOpts);
        } else if (targetOpts || dimensions) {
            var slotName = adContainer.dfpSlotName || null;
            if (!dimensions || typeof dimensions !== 'object') dimensions = adContainer.dfpDimensions;
            if (!targetOpts) targetOpts = adContainer.dfpTargetOpts;
            targetOpts.refresh = slotID.dfpRefreshNum || '0';
            promise = setupAndShowSlot(slotName, dimensions, adContainer, targetOpts);
        }

        googletag_cmd(function() {
            var slot = adContainer.dfpSlot;
            if (!slot) {
                console.error('DFP: no slot has been defined for container:', adContainer);
                if (window.Errsnag) Errsnag("DFP: no slot defined for refresh: " + adContainer.id);
                return;
            }
            if (!promise) {
                // do manually
                refreshSlot(slot.getSlotElementId()).then(function() {
                    $(adContainer).trigger('dfp.refresh');
                });
            }
        });
        return promise;
    }

    /**
     * Call dfp() with a size that matches the sizes mapping.
     *
     * Sizes should be an array of arrays with [ WINDOW_SIZE, AD_SIZE ].
     * [
     *      [ 400, [320,50] ], // If the window size is >400 pixels wide
     *      [ 800, [720,60] ],
     *      [ 980, [[720,90],[720,60]] ]
     * ]
     *
     * @param {Array} sizes
     * @param {String} id
     * @param {Object} params
     * @returns {*} Whatever dfp() returns (currently nothing)
     */
    function dfp_size(sizes, id, params) {
        var width = $(window).width(), i;
        for (i in sizes) {
            if (width > sizes[i][0]) {
                return dfp(null, sizes[i][1], id, params);
            }
        }
    }

    /**
     * Create ad area inside accordian-style <h2> content.
     * Relies on code in init.js to modify page markup to match the pattern "h2.collapsed+.collapseWrap".
     *
     * Usage: (MUST be placed in a document.ready block else the class match will not work)
     * $(function() {
 *   dfp.mobile('/{id}', [320,50]);
 * });
     *
     * @param {string=} slotName Passed to dfp()
     * @param {Array}  sizes    Passed to dfp()
     * @param {object} targetOpts optional Passed to dfp(), hash of custom attributes to string/array value targets
     * @param {string} mid optional Media ID to trigger an impression; fired if is provided
     */
    function dfp_mobile(slotName, sizes, targetOpts, mid) {
        // This function targets a classname that is not applied unless is mobile, don't need to re-check
        //if (!(''+window.tplname).match(/^DB - [56]$/) || $(window).width() > 768) {
        //    return; // is for mobile only
        //}
        loadDfp(targetOpts);

        $(function () {
            // Run after .collapsed has been applied by init.js
            $('.collapseWrap').on('shown', function () {
                if (this.hasDfp) {
                    return;
                }
                this.hasDfp = 1;

                var $tpl = $('<div class="dfp displayAd" style="display:none">');

                // Create ad and initialize it immediately
                dfp_main(slotName, sizes, $tpl.show().appendTo(this), targetOpts);

                // Register Omniture impression if configured to run
                if (mid) {
                    ic(mid);
                }
            });
        });
    }

    /**
     * Load a DFP ad only when it's about to be visible.
     * Passes all arguments into dfp().
     *
     * @param {String=}  slotName Optional, passed to dfp()
     * @param {Array=}   dimensions Optional, passed to dfp()
     * @param {String|Element}   slotID  ID of destination DOM element
     * @param {Object=}  targetOpts
     */
    function dfp_lazy(slotName, dimensions, slotID, targetOpts) {
        var args = Array.prototype.slice.call(arguments);
        loadDfp(targetOpts);
        try {
            slotID = resolveSlotIDtoElement(slotID);
        } catch (e) {
            console.error('DFP-Lazy failed:', e);
            //if (window.Errsnag) Errsnag("DFP-Lazy Failed: " + e);
            return;
        }
        runOnVisible(slotID, function () {
            dfp.apply(null, args);
        });
    }

    /**
     * Apply fill rules, inject ads into a container.
     *
     * @param {Array} dimensions
     * @param {String} container
     * @param {Object=} targetOpts
     * @return {jQuery} Injected ads
     */
    function dfp_fillrules(dimensions, container, targetOpts) {
        if (typeof container === 'string' && /^\w/.test(container)) {
            container = '#' + container;
        }

        // CAREFUL: if the divs are not visible, then inserting a div will affect
        // subsequent div positioning! To solve this, use display:block then $.show()
        var $tpl = $('<div class="dfp dfp_fillrules" style="display:none">');

        return fillContainer(container, $tpl).show().each(function (i) {
            dfp(i < 1 ? null : 'lazy', dimensions, this, targetOpts);
        });
    }

    /**
     * Resolve a mixed-type variable into an on-page element.
     * Throw exception if fails.
     * @param {String|jQuery|Element} slotID
     * @returns {Element}
     */
    function resolveSlotIDtoElement(slotID) {
        var adContainer, elementErr;
        if (!slotID) {
            elementErr = "No ID provided";
        } else if (typeof slotID === 'string') {
            adContainer = document.getElementById(slotID);
            elementErr = "No element found for ID:" + slotID;
        } else if (slotID instanceof $) {
            adContainer = slotID[0];
            elementErr = "jQuery collection was empty";
        } else if (slotID.nodeType === slotID) {
            var type = slotID.nodeType;
            if (type === Node.ELEMENT_NODE) {
                adContainer = slotID;
            } else {
                elementErr = "Bad nodeType for element: " + type;
            }
        } else {
            adContainer = (slotID && slotID[0]) || slotID;
            elementErr = "No element provided";
        }

        if (adContainer) {
            return adContainer;
        }

        throw elementErr;
    }

    /**
     * Called when DFP pubads() finishes with a slot.
     * @param e
     */
    function slotRenderEnded(e) {
        var slot = e.slot, el;

        try {// never fail!

            var id = slot.getSlotElementId();
            el = document.getElementById(id);
            var $ad = $(el);

            if (!el) {
                throw "element not found: " + id;
            }
            if (!el.dfpSlot) {
                throw "no cached dfpSlot, element changed?";
            }
            if (el.dfpSlot !== slot) {
                throw "slot doesn't match, double-init?";
            }

            // Log verbose info about the event
            if (/DFP_DEBUG/.test(location.search)) {
                var ns = "DFP_DEBUG [slotRenderEnded] ";
                console.log(ns + 'event:', e);
                console.log(ns + 'el:', el);
                console.log(ns + 'targeting:', slot.getTargetingKeys().reduce(function(o, k) {
                    o[k] = slot.getTargeting(k);
                    return o;
                }, {}));
            }

            // Check if the ad was filled
            if (!$ad.is(':visible') || !$ad.children().is(':visible')) {
                console.log('DFP: Ad was not filled', el, slot);
                $ad.trigger('dfp.unfilled', [slot]);

                // Doesn't work consistently, so disabling
                // Hide the container if the slot is empty
                // $ad.parent().hide();
            } else {
                if (e.size && e.size[0] > 50) {
                    el.dfpResolvedSize = [e.size[0], e.size[1]];
                }
                $ad.trigger('dfp.filled', [slot]);
            }

            // Auto-Refresh if timeout is provided and is not excluded
            clearTimeout(el.dfpAutoRefreshTimer); // clear last
            var autoRefreshTimeout = dfp.o.autoRefreshTimeout,
                autoRefreshExcludeAdvertisers = dfp.o.autoRefreshExcludeAdvertisers || [];
            if (autoRefreshTimeout > 0
                && autoRefreshExcludeAdvertisers.indexOf(e.advertiserId) === -1
                // special rule: exclude all native positions
                && !/^native/i.test(slot.getTargeting("pos"))
            ) {
                var timer;
                timer = setTimeout(function() {
                    refreshIfInView(el, timer);
                }, autoRefreshTimeout);
                el.dfpAutoRefreshTimer = timer;
            }

        } catch (e) {
            console.error("SlotRenderEnd:", e, el);
        }
    }

    /**
     * Perform an auto-refresh when Element touches viewport.
     *
     * @param {Element} el
     * @param {number} lastTimer
     */
    function refreshIfInView(el, lastTimer) {
        var curTimer = el.dfpAutoRefreshTimer;
        if (curTimer) {
            if (lastTimer && curTimer !== lastTimer) {
                // multiple onSlotRenderEnded events happened
                // we should stop immediately!
                return;
            }
            clearTimeout(el.dfpAutoRefreshTimer);
        }

        var lastRefreshNum = el.dfpRefreshNum || 0;

        if (dfp.o.autoRefreshOnVisible) {
            runOnVisible(el, doRefresh, 0);
        } else {
            doRefresh();
        }

        function doRefresh() {
            var currentRefreshNum = el.dfpRefreshNum || 0;
            // if no changes have happened yet
            if (currentRefreshNum === lastRefreshNum
                && currentRefreshNum < dfp.o.autoRefreshLimit
            ) {
                el.dfpRefreshNum = currentRefreshNum + 1;
                if (/DFP_DEBUG/.test(location.search)) {
                    console.log("DFP_DEBUG [slotRenderEnded] performing refresh num:", el.dfpRefreshNum, el);
                }
                dfp_refresh(el.dfpResolvedSize || true, el);
            }
        }
    }

    /**
     * Load DFP, and optionally pre-bid.js
     * @param {Object=} targetOpts
     * @returns {Promise} Whether the file(s) is already loaded
     */
    function loadDfp(targetOpts) {
        // var require = window.require;// local ref for better compilation
        //
        // var p = loadDfp.dfp || (loadDfp.dfp = (new Promise(function(resolve, reject) {
        //     require('dfp', function() {
        //         // if extension triggers 307 with empty body (like Disconnect does)
        //         if (window.google_js_reporting_queue) {
        //             resolve();
        //         } else {
        //             var err = "DFP could not be reliably determined as loaded";
        //             console.error(err);
        //             reject(err);
        //         }
        //     });
        //     // if extension triggers 403/404
        //     require('err:dfp', function() {
        //         var err = "DFP require() failed to load";
        //         console.error(err);
        //         reject(err);
        //     });
        // })));
        //
        // if (targetOpts && (targetOpts === true || targetOpts.bidconfig)) {
        //     var prebidName = 'prebid';
        //     loadDfp[prebidName] || (loadDfp[prebidName] = (new Promise(function(resolve, reject) {
        //         require(prebidName, function() {
        //             if (window.pbjs && window.pbjs.setConfig) {
        //                 resolve();
        //             } else {
        //                 var err = "Prebid code not detected after loading completed";
        //                 console.error(err);
        //                 reject(err);
        //             }
        //         });
        //         require('err:' + prebidName, function() {
        //             var err = "Prebid require() failed to load";
        //             console.error(err);
        //             reject(err);
        //         });
        //     })));
        // }
        //
        // return p;

        return loadDfp.dfp || (loadDfp.dfp = (new Promise(function(resolve, reject) {

            // Script from MediaFuse
            // Modified to call resolve/reject() on completion
            window.googletag = window.googletag || {};
            window.vmpbjs = window.vmpbjs || {};
            window.vpb = window.vpb || {};
            window.vpb.fastLoad = true;
            googletag.cmd = googletag.cmd || [];
            vmpbjs.cmd = vmpbjs.cmd || [];
            var cmds = googletag.cmd.slice(0) || [];
            googletag.cmd.length = 0;
            var ready = false;

            function exec(cb) {
                return cb.call(googletag);
            }

            googletag.cmd.push(function () {
                googletag.cmd.unshift = function (cb) {
                    if (ready) {
                        return exec(cb);
                    }
                    cmds.unshift(cb);
                    if (cb._startgpt) {
                        ready = true;
                        cmds.forEach(function (cb) {
                            exec(cb);
                        });
                    }
                };
                googletag.cmd.push = function (cb) {
                    if (ready) {
                        return exec(cb);
                    }
                    cmds.push(cb);
                };
            });
            googletag.cmd.push = function (cb) {
                cmds.push(cb)
            };
            googletag.cmd.unshift = function (cb) {
                cmds.unshift(cb);
                if (cb._startgpt) {
                    ready = true;
                    if (googletag.apiReady) {
                        cmds.forEach(function (cb) {
                            googletag.cmd.push(cb);
                        })
                    } else {
                        googletag.cmd = cmds;
                    }
                }
            };
            var dayMs = 36e5,
                cb = parseInt(Date.now() / dayMs),
                vpbSrc = '//player.mediafuse.com/prebidlink/' + cb + '/wrapper_hb_307946_8169.js',
                pbSrc = vpbSrc.replace('wrapper_hb', 'hb'),
                gptSrc = '//securepubads.g.doubleclick.net/tag/js/gpt.js',
                c = document.head || document.body || document.documentElement;

            function loadScript(src, cb) {
                var s = document.createElement('script');
                s.src = src;
                s.defer = false;
                c.appendChild(s);
                s.onload = cb;
                s.onerror = function () {
                    var fn = function () {};
                    fn._startgpt = true;
                    googletag.cmd.unshift(fn);
                    reject();
                };
                return s;
            }

            loadScript(pbSrc);
            loadScript(gptSrc, function() {
                resolve();
            });
            loadScript(vpbSrc);
        })));
    }

})(window);
