/**
 * Convert _P array values into global variables.
 * The array _P is hard-baked into every built page.
 */
(function(window) {
    // We use this variable everywhere; make sure it's not empty
    var tmp = window.rootUrl;
    if (!tmp) {
        tmp = 'dmv.org';
        if (tmp) tmp = 'www.' + tmp;
    }
    window.rootUrl = tmp.indexOf('//') > -1
        ? tmp // use original value if non-empty and absolute
        : location.protocol + '//' + (tmp || location.host) + '/'; // make absolute if necessary

    //// PHP Config vars
    // used in s_code.js to create Omniture objects
    window.s_account = window.s_account || 'dmvorg';
    // used in s_code.js ac() function
    window.admarketplaceSrc = window.admarketplaceSrc || '//tracking.admarketplace.net/tracking?pid=29666&cid=73774&orderval=0.00&v=2&orderid=&custominfo=';

    // IE8 Shim for Date.now()
    Date.now = Date.now || function() { return +new Date(); };

    // Used in LOTS of places
    // FYI: stateID is index+1 because JavaScript uses 0-based indicies
    window.arrStateName = "Alabama,Alaska,Arizona,Arkansas,California,Colorado,Connecticut,Delaware,Florida,Georgia,Hawaii,Idaho,Illinois,Indiana,Iowa,Kansas,Kentucky,Louisiana,Maine,Maryland,Massachusetts,Michigan,Minnesota,Mississippi,Missouri,Montana,Nebraska,Nevada,New Hampshire,New Jersey,New Mexico,New York,North Carolina,North Dakota,Ohio,Oklahoma,Oregon,Pennsylvania,Rhode Island,South Carolina,South Dakota,Tennessee,Texas,Utah,Vermont,Virginia,Washington,West Virginia,Wisconsin,Wyoming,Washington DC".split(',')/*[
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware",
        "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky",
        "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri",
        "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina",
        "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
        "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming", "Washington DC"
    ]*/;
    window.arrStateCodes = "al-alabama,ak-alaska,az-arizona,ar-arkansas,ca-california,co-colorado,ct-connecticut,de-delaware,fl-florida,ga-georgia,hi-hawaii,id-idaho,il-illinois,in-indiana,ia-iowa,ks-kansas,ky-kentucky,la-louisiana,me-maine,md-maryland,ma-massachusetts,mi-michigan,mn-minnesota,ms-mississippi,mo-missouri,mt-montana,ne-nebraska,nv-nevada,nh-new-hampshire,nj-new-jersey,nm-new-mexico,ny-new-york,nc-north-carolina,nd-north-dakota,oh-ohio,ok-oklahoma,or-oregon,pa-pennsylvania,ri-rhode-island,sc-south-carolina,sd-south-dakota,tn-tennessee,tx-texas,ut-utah,vt-vermont,va-virginia,wa-washington,wv-west-virginia,wi-wisconsin,wy-wyoming,washington-dc".split(',')/*[
        "al-alabama", "ak-alaska", "az-arizona", "ar-arkansas", "ca-california", "co-colorado", "ct-connecticut", "de-delaware",
        "fl-florida", "ga-georgia", "hi-hawaii", "id-idaho", "il-illinois", "in-indiana", "ia-iowa", "ks-kansas", "ky-kentucky",
        "la-louisiana", "me-maine", "md-maryland", "ma-massachusetts", "mi-michigan", "mn-minnesota", "ms-mississippi", "mo-missouri",
        "mt-montana", "ne-nebraska", "nv-nevada", "nh-new-hampshire", "nj-new-jersey", "nm-new-mexico", "ny-new-york",
        "nc-north-carolina", "nd-north-dakota", "oh-ohio", "ok-oklahoma", "or-oregon", "pa-pennsylvania", "ri-rhode-island",
        "sc-south-carolina", "sd-south-dakota", "tn-tennessee", "tx-texas", "ut-utah", "vt-vermont", "va-virginia", "wa-washington",
        "wv-west-virginia", "wi-wisconsin", "wy-wyoming", "washington-dc"
    ]*/;
    window.arrStateAbbr = "AL,AK,AZ,AR,CA,CO,CT,DE,FL,GA,HI,ID,IL,IN,IA,KS,KY,LA,ME,MD,MA,MI,MN,MS,MO,MT,NE,NV,NH,NJ,NM,NY,NC,ND,OH,OK,OR,PA,RI,SC,SD,TN,TX,UT,VT,VA,WA,WV,WI,WY,DC".split(',');

    window.arrLocalStateKeys = "alabama,alaska,arizona,arkansas,california,colorado,connecticut,delaware,florida,georgia,hawaii,idaho,illinois,indiana,iowa,kansas,kentucky,louisiana,maine,maryland,massachusetts,michigan,minnesota,mississippi,missouri,montana,nebraska,nevada,new-hampshire,new-jersey,new-mexico,new-york,north-carolina,north-dakota,ohio,oklahoma,oregon,pennsylvania,rhode-island,south-carolina,south-dakota,tennessee,texas,utah,vermont,virginia,washington,west-virginia,wisconsin,wyoming,district-of-columbia".split(',');

    // Polyfill: console.log (it's used in too many places to just ignore)
    tmp = window.console = window.console || {};
    if (!tmp.log) {
        tmp.log = function() {};
    }

    // Optimizely SiteCatalyst Integration
    // The cdn.optimizely.com JS file is hard-coded into the page template, so these
    // attributes need to be run/available in-line instead of put into init.js
    var optimizely = window.optimizely = (window.optimizely || []),
        // Tag map { tagName: 'potentialWindowVarName' }
        optimizelyCustom = {
            supersection: 'pagesupersection',
            section: 'pagesection',
            topic: 'pagetopic',
            statecode: 0,
            county: 0,
            city: 0,
            template: 'tplid'
        },
        tagName, tagVal;
    optimizely.push("sc_activate");

    // Optimizely Custom Tags
    for (tagName in optimizelyCustom) {
        // Look in global scope for value
        tagVal = window[ optimizelyCustom[tagName] || tagName ];
        if (tagVal) {
            // Save non-emptyp value as tag
            optimizelyCustom[tagName] = tagVal;
        } else {
            // Else remove entry
            delete optimizelyCustom[tagName];
        }
    }
    // statename is special
    if (window.statename) {
        optimizelyCustom.statename = window.statename[0].toUpperCase() + window.statename.substr(1).toLowerCase();
    }
    optimizely.push(["customTag", optimizelyCustom]);
})(window);
