/*global window, document, Image*/
var s; // try to solve "variable undefined" problems by just defining it at the top
(function(window, document) {
    "use strict";

    var //transID = window.transID, << use window.transID directly, don't create local copy
        txIds = {}, // only used in ac() function
        eventCats = [ // Map of the events that should be fired if a media ID matching pattern is found
            { match: /^RPS_/i, imp: "event6", click: "event7" },
            { match: /^Splash/i, imp: "event4", click: "event5" },
            //{ match: /^CityGrid_/i, imp: "event10", click: "event11" },
            { match: /^misc_search_/i, imp: "event3", click: "event3" },
            { match: /^misc_serp_first/i, imp: "event21", click: "event21" },
            { match: /^misc_serp_click/i, imp: "event22", click: "event22" },
            { match: /^(?:misc|house)_/i, imp: "event14", click: "event15" },
            { match: /^cpm_/i, imp: "event16", click: "event17" },
            // Default, will match anything non-empty
            { match: /./, imp: "event8", click: "event9" }
        ],
        icTimer, icQueue = [],
        icCanQueueProducts = [],// is reset when s.t() fires the first time
        a, b;

    if (!s || !s.tl) {
        a = s;
        s = buildDefaultOmnitureObject();
        if (a) {
            for (b in a) {
                if (a.hasOwnProperty(b)) {
                    s[b] = a[b];
                }
            }
        }
    }

    /**
     * Execute any queue items.
     * @param {Object} shim
     * @param {Function} func
     */
    function runQueue(shim, func) {
        if ((shim = (shim && shim.q))) {
            for (var i=0; i<shim.length; i++) {
                func.apply(null, Array.prototype.slice.call(shim[i]));
            }
        }
    }
    // Deal with ic() and ac() queues that may have been initialized within the global scope
    runQueue(window.ic, ic);
    runQueue(window.ac, ac);

    // Export functions to global scope
    window.ac = ac;
    window.fc = fc;
    window.ic = ic;

    // Only override the window object if not yet instantiated
    // This protects the page from having a mal-formed environment if this file is included more than once
    var origTrackFunc = s.t;
    // Record whether tracking code has run
    s.t = function() {
        //s.t = origTrackFunc;
        // We are using Omniture to track just one page-view per page-load -- we are NOT a JS single-page-app
        if (s.hasTrackedPageView) return;

        var events = s.events || '';
        if (!/event1\b/.test(events)) {
            events += (events ? ',' : '') + 'event1';
        }

        // addOmnEvents() knows to remove empty "," entries
        if (icCanQueueProducts && icCanQueueProducts.length) {
            var products = s.products || '',
                p = s.products = (products ? products + ',' : '') + addOmnEvents(icCanQueueProducts.join(','));
            if ((p = getUniqueEvents(p))) {
                // events will always contain at least event1
                events += ',' + p;
            }
        }
        icCanQueueProducts = null;// clear so we force-trigger errors if we tough it again
        s.events = events;

        var ret = origTrackFunc.call(s); // run within context using '.' notation
        s.products = // clear products so calls like ac(null, '', {eVarXX:"value"}) don't re-use the products value
        s.events = // clear events so subsequent s.t() calls don't include event1
        s.pageName = null; // clear pageName so subsequent ic()/ac() calls don't trigger a Page View
        s.hasTrackedPageView = true; // marker to elsewhere that this has run
        return ret;
    };

    // On DOMContentLoaded check if s.t() has already been called.
    // If the page had an error, some browsers will NOT trigger this.
    // DISABLING: this was causing issues with double-counting page views. YES some older browsers will skip JS execution, but the majority of our visitors are on newish or mobile platforms.
    //if (window.$) $(s.t);

    /**
     * Format product list and add events to product list.
     *
     * @param {string} products
     * @param {string=} defaultEvent   Optional
     * @param {boolean=} isClick  Optional
     * @return string Formatting products string
     */
    function addOmnEvents(products, defaultEvent, isClick) {
        var productTokens, i=0, validTokens = [],
            product, tEvent, cat = typeof products;

        if (cat === 'function' || cat === 'object') {
            productTokens = ['_INVALID_OBJECT_'];// hard-coded default
        } else {
            productTokens = (''+products).split(',');
        }

        for (; i<productTokens.length; i++) {
            product = productTokens[i];

            // See if the event name is held within the product itself
            if (getUniqueEvents(product)) {
                validTokens.push(product);// trust the string implicitly

            } else if ((product = validateMid(product))) {
                tEvent = defaultEvent;
                if (!/^event/.test(tEvent)) {
                    // route event to particular event?
                    for (cat in eventCats) {
                        if (eventCats.hasOwnProperty(cat)) {
                            cat = eventCats[cat];
                            if (cat.match.test(product)) {
                                tEvent = isClick ? cat.click : cat.imp;
                                break;
                            }
                        }
                    }
                }
                validTokens.push(";" + product + ";;;" + tEvent + "=1");
            }
        }
        return validTokens.join(',');
    }

    /**
     *
     * @param {string} mid
     * @returns {string|null}
     */
    function validateMid(mid) {
        if (!mid) {
            return '';
        }
        mid = mid
            // strip whitespace
            .replace(/^\s+|\s+$/g, '')
            // remove any event information
            .replace(/^;(.*?);.*/, '$1');

        if (!mid) {// skip empties
            return '';
        }
        // MID should NEVER have newlines -- dead giveaway that the MID is invalid
        if (/[\n\r{}()]/.test(mid)) {
            try {
                console.error('Invalid product!', mid);
            } catch (e) {
            }// ignore error
            mid = '_INVALID_STRING_';// hard-coded default
        }
        return mid;
    }

    /**
     * Find all unique events used in the product list.
     * Returns event list as 'event1,event5,...'.
     *
     * @param {string} products
     * @return string
     */
    function getUniqueEvents(products) {
        var re = /;event(\d+)=/g,
            events = "",
        // All matches are numeric, so array works
            matchedIDs = [],
            match;
        // If the regex is cached, multiple calls to exec()
        // will increment to the next match.
        while ((match = re.exec(products))) {
            match = match[1];
            if (!matchedIDs[match]) {
                events += (events && ",") + "event" + match;
                matchedIDs[match] = true;
            }
        }
        return events || '';
    }

    /**
     * Media tracking for forms.
     * E.g. <form onsubmit='fc(this,"[media id]")' ...>.
     *
     * @param {Element}   formEl  Element
     * @param {string}    mid     Passed to ac()
     * @param {string|object}   [cevent] Passed to ac()
     * @param {function} [callback]
     * @return string TXID used for the product impression, will be truthy
     */
    function fc(formEl, mid, cevent, callback) {
        var tag, linkEl = document.createElement('a');

        if (formEl) {
            // Allow passing jQuery objects
            formEl = formEl[0] || formEl;
            // Must be DOM element
            if (formEl.appendChild) {
                // Ensure tag is non-empty
                tag = formEl.name || formEl.action;
            } else {
                formEl = false;
            }
        }

        // AppMeasurement inspects the <a> tag
        linkEl.href = tag;
        linkEl.textContent = 'Form: ' + tag;
        linkEl.style.display = 'none';// hide from view
        // ac() inspects the parents of the clicked item
        formEl && formEl.appendChild(linkEl);

        // Pass event
        var ret = ac(linkEl, mid, cevent, callback);

        // Cleanup
        formEl && formEl.removeChild(linkEl);

        return ret;
    }

    /**
     * Determine if the s.t() function has been called.
     * DOESNT WORK ALL THE TIME. WAS CAUSING ~25% DIFFERENCE BETWEEN PAGE IMPRESSIONS/VIEWS.
     *
     * @returns {boolean}
     *
    function pageTrackingHasRun() {
        for (var w_m in window) {
            //noinspection JSUnfilteredForInLoop
            if (/^s_i_/.test(w_m)
                && /\/b\/ss\//.test(window[w_m].src)
            ) {
                    return true;
            }
        }
    }*/

    /**
     * Impression tracking.
     * Product should be ';mid;;[;eventX=N[|eventX2=N2]][,next mid]'
     * or comma separated list of mids.
     *
     * @param {string} products       String passed to addOmnEvents()
     * @param {string} [defaultEvent] String passed to addOmnEvents()
     */
    function ic(products, defaultEvent) {
        if (typeof products !== 'string' || !products) {
            return;// short-circuit with empty product
        }
        // If defaultEvent is provided, we have to call addOmnEvents() with custom params and can't shortcut
        if (!defaultEvent && icCanQueueProducts) {
            icCanQueueProducts.push(products);
        } else {
            icQueue.push(arguments);
            if (!icTimer) {
                icTimer = setTimeout(_ic, 200);
            }
        }
    }

    /**
     * Push impression queue in one hit.
     *
     * @private
     */
    function _ic() {
        icTimer = 0;
        if (!icQueue.length) return;
        var i= 0, products;
        for (; i < icQueue.length; i++) {
            products = icQueue[i];
            // Use first two arguments
            icQueue[i] = addOmnEvents(products[0], products[1]);
        }

        products = icQueue.join(',');
        i = getUniqueEvents(products);
        icQueue = [];

        // WARNING: if first argument is "true" then linkName MUST be non-empty string or tl() will trigger a page view!
        s.tl(true, 'o', 'Impression', {
            linkTrackVars: 'products,events,eVar4,eVar5,eVar9,eVar11,eVar12,eVar13,eVar14,eVar22,transactionID,eVar51,eVar68,eVar69',
            products: products,
            events: i,
            linkTrackEvents: i
        });
    }

    /**
     * Media tracking for links.
     * e.g. <a onsubmit='ac(this,"[media id]")' ...>.
     *
     * @param {Element|null}    linkObj Element or NULL
     * @param {string}          mid     Media ID, comma-separated
     * @param {string|object}   [overrides] Event string like "event9" or object with override values
     * @param {string|function} [callback] Custom callback function; can use magic string 'navigate' if passed a valid linkObj
     * @return {string} TXID used for the click
     */
    function ac(linkObj, mid, overrides, callback) {
        var overridesType = typeof overrides,
            // Legacy: in the past, argments[2] was 'event2' or something custom
            allProducts = addOmnEvents(mid, overridesType === 'string' ? overrides : '', true),
            allEvents = getUniqueEvents(allProducts),
            txId = window.transID,// local copy
            regularProductMid, firstMid, i = 0, opts, cur;

        // Reset the mid variable to the FIRST product name
        opts = allProducts.split(',');
        for (; i < opts.length; i++) {
            cur = opts[i];
            // Is this a default action?
            if (/\bevent9\b/.test(cur)) {
                firstMid = regularProductMid = cur.replace(/^;(.*?);.*/, '$1');
                break;
            }
        }
        if (!firstMid) {
            // If we don't have a product MID to use, then back-fill with the first one in the list
            firstMid = validateMid((''+mid).split(',', 2)[0]);
        }

        /* Very scary global event cancel
         * @see https://marketing.adobe.com/resources/help/en_US/sc/implement/function_tl.html
        var event = window.event;
        if (!callback && event && event.target === linkObj && event.type === 'click' && linkObj && linkObj.href) {
            try {
                // event.preventDefault might be missing on older browsers or event might not be cancelable
                event.preventDefault();
                callback = 'navigate';
            } catch (err) {
                console && console.log(err);
            }
        }
        */

        if (firstMid) {
            txId += '-' + firstMid;

            // Record this ID+MID as happening
            txIds[txId] = i = (txIds[txId] || 0) + 1;
            // If it's happened more than once, then add it to the call
            if (i > 1) {
                txId += ':' + i;
            }

            // Tie-In with engagement.js
            if (window.hasEngagementTracking && !window.hasTrackedEngagementProduct && (
                allEvents === 'event9'
                || /*will be event15:*/ /^house_/i.test(firstMid) // treat house ads as "Conversion Link"
                || /\b((misc_)?wzd_[\w-]+?start)([^\w]|$)/i.test(firstMid) // treat starting a wizard as "Conversion Link"
            )) {
                window.hasTrackedEngagementProduct = 1;// Only trigger once!
                allEvents = 'event32,' + allEvents;
            }

            // Add generic product click event
            allEvents = 'event2,' + allEvents;

            if (regularProductMid) {
                // Write the transId into a cookie for 30 days
                // these are used in pixel tracking /pages/pixel.php
                //var cookieDom = '=' + txId + ';max-age=' + (60*60*24*30) + ';path=/;domain=' + getCookieDomain();
                //document.cookie = 'mc:last' + cookieDom; << we don't use this cookie anywhere!
                document.cookie = 'mc:' + encodeURIComponent(regularProductMid)
                    + '=' + encodeURIComponent(txId)
                    + ';max-age=' + (60*60*24*30) + ';path=/;domain=' + getCookieDomain();
            }
        }

        opts = {
            // Always send these properties, in addition to overridden props
            linkTrackVars: 'eVar11,eVar12,eVar13,eVar22,eVar51,eVar68,eVar69,eVar73',
            linkTrackEvents: allEvents
        };

        if (!overrides || /* NULL is an object! */overridesType !== 'object') {
            overrides = {};
        }
        // Use the overrides object so we can build out linkTrackVars
        overrides.products = allProducts;
        overrides.transactionID = overrides.eVar5 = txId;
        overrides.events = allEvents;

        // Do not turn global wrappers into text
        if (linkObj === window || linkObj === document) {
            linkObj = null;
        }
        // Inspect parent elements to see if we can determine the ad-zone associated
        if (linkObj && typeof overrides.eVar1 === 'undefined') {
            var parent = linkObj,
                pLevel = 20,// only go up this many levels
                // this pattern is our internal zone prefix
                clsExtract = /(?:^|\s)zd(zone\w+)/,
                clsMatch;
            while (pLevel-- && (parent = parent.parentNode) && parent.getAttribute && parent.nodeType === 1) {
                if (clsMatch = clsExtract.exec(parent.getAttribute('class'))) {
                    overrides.eVar1 = clsMatch[1];
                    break;
                }
            }
        }

        // REMINDER: mid can be a LIST of MIDs!
        // CUSTOM FUNCTIONALITY: add eVar7 if there's an MID misc_wzd_*start OR wzd_*start
        if ((i = /\b((misc_)?wzd_[\w-]+?start)([^\w]|$)/i.exec(mid))) {
            overrides.eVar7 = 'form:' + i[1];
        }
        // Force links to be tracked by adding to linkTrackVars
        for (i in overrides) {
            if (overrides.hasOwnProperty(i)) {
                opts[i] = overrides[i];
                opts.linkTrackVars += ',' + i;
            }
        }

        /* This broke in v2.0.0 and we didn't catch it!!
        // Copied from AppMeasurement.sb(pixelUrl) which executes the current request
        if (s.v) { // will be =1 if AppMeasurement is in middle of a request
            // s.v is set in s.da() RunNextRequest, called from s.pb() EnqueueRequest
            if (s.la) { // set in s.sb() when Image request is started
                clearTimeout(s.la);
                s.la = 0;
            }
            //s.$a(); // dequeue from localStorage, we don't set trackOffline=1 so don't need this
            //s.Z(); // replaced by following lines
            if (s.aa) { // run in s.Z()
                clearTimeout(s.aa);
                s.aa = 0;
            }

            // Finally, clear "isRunning" flag
            s.v = 0;
        }*/

        // Track click
        // @see https://marketing.adobe.com/resources/help/en_US/sc/implement/function_tl.html
        s.tl(
            // 1) First argument should be truthy
            linkObj || true,
            // 2) Type should be 'o' for "Custom Link Event"
            'o',
            // 3) Limit length of markup to 100 chars
            // WARNING: if first argument is "true" then linkName MUST be non-empty string or tl() will trigger a page view!
            elToText(linkObj) || 'Click',
            // 4) Custom params to take effect for this request only
            opts,
            // 5) Can be 'navigate' for default behavior, or custom function
            callback
        );

        // Should never call ac() before s.t()
        if (icCanQueueProducts && window.console) {
            console.error("ac() being called before s.t(), will not receive eVar values!");
        }

        // Krux
        if (window.Krux) { // protect against missing variables
            if (regularProductMid) {
                // Krux Product Click (click ID setup within Krux UI)
                Krux('ns:dmv', 'admEvent', 'KrZ7b4xE', {});
            }
            if (/^misc_/i.test(mid)) {
                // Krux Misc Click (click ID setup within Krux UI)
                Krux('ns:dmv', 'admEvent', 'KrZ9rqxs', {});
            }
        }

        // Quantcast
        (window._qevents = window._qevents || []).push({
            qacct: "p-s_aNqFS0D2k_x",
            labels: 'Product Clicks.' + window.quantcastLabels
        });

        // Google Analytics Custom Event
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
        // https://support.google.com/analytics/answer/2709828?hl=en#collection
        if (!(/^cpm/i.test(mid))) {
            ga('send', {
                hitType: 'event', // Required.
                eventCategory: /^(misc|house)/i.test(mid) ? 'Misc Click' : 'Product Click', // Required.
                eventAction: 'click', // Required.
                eventLabel: mid,
                eventValue: 1,
                nonInteraction: 1,
                dimension1: window.pagesupersection,
                dimension2: window.pagesection,
                dimension3: window.pagetopic
            });
        }

        // Admarketplace tracking pixel (only Product Clicks)
        if (!/^(cpm|house|misc)/i.test(mid) &&
            window.s.campaign === 'admarketplace' && window.s.eVar37 && (cur = window.admarketplaceSrc)
        ) {
            var el = new Image();
            el.alt = '';
            el.src = cur;
        }

        return txId;
    }

    /**
     * Convert an element into a string.
     *
     * @param {Node|Array|string} el
     * @returns {string}
     */
    function elToText(el) {
        // "no jQuery required": $.fn.text() and $.trim()
        // try to treat as array first, else
        if (!el) {
            el = ''; // empty?
        } else if (!el.textContent && el.length) {
            el = el[0]; // array?
        } else {
            el = el.textContent; // node?
        }
        return ('' + (el || '')) // always fallback
            .substr(0, 200) // don't execute the RegExp against the entire string
            .replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') // trim whitespace
            .replace(/\s+/g, ' ') // replace all whitespace with "space"
            .substr(0, 80); // return only the beginning
    }

    /**
     * @returns {string}
     */
    function getCookieDomain() {
        // use pre-parsed location, remove anything above 2 TLDs
        return window.location.hostname.replace(/^.*(\.[\w-]+\.[\w-]+)$/, '$1');
    }

    /**
     * Encapsulate creating the default Omniture s object.
     * @returns {AppMeasurement}
     */
    function buildDefaultOmnitureObject() {
        var s = new AppMeasurement(),
            location = window.location,
            hostname = location.hostname,
            rootDomain = hostname.replace(/^.+\.([\w-]+\.\w+)$/, '$1');

        /************************** CONFIG SECTION **************************/

        if (!window.s_account) throw 'No s_account is defined';

        s.sa(window.s_account); //setAccount()
        s.charSet = "ISO-8859-1"; // This is required IF values in your evar/props might contain UTF-8 characters but you want them translated to a default ISO for storage
        // E-commerce Config
        //s.currencyCode = "USD"; // This is already the default -- we don't need to double-specify unless it's different than the global config
        // Link Tracking Config
        s.trackDownloadLinks = true;
        s.trackExternalLinks = true;
        s.trackInlineStats = true;
        s.linkDownloadFileTypes = "exe,zip,wav,mp3,mov,mpg,avi,wmv,doc,pdf,xls";
        s.linkInternalFilters = "javascript:," + rootDomain;
        s.linkLeaveQueryString = false;
        // Setting the track vars to None so that the Omniture automatic exit call doesn't
        // send the vars above again and double cound the click...
        s.linkTrackVars = "None";
        s.linkTrackEvents = "None";

        /* WARNING: Changing any of the below variables will cause drastic
         changes to how your visitor data is collected.  Changes should only be
         made when instructed to do so by your account manager.*/
        //s.dc = 112; // Data Center, optional if s.trackingServer is set
        //s.trackingServer = "onlineguru.112.2o7.net"; // Domain name, set to rootUrl if domain matches
        // CUSTOMIZED GLOBALLY!
        // Special prefix configured with HTTP proxy on ALL domains
        s.PREFIX = '/omn/';
        // For DMV.org domains, we want to use explicitly www.dmv.org
        s.trackingServer = /\.(int)?dmv\./.test(hostname) ? 'www.' + rootDomain
            // For all others, if the user is looking at www, then avoid the DNS lookup for root
            : (/^www\./.test(hostname) ? hostname
            // Otherwise, just assume the root domain
                : rootDomain);

        // Start with "Page Impression" event
        s.events = 'event1';

/* Moving vars from templates/legacy/statecode_js.tpl into pure-JavaScript so can be cached

* = is set in this file

s.
* transactionID: must be set for CPM, will also be set on ac()/ic()
  pageName: Page (Topic+State) name
  channel: Super Section name
* server: Load-balanced node name

s.eVar
      1: Domain
      2: Overture Advertisement load time
      3: Onsite Search Keyword (not used)
*     4: current URL
*     5: transactionID (window.transID)
      ...: [unknown]
*     9: s.server, window.serverIP
*     10: Cookie['customerid'] customerID from cookie
      11: s.channel (Super Section name)
      12: Section name
      13: Topic name
      14: s.pageName
      15: [unknown]
      16-17: [NOT IN USE] set by channelManager in s.doPlugins()
      18: LeadForm yearly savings bucket
      19: Source Platform, used for SEM links
      20: Product Data by Keywords (set by channelManager in s.doPlugins())
*     21: Region (hard-code to "US")
      22: State
      ...: [unknown]
*     29: WS Search - wskw Results click: Keywords
*     30: Cookie['mktgtype'] Product Data by Mktg.
*     31: Cookie['src'] Product Data by Mktg. Source
*     32: Cookie['mktgemailtype']
*     33: Cookie['mktgemailid']
*     34: Cookie['mktgemailsection']
*     35: Cookie['matchType']
*     36: WS Search - pos Results click: Position
      37: Cookie['gclid'] Google Click ID - GCLID
*     38: Cookie['kw'] Product Data by Keyword (used by SEM)
*     39: Cookie['creative']
      40: Cookie['campaign']
*     41: Cookie['kwid'] Product Data by KeywordID (used by SEM)
*     42: Cookie['mktgqrystring'] query string (used by SEM)
*     43: Cookie['faq'] nano rep faq/article
      44: DMV.org Visitor ID
      45: JS window.splashProduct (no usage found)
      46: House Ad Topic
      47: # of Pages Viewed (must be set to Counter type in admin)
      48: Sidecar (sidecar.js): Search Term (showing results)
      49: Sidecar (sidecar.js): Title of Result Click
      51: Subsection name (template.tpl)
*     52: Mobile device (default="Other")
*     53: Mobile device type (default="Non-Mobile")
*     54: testVariableDesktop
*     55: testVariableMobile
*     56: testVariableUX
*     ...: [unknown]
*     59: Swimlane (random number 1-100, used for segmenting audiences for testing)
*     63: Performance Timing is active (value is NavType)
*     64: ?
*     65: Performance Timing Diff Buckets
*     66: Cookie['adgrpid'] Marketing ad group id
*     67: Halo topic tag
*     68: CMS NavID
*     69: CMS ConID
      73: Wizard Page
      75: Browser Frame Is Active - Page Visbility

s.prop
      1: State name
      2: Topic name
      3: WS Search - Keywords, set by SLI code
      4: WS Search - # of Results, set by SLI code
*     5: WS Search - State Origination (comes from "tz" GET param)
*     6: WS Search - Topic Origination (comes from "section" GET param)
      7: WS Search - Resuts Page #, set by SLI code
      ...: [unknown]
*     10: Template name (window.tplname)
      11: s.channel (Super Section name)
      12: Section name
      13: Topic name
      14: [unknown]
      15: Most Popular Subsections (Adobe processing rule that copies from eVar51
      16-19: [unknown]
*     20: transactionID (window.transID) - deactivated in adobe for now
*     21: Region (hard-code to "US")
      22: State name
      ...: [unknown]
      35: Cookie['gclid'] Google Click ID - GCLID
      ...: [unknown]
      44: WS Search - Organic Results, set by SLI code
      ...: [unknown]
      49: WS Search - Box Location, set by SLI code
      ...: [unknown]
*     52: Mobile device (default="Other")
*     53: Mobile device type (default="Non-Mobile")
*     54: WS Search - wskw Results click: Keywords
*     55: WS Search - pos Results click: Position
*     56: DMV.org Visitor ID (reserved for future use)

*/
        function hashString(str) {
            for (var hash = 0, i = 0, il = str.length; i < il; i++) {
                hash = ((hash << 5) - hash) + str.charCodeAt(i);
                hash = hash & hash; // Convert to 32bit integer
            }
            return base62encode(Math.abs(hash)); // return as base62
        }

        function randomString(len) {
            var base62chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
                arrLen = base62chars.length,
                str = '';
            for (; len-- > 0;) {
                // Protect against Math.random() returning 1 and causing an error by multiplying by .999
                str += base62chars.substr(Math.floor(Math.random() * 0.9999 * arrLen), 1);
            }
            return str;
        }

        function base62encode(integer) {
            if (integer <= 0) {return '0';}
            var base62chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
                arrLen = base62chars.length,
                s = '';
            while (integer > 0) {
                s = base62chars.substr(integer % arrLen, 1) + s;
                integer = Math.floor(integer/arrLen);
            }
            return s;
        }

        // Parse cookies once into object once so we're not re-getting and re-parsing in a loop
        // This is slightly more memory performant than calling readCookie()
        var cookieMap = {},
            semCampaign = document.cookie.split('; '),
            cookieVal = 0, _b, r;
        for (; cookieVal < semCampaign.length; cookieVal++) {
            _b = semCampaign[cookieVal].split('=', 2);
            r = (_b[1] || '').replace(/\+/g, ' ');
            try {
                cookieMap[_b[0]] = decodeURIComponent(r);
            }
            catch(e) {
                cookieMap[_b[0]] = r;
            }
        }

        var urlParams = (function() {
            // First time this function is run, parse the URL
            var all = location.search.substr(1).split('&'),
                parsed = {},
                i = 0,
                curSplit;
            for (; i < all.length; ++i) {
                curSplit = all[i].split('=', 2);
                parsed[curSplit[0]] = curSplit.length < 2 ? '' : decodeURIComponent(curSplit[1].replace(/\+/g, " "));
            }
            return parsed;
        })();

        /**
         * Look in URL params, fallback to cookie value.
         * Remove cookie value always, if exists.
         * @param {String} key
         * @returns {String}
         */
        function getOnce(key) {
            if (cookieMap[key]) {
                var suffix = ";expires=" + new Date(Date.now() - 60000).toUTCString();
                // For direct-set cookies
                document.cookie = key + "=" + suffix;
                // If Apache provided more explicitness
                document.cookie = key + "=;path=/;domain=" + location.hostname + suffix;
            }
            return urlParams[key] || cookieMap[key];
        }

        // the custom visitorID must not contain hyphens (we are using underscores)
        // https://marketing.adobe.com/developer/documentation/data-insertion/c-visitor-id
        cookieVal = cookieMap.vid;
        // Same logic as visittrack.js
        r = base62encode(Math.round((Date.now() / 1000) - 1451606400)); // number is \Util::TXID_SEED value
        if (!cookieVal) {
            cookieVal = r + '_' + (window.navigator && navigator.userAgent
                    ? hashString(navigator.userAgent) + randomString(4)
                    : randomString(12));
        }
        // expire vid cookie in 90 days
        document.cookie = "vid=" + cookieVal + ";expires="+ new Date(Date.now() + 1000*60*60*24*800).toUTCString() +
            ";path=/;domain=" + getCookieDomain();

        s.visitorID = s.eVar44 = cookieVal; // ID must be capitalized for s object

        // Reference the global variable directly (no local copy) in case something modifies it
        if (!window.transID) {
            // String starts with 'v' to signify it came from JavaScript VID
            // This string should be parseable by \model\RevenueSuite::txInfo($txid)
            window.transID = 'v' + location.hostname[0] + '_' + cookieVal + '_' + r + randomString(2);
        }

        // This is very important for CPM tracking!
        s.transactionID =
            s.eVar5 = window.transID; // from window._P array

        s.server = s.eVar9 = window.serverIP; // from window._P array
        s.prop10 = window.tplname; // from window._p array

        s.eVar4 = location.protocol + '//'
            + location.hostname
            + location.pathname
            + (location.search || '');

        s.eVar47 = "+1"; // increment Page Views Counter by one

        // Very-simple UA testing
        var device = /\b(iphone|ipad|ipod|android|droid|blackberry|nokia|opera mini|windows (mobile|phone)|iemobile)\b/i.exec(window.navigator.userAgent);
        s.eVar52 = s.prop52 = (device && device[0]) || 'Other';
        s.eVar53 = s.prop53 = (device && Math.min(window.screen.width, window.outerWidth) > 500 ? 'Tablet' : 'Mobile') || 'Non-Mobile';

        s.eVar68 = window.navid;
        s.eVar69 = window.conid;

        // Used in the Articles Transporter (db35.tpl), needs to be reset on every page
        s.eVar71 = "-";

        //page visibility initialization
        s.eVar75 = "initial";

        // SEM variables from EF
        // (Moved here from statecode_js.tpl)
        cookieVal = getOnce('utm_source'); // Hubspot UTM param
        cookieVal = getOnce('src') || cookieVal;// retrieve src val
        cookieVal = getOnce('source') || cookieVal;// but prefer fully-spelled-out "source" over "src"
        cookieVal = getOnce('gclsrc') || cookieVal;// prefer gclsrc over all others
        if (cookieVal) {
            s.eVar31 = cookieVal;
        }

        // mktgtype is also referenced in Ad_AdvertPro for setting custom10
        cookieVal = getOnce('utm_medium'); // Hubspot UTM param
        cookieVal = getOnce('mktgtype') || cookieVal;
        if (cookieVal) {
            s.eVar30 = cookieVal; // this used to set 'Other' when cookieVal falsy
        }

        cookieVal = getOnce('matchtype');
        if (cookieVal) {
            if (cookieVal.length === 1) {
                // convert single-char type into word
                cookieVal = ({E: 'EXACT', B: 'BROAD', P: 'PHRASE'})[cookieVal];
            }
            s.eVar35 = cookieVal;
        }

        cookieVal = getOnce('kw');
        if (cookieVal) {
            s.eVar38 = cookieVal; // should always be lowercase; SLI passes JS window.prequery_word
        }

        cookieVal = getOnce('creative');
        if (cookieVal) {
            s.eVar39 = cookieVal;
        }

        cookieVal = getOnce('utm_campaign'); // Hubspot UTM param
        cookieVal = getOnce('campaign') || cookieVal;
        if (cookieVal) {
            s.eVar40 = cookieVal;
        }

        cookieVal = getOnce('kwid');
        if (cookieVal) {
            s.eVar41 = cookieVal;
        }

        cookieVal = getOnce('mktgqrystring');
        if (cookieVal) {
            s.eVar42 = cookieVal;
        }

        cookieVal = getOnce('mktgemailtype');
        if (cookieVal) { // value might be '0', but will still be true
            //s.eVar30 = 'EMAIL';
            s.eVar32 = cookieVal;
        }

        cookieVal = getOnce('utm_content'); // Hubspot UTM param
        cookieVal = getOnce('mktgemailid') || cookieVal;
        if (cookieVal) {
            s.eVar33 = cookieVal;
        }

        cookieVal = getOnce('mktgemailsection');
        if (cookieVal) {
            s.eVar34 = cookieVal;
        }

        // IDS affiliate marketing
        cookieVal = getOnce('customerid');
        if (cookieVal) {
            s.eVar10 = cookieVal;
        }

        //
        // Will only trigger on click-target of SLI search link
        //
        cookieVal = getOnce('wskw');
        if (cookieVal) {
            s.prop54 = s.eVar29 = cookieVal;
        }
        cookieVal = getOnce('pos');
        if (cookieVal) {
            s.prop55 = s.eVar36 = cookieVal;
        }
        //
        // Will only trigger on SLI search result pages
        //
        cookieVal = getOnce('tz');
        if (cookieVal) {
            s.prop5 = cookieVal;
        }
        cookieVal = getOnce('section');
        if (cookieVal) {
            s.prop6 = cookieVal;
        }

        //
        // Google Adwords/Analytics
        //
        cookieVal = getOnce('gclid');
        if (cookieVal) {
            s.prop35 = s.eVar37 = cookieVal;
        }

        //
        // Google/Bing/3Q tracking param for origin SEM platform
        //
        cookieVal = getOnce('platform');
        if (cookieVal) {
            s.eVar19 = cookieVal;
        }

        cookieVal = getOnce('adgrpid');
        if (cookieVal) {
            s.eVar66 = cookieVal;
        }

        //
        // Visitor Tracking
        //
        cookieVal = getOnce('cid');
        if (cookieVal) {
            s.campaign = cookieVal;
        }

        //
        // Nanorep FAQ tracking
        //
        cookieVal = getOnce('faq');
        if (cookieVal) {
            s.eVar43 = 'faq:' + cookieVal; // 'faq:' prefix requested by Jenn
        }

        // Swimlanes (random value assigned to segment traffic)
        // set within BuiltPage::init(), this is a fallback routine
        cookieVal = cookieMap.swimlane;
        if (!/^\d\d$/.test(cookieVal)) {
            document.cookie='swimlane='
                // Using 0-padded string so that number always matches /[0-9][0-9]/
                + (cookieVal = ("0" + Math.floor(Math.random() * 99.999)).substr(-2))
                + ';path=/'
                + ';domain=' + location.hostname.replace(/^.*(\.[\w-]+\.[\w-]+)$/, '$1');
        }
        s.eVar59 = cookieVal;

        s.campaign = s.campaign || s.Util.getQueryParam('q,cid,camp,source');
        (function() {
            var // used for iterating over many things
                parts = s.linkInternalFilters.toLowerCase().split(','),
                referrer = (s.referrer || document.referrer || '').toLowerCase(),
                referrerNoQry, keywords, hasPartner,
                i, j, k;

            if (referrer) {
                referrerNoQry = referrer.replace(/\?.*$/, '');
                for (i = 0; i < parts.length; i++) {
                    if (referrerNoQry.indexOf(parts[i]) > -1) {
                        i = -1;
                        break;
                    }
                }

                if (i >= 0) {
                    parts = (/* top 130 Search Engines - Grouped */
                        "altavista.co,altavista.de|q,r|AltaVista"
                        + ">.aol.,suche.aolsvc.de|q,query|AOL"
                        + ">ask.jp,ask.co|q,ask|Ask"
                        + ">www.baidu.com|wd|Baidu"
                        + ">daum.net,search.daum.net|q|Daum"
                        + ">google.,googlesyndication.com|q,as_q|Google"
                        + ">icqit.com|q|icq"
                        + ">bing.com|q|Bing"
                        + ">myway.com|searchfor|MyWay.com"
                        + ">naver.com,search.naver.com|query|Naver"
                        + ">netscape.com|query,search|Netscape Search"
                        + ">reference.com|q|Reference.com"
                        + ">seznam|w|Seznam.cz"
                        + ">abcsok.no|q|Startsiden"
                        + ">tiscali.it,www.tiscali.co.uk|key,query|Tiscali"
                        + ">virgilio.it|qs|Virgilio"
                        + ">yahoo.com,yahoo.co.jp|p,va|Yahoo!"
                        + ">yandex|text|Yandex.ru"
                        + ">search.cnn.com|query|CNN Web Search"
                        + ">search.earthlink.net|q|Earthlink Search"
                        + ">search.comcast.net|q|Comcast Search"
                        + ">search.rr.com|qs|RoadRunner Search"
                        + ">optimum.net|q|Optimum Search"
                    ).split('>');
                    for (i = 0; i < parts.length; i++) {
                        var def = parts[i].split('|'),
                            domains = def[0].split(','),
                            params = def[1];
                        for (j = 0; j < domains.length; j++) {
                            if (referrerNoQry.indexOf(domains[j]) > -1) {
                                hasPartner = true;
                                //partnerName = def[2];
                                params = params.split(',');
                                for (k = 0; k < params.length; k++) {
                                    keywords = s.Util.getQueryParam(params[k], '', referrer).toLowerCase();
                                    if (keywords) break
                                }
                            }
                        }
                    }
                }

                parts = "Social Media|facebook.com,flickr.com,digg.com,twitter.com,myspace.com,youtube.com,linkedin.com,stumbleupon.com,del.icio.us,reddit.com,metacafe.com,technorati.com";
                parts = parts.split('>');
                for (i = 0; i < parts.length; i++) {
                    var q = parts[i].split('|');
                    var r = ('' + q[1]).split(',');
                    for (j = 0; j < r.length; j++) {
                        if (referrerNoQry.indexOf(r[j].toLowerCase()) > -1) {

                        }
                    }
                }
            }

            if (hasPartner) {
                // Keywords:
                s.eVar20 = keywords || (hasPartner ? 'Keyword Unavailable' : 'n/a');
            }

            var mktgSrc = s.eVar31,
                channel = s.eVar30,
                refDomain = referrer.replace(/^.{0,20}\/\//, '').replace(/^([^\/?]+).*$/, '$1'),
                isInternal = refDomain.indexOf(location.hostname.replace(/^.*(\.[\w-]+\.[\w-]+)$/, '$1')) > -1,
                a;
            // These rules are created by Jennifer within Omniture
            // Admin menu >> Report Suites >> [select] > Settings > Marketing Channels > Marketing Channel Processing Rules
            if (mktgSrc && /^SEM$/i.test(channel)) a = 'Paid Search';
            else if (mktgSrc && /social/i.test(channel)) a = 'Paid Social';
            else if (/email/i.test(channel)) a = 'Email';
            else if (/bmv\.com|enom_domain_redirects|godaddy_domain_redirects|IDriveSafely|IDS_Email/i.test(mktgSrc)) a = 'Affiliate';
            else if (/^vs-/.test(s.campaign)) a = 'Visitor Support';
            else if (hasPartner && !channel) a = 'Natural Search';
            else if (/facebook\.com|linkedin\.com|twitter\.com|plus\.google\.com|orkut\.com|friendster\.com|livejournal\.com|blogspot\.com|wordpress\.com|friendfeed\.com|myspace\.com|digg\.com|reddit\.com|stumbleupon\.com|twine\.com|yelp\.com|mixx\.com|delicious\.com|tumblr\.com|disqus\.com|intensedebate\.com|plurk\.com|slideshare\.net|backtype\.com|netvibes\.com|mister-wong\.com|diigo\.com|flixster\.com|youtube\.com|vimeo\.com|12seconds\.tv|zooomr\.com|identi\.ca|jaiku\.com|flickr\.com|imeem\.com|dailymotion\.com|photobucket\.com|fotolog\.com|smugmug\.com|classmates\.com|myyearbook\.com|mylife\.com|tagged\.com|brightkite\.com|ning\.com|bebo\.com|hi5\.com|yuku\.com|cafemom\.com|xanga\.com/i.test(refDomain)) a = 'Social Networks';
            //else if (location.hostname !== refDomain && isInternal) a = 'Internal';
            // Omniture tracks first-page-of-visit on SERVER SIDE, so "Internal" won't work correctly
            // This cookie is set by Omniture s.t() call to track sequence
            // It is a session cookie, but it's based on TLD so it won't correctly figure out cross-sub-domain referrers
            else if (!referrer && !cookieMap.s_sq) a = 'Direct';
            else if (referrer && !isInternal) a = 'Referrer';
            if (a) {
                s.eVar64 = crossVisitParticipation(a, 's_ev64', 0, 5, '>', '', 1);//, 'event9'/* Product Click */);
            }
        })();

        // Add window timing events as incrementers into the beacon
        var p, pt;
        if ((p = window.performance) && (pt = p.timing)) {
            var start = pt.connectStart || pt.fetchStart,
                // start by using domContentLoaded (if beacon is firing late)
                // fall back on "now"
                now = pt.domContentLoadedEventStart || (p.now ? pt.navigationStart + Math.round(p.now()) : Date.now()),
                // connectStart should fallback to fetchStart when 0, but just making sure
                // domLoading should always be there, but just in case fallback to "responseEnd" because it's the closest approximation
                topOfPage = normalizeMilli(start, (pt.domLoading || pt.responseEnd)),
                // now() is relative to navigationStart and is fractional
                // but it's not available in IE9
                // I'm using round
                bottomOfPage = normalizeMilli(start, now),
                diff = bottomOfPage - topOfPage,
                navType;
            if (0 < topOfPage && topOfPage <= bottomOfPage) {// we're getting weird scenarios where now() is BEFORE start
                navType = (navType = p.navigation) && navType.type;
                s.eVar63 = navType == 1 ? 'perfReload' : (navType == 2 ? 'perfBack' : 'perfNavigate');
                s.events += ",event33=" + Math.round(topOfPage*100) + ",event34=" + Math.round(bottomOfPage*100);
                s.eVar65 = diff > 2.5 ? '> 2.5s' : '< ' + (Math.ceil(diff / 0.25) * 0.25).toFixed(2) + 's';
            }
        }
        function normalizeMilli(start, end) {
            if (start > 0 && end > 0) {
                var diff = end - start;
                if (diff < 60000 && diff >= 0) {
                    return (diff / 1000);
                }
            }
            return -1;
        }

        // Testing option: listen to various global events
        //window.AppMeasurement_Module_Test = function() { console.log('Test Mod Loaded'); };
        //window.AppMeasurement_Module_Test.prototype._s = function() { console.log('_s', window.s); debugger; };
        //window.AppMeasurement_Module_Test.prototype._l = function() { console.log('_l', window.s); };
        //window.AppMeasurement_Module_Test.prototype._g = function() { console.log('_g', window.s); };
        //window.AppMeasurement_Module_Test.prototype._t = function() { console.log('_t', window.s); };
        //s.loadModule('Test');

        // note: we use variables like s.eVar30, s.eVar31, s.campaign in init.js for ga() calls

        return s;


        /**
         * Plug-in Example: crossVisitParticipation v1.7
         * In the following sample call:
         * A list of s.campaign values will be returned.  New s.campaign values will be appended to
         * the list.  The list will be stored in a cookie named "s_ev22".  Each s.campaign value will
         * be stored for 30 days and a maximum of 5 values will be stored in the campaigns list.  If
         * event2 is set the list will be cleared, and sequential duplicate values	are allowed.
         *
         * s.eVar22 = s.crossVisitParticipation(s.campaign,'s_ev22','30','5','>','event2',1);
         *
         * @param {string} src Input string
         * @param {string} cookieName Cookie Name
         * @param {number} ex Num hours until new entry can be added
         * @param {number} maxVals Num values within the cookie
         * @param {string} delim Delimiter separating values
         * @param {string=} ev If value is set, returned value will be single current value
         * @param {bool=} allowDup If duplicate values are allowed
         * @returns {string}
         */
        function crossVisitParticipation(src, cookieName, ex, maxVals, delim, ev, allowDup) {
            var isSingleVal, i, j;
            if (s.events && ev) {
                var ay = ev.split(','),
                    ea = s.events.split(',');
                for (i = 0; i < ay.length; i++) {
                    for (j = 0; j < ea.length; j++) {
                        if (ay[i] === ea[j]) {
                            isSingleVal = 1;
                            break;
                        }
                    }
                }
            }
            if (!src) {
                if (isSingleVal) {
                    s.c_w(cookieName, '');
                }
                return '';
            }
            src = encodeURIComponent(src);
            var history = [],
                cookieData = [],
                h = s.c_r(cookieName),
                now = new Date(),
                nowEpoch = Math.floor(now.getTime() / 1000);
            if (h) {
                history = h.split('^');
                for (i = 0; i < history.length; i++) {
                    history[i] = history[i].split('|')
                }
            }

            h = [src, nowEpoch];
            if (!allowDup && history.length && history[history.length - 1][0] === src) {
                history[history.length - 1] = h;
            } else {
                history.push(h);
            }

            h = [];
            for (i = Math.max(0, history.length - maxVals); i < history.length; i++) {
                j = history[i];
                // check time differential, using negative-boolean check just in case something triggers NaN
                if (!(Math.max(0, nowEpoch - j[1]) / 3600 < ex)) {
                    h.push(decodeURIComponent(j[0]));
                    cookieData.push(j[0] + '|' + j[1]);
                }
            }

            if (isSingleVal) {
                s.c_w(cookieName, '');
            } else {
                now.setFullYear(now.getFullYear() + 1);
                s.c_w(cookieName, cookieData.join('^'), now);
            }
            return h.join(delim);
        }
    }

    //adobe tracking for page visibility changes
    (function () {
        //support page visibility api for multiple browsers
        var hidden, visibilityChange;
        if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }
        if (typeof document.addEventListener !== "undefined" && hidden !== undefined) {
            document.addEventListener(visibilityChange, createTimer, false);
        }

        var timeout;
        var lastState = false;

        function createTimer() {
            clearTimeout(timeout);
            timeout = setTimeout(handleVisibilityChange, 50);
        }

        function handleVisibilityChange() {
            var h = !!document[hidden];
            if (lastState !== h) {
                lastState = h;
                ac(null, '', {
                    eVar75: h ? 'hidden' : 'visible'
                });
            }
        }
    })();

    /************************** PLUGINS SECTION *************************/

/* This is never used!
/**
 * Plugin: getValOnce_v1.0
 * @param {String} param
 * @param {String} cookie Cookie name
 * @param {Number=0} numDays
function getValOnce(param, cookie, numDays) {
    var date, k;
    cookie = (cookie||'s_gvo');
    k = s.c_r(cookie);
    param = (param||'');
    if (param) {
        numDays = (numDays||0);
        date = new Date;
        date.setTime(date.getTime()+numDays*86400000);
        s.c_w(cookie, param, numDays ? date : 0);
    }
    return param == k ? '' : param;
}
// Utility Functions: apl, p_c, p_gh, split, replace, join
// Replace v1.0, used by ChannelManager
s.repl = function (str,subj,replacement) { return str.split(subj).join(replacement) };
// split v1.5, used by ChannelManager
s.split = function (str,delimiter) { return (''+str).split(delimiter) };
//append list
s.apl=function(str,suffix,delim,u){var s=this,containsToken=0,idx,curToken,tokens;if(!str){str=''}if(u){tokens=s.split(str,delim);for(idx=0;idx<tokens.length;idx++){curToken=tokens[idx];containsToken=containsToken||(u==1?(curToken==suffix):(curToken.toLowerCase()===suffix.toLowerCase()))}}if(!containsToken){str=str?str+delim+suffix:suffix}return str};
// ver. 1.0 - s.join(v,p)| v - Array | p - formatting parameters (front,back,delim,wrap)
s.join=function(arr,options){var s=this,front,back,delim,wrap,str='',idx=0;if(options){front=options.front?options.front:'';back=options.back?options.back:'';delim=options.delim?options.delim:'';wrap=options.wrap?options.wrap:''}for(;idx<arr.length;idx++){if(typeof(arr[idx])=='object'){str+=s.join(arr[idx],options)}else{str+=wrap+arr[idx]+wrap}if(idx<arr.length-1){str+=delim}}return front+str+back};
/**/

})(window, document);
/*
 ============== DO NOT ALTER ANYTHING BELOW THIS LINE ! ===============
   Library is edited to use PREFIX attribute when set: (a.PREFIX||'/b/ss')

AppMeasurement for JavaScript version: 2.0.0
Copyright 1996-2016 Adobe, Inc. All Rights Reserved
More info available at http://www.adobe.com/marketing-cloud.html
*/
function AppMeasurement(){var a=this;a.version="2.0.0";var h=window;h.s_c_in||(h.s_c_il=[],h.s_c_in=0);a._il=h.s_c_il;a._in=h.s_c_in;a._il[a._in]=a;h.s_c_in++;a._c="s_c";var n=h.AppMeasurement.Ob;n||(n=null);var p=h,l,r;try{for(l=p.parent,r=p.location;l&&l.location&&r&&""+l.location!=""+r&&p.location&&""+l.location!=""+p.location&&l.location.host==r.host;)p=l,l=p.parent}catch(s){}a.P=function(a){try{console.log(a)}catch(b){}};a.La=function(a){return""+parseInt(a)==""+a};a.replace=function(a,b,d){return!a||
0>a.indexOf(b)?a:a.split(b).join(d)};a.escape=function(c){var b,d;if(!c)return c;c=encodeURIComponent(c);for(b=0;7>b;b++)d="+~!*()'".substring(b,b+1),0<=c.indexOf(d)&&(c=a.replace(c,d,"%"+d.charCodeAt(0).toString(16).toUpperCase()));return c};a.unescape=function(c){if(!c)return c;c=0<=c.indexOf("+")?a.replace(c,"+"," "):c;try{return decodeURIComponent(c)}catch(b){}return unescape(c)};a.vb=function(){var c=h.location.hostname,b=a.fpCookieDomainPeriods,d;b||(b=a.cookieDomainPeriods);if(c&&!a.cookieDomain&&
!/^[0-9.]+$/.test(c)&&(b=b?parseInt(b):2,b=2<b?b:2,d=c.lastIndexOf("."),0<=d)){for(;0<=d&&1<b;)d=c.lastIndexOf(".",d-1),b--;a.cookieDomain=0<d?c.substring(d):c}return a.cookieDomain};a.c_r=a.cookieRead=function(c){c=a.escape(c);var b=" "+a.d.cookie,d=b.indexOf(" "+c+"="),f=0>d?d:b.indexOf(";",d);c=0>d?"":a.unescape(b.substring(d+2+c.length,0>f?b.length:f));return"[[B]]"!=c?c:""};a.c_w=a.cookieWrite=function(c,b,d){var f=a.vb(),e=a.cookieLifetime,g;b=""+b;e=e?(""+e).toUpperCase():"";d&&"SESSION"!=
e&&"NONE"!=e&&((g=""!=b?parseInt(e?e:0):-60)?(d=new Date,d.setTime(d.getTime()+1E3*g)):1==d&&(d=new Date,g=d.getYear(),d.setYear(g+5+(1900>g?1900:0))));return c&&"NONE"!=e?(a.d.cookie=a.escape(c)+"="+a.escape(""!=b?b:"[[B]]")+"; path=/;"+(d&&"SESSION"!=e?" expires="+d.toGMTString()+";":"")+(f?" domain="+f+";":""),a.cookieRead(c)==b):0};a.K=[];a.ia=function(c,b,d){if(a.Ea)return 0;a.maxDelay||(a.maxDelay=250);var f=0,e=(new Date).getTime()+a.maxDelay,g=a.d.visibilityState,k=["webkitvisibilitychange",
"visibilitychange"];g||(g=a.d.webkitVisibilityState);if(g&&"prerender"==g){if(!a.ja)for(a.ja=1,d=0;d<k.length;d++)a.d.addEventListener(k[d],function(){var c=a.d.visibilityState;c||(c=a.d.webkitVisibilityState);"visible"==c&&(a.ja=0,a.delayReady())});f=1;e=0}else d||a.p("_d")&&(f=1);f&&(a.K.push({m:c,a:b,t:e}),a.ja||setTimeout(a.delayReady,a.maxDelay));return f};a.delayReady=function(){var c=(new Date).getTime(),b=0,d;for(a.p("_d")?b=1:a.xa();0<a.K.length;){d=a.K.shift();if(b&&!d.t&&d.t>c){a.K.unshift(d);
setTimeout(a.delayReady,parseInt(a.maxDelay/2));break}a.Ea=1;a[d.m].apply(a,d.a);a.Ea=0}};a.setAccount=a.sa=function(c){var b,d;if(!a.ia("setAccount",arguments))if(a.account=c,a.allAccounts)for(b=a.allAccounts.concat(c.split(",")),a.allAccounts=[],b.sort(),d=0;d<b.length;d++)0!=d&&b[d-1]==b[d]||a.allAccounts.push(b[d]);else a.allAccounts=c.split(",")};a.foreachVar=function(c,b){var d,f,e,g,k="";e=f="";if(a.lightProfileID)d=a.O,(k=a.lightTrackVars)&&(k=","+k+","+a.na.join(",")+",");else{d=a.g;if(a.pe||
a.linkType)k=a.linkTrackVars,f=a.linkTrackEvents,a.pe&&(e=a.pe.substring(0,1).toUpperCase()+a.pe.substring(1),a[e]&&(k=a[e].Mb,f=a[e].Lb));k&&(k=","+k+","+a.G.join(",")+",");f&&k&&(k+=",events,")}b&&(b=","+b+",");for(f=0;f<d.length;f++)e=d[f],(g=a[e])&&(!k||0<=k.indexOf(","+e+","))&&(!b||0<=b.indexOf(","+e+","))&&c(e,g)};a.r=function(c,b,d,f,e){var g="",k,m,h,t,l=0;"contextData"==c&&(c="c");if(b){for(k in b)if(!(Object.prototype[k]||e&&k.substring(0,e.length)!=e)&&b[k]&&(!d||0<=d.indexOf(","+(f?f+
".":"")+k+","))){h=!1;if(l)for(m=0;m<l.length;m++)k.substring(0,l[m].length)==l[m]&&(h=!0);if(!h&&(""==g&&(g+="&"+c+"."),m=b[k],e&&(k=k.substring(e.length)),0<k.length))if(h=k.indexOf("."),0<h)m=k.substring(0,h),h=(e?e:"")+m+".",l||(l=[]),l.push(h),g+=a.r(m,b,d,f,h);else if("boolean"==typeof m&&(m=m?"true":"false"),m){if("retrieveLightData"==f&&0>e.indexOf(".contextData."))switch(h=k.substring(0,4),t=k.substring(4),k){case "transactionID":k="xact";break;case "channel":k="ch";break;case "campaign":k=
"v0";break;default:a.La(t)&&("prop"==h?k="c"+t:"eVar"==h?k="v"+t:"list"==h?k="l"+t:"hier"==h&&(k="h"+t,m=m.substring(0,255)))}g+="&"+a.escape(k)+"="+a.escape(m)}}""!=g&&(g+="&."+c)}return g};a.usePostbacks=0;a.yb=function(){var c="",b,d,f,e,g,k,m,h,l="",p="",q=e="";if(a.lightProfileID)b=a.O,(l=a.lightTrackVars)&&(l=","+l+","+a.na.join(",")+",");else{b=a.g;if(a.pe||a.linkType)l=a.linkTrackVars,p=a.linkTrackEvents,a.pe&&(e=a.pe.substring(0,1).toUpperCase()+a.pe.substring(1),a[e]&&(l=a[e].Mb,p=a[e].Lb));
l&&(l=","+l+","+a.G.join(",")+",");p&&(p=","+p+",",l&&(l+=",events,"));a.events2&&(q+=(""!=q?",":"")+a.events2)}if(a.visitor&&a.visitor.getCustomerIDs){e=n;if(g=a.visitor.getCustomerIDs())for(d in g)Object.prototype[d]||(f=g[d],"object"==typeof f&&(e||(e={}),f.id&&(e[d+".id"]=f.id),f.authState&&(e[d+".as"]=f.authState)));e&&(c+=a.r("cid",e))}a.AudienceManagement&&a.AudienceManagement.isReady()&&(c+=a.r("d",a.AudienceManagement.getEventCallConfigParams()));for(d=0;d<b.length;d++){e=b[d];g=a[e];f=e.substring(0,
4);k=e.substring(4);!g&&"events"==e&&q&&(g=q,q="");if(g&&(!l||0<=l.indexOf(","+e+","))){switch(e){case "supplementalDataID":e="sdid";break;case "timestamp":e="ts";break;case "dynamicVariablePrefix":e="D";break;case "visitorID":e="vid";break;case "marketingCloudVisitorID":e="mid";break;case "analyticsVisitorID":e="aid";break;case "audienceManagerLocationHint":e="aamlh";break;case "audienceManagerBlob":e="aamb";break;case "authState":e="as";break;case "pageURL":e="g";255<g.length&&(a.pageURLRest=g.substring(255),
g=g.substring(0,255));break;case "pageURLRest":e="-g";break;case "referrer":e="r";break;case "vmk":case "visitorMigrationKey":e="vmt";break;case "visitorMigrationServer":e="vmf";a.ssl&&a.visitorMigrationServerSecure&&(g="");break;case "visitorMigrationServerSecure":e="vmf";!a.ssl&&a.visitorMigrationServer&&(g="");break;case "charSet":e="ce";break;case "visitorNamespace":e="ns";break;case "cookieDomainPeriods":e="cdp";break;case "cookieLifetime":e="cl";break;case "variableProvider":e="vvp";break;case "currencyCode":e=
"cc";break;case "channel":e="ch";break;case "transactionID":e="xact";break;case "campaign":e="v0";break;case "latitude":e="lat";break;case "longitude":e="lon";break;case "resolution":e="s";break;case "colorDepth":e="c";break;case "javascriptVersion":e="j";break;case "javaEnabled":e="v";break;case "cookiesEnabled":e="k";break;case "browserWidth":e="bw";break;case "browserHeight":e="bh";break;case "connectionType":e="ct";break;case "homepage":e="hp";break;case "events":q&&(g+=(""!=g?",":"")+q);if(p)for(k=
g.split(","),g="",f=0;f<k.length;f++)m=k[f],h=m.indexOf("="),0<=h&&(m=m.substring(0,h)),h=m.indexOf(":"),0<=h&&(m=m.substring(0,h)),0<=p.indexOf(","+m+",")&&(g+=(g?",":"")+k[f]);break;case "events2":g="";break;case "contextData":c+=a.r("c",a[e],l,e);g="";break;case "lightProfileID":e="mtp";break;case "lightStoreForSeconds":e="mtss";a.lightProfileID||(g="");break;case "lightIncrementBy":e="mti";a.lightProfileID||(g="");break;case "retrieveLightProfiles":e="mtsr";break;case "deleteLightProfiles":e=
"mtsd";break;case "retrieveLightData":a.retrieveLightProfiles&&(c+=a.r("mts",a[e],l,e));g="";break;default:a.La(k)&&("prop"==f?e="c"+k:"eVar"==f?e="v"+k:"list"==f?e="l"+k:"hier"==f&&(e="h"+k,g=g.substring(0,255)))}g&&(c+="&"+e+"="+("pev"!=e.substring(0,3)?a.escape(g):g))}"pev3"==e&&a.e&&(c+=a.e)}return c};a.D=function(a){var b=a.tagName;if("undefined"!=""+a.Rb||"undefined"!=""+a.Hb&&"HTML"!=(""+a.Hb).toUpperCase())return"";b=b&&b.toUpperCase?b.toUpperCase():"";"SHAPE"==b&&(b="");b&&(("INPUT"==b||
"BUTTON"==b)&&a.type&&a.type.toUpperCase?b=a.type.toUpperCase():!b&&a.href&&(b="A"));return b};a.Ha=function(a){var b=h.location,d=a.href?a.href:"",f,e,g;f=d.indexOf(":");e=d.indexOf("?");g=d.indexOf("/");d&&(0>f||0<=e&&f>e||0<=g&&f>g)&&(e=a.protocol&&1<a.protocol.length?a.protocol:b.protocol?b.protocol:"",f=b.pathname.lastIndexOf("/"),d=(e?e+"//":"")+(a.host?a.host:b.host?b.host:"")+("/"!=d.substring(0,1)?b.pathname.substring(0,0>f?0:f)+"/":"")+d);return d};a.L=function(c){var b=a.D(c),d,f,e="",
g=0;return b&&(d=c.protocol,f=c.onclick,!c.href||"A"!=b&&"AREA"!=b||f&&d&&!(0>d.toLowerCase().indexOf("javascript"))?f?(e=a.replace(a.replace(a.replace(a.replace(""+f,"\r",""),"\n",""),"\t","")," ",""),g=2):"INPUT"==b||"SUBMIT"==b?(c.value?e=c.value:c.innerText?e=c.innerText:c.textContent&&(e=c.textContent),g=3):"IMAGE"==b&&c.src&&(e=c.src):e=a.Ha(c),e)?{id:e.substring(0,100),type:g}:0};a.Pb=function(c){for(var b=a.D(c),d=a.L(c);c&&!d&&"BODY"!=b;)if(c=c.parentElement?c.parentElement:c.parentNode)b=
a.D(c),d=a.L(c);d&&"BODY"!=b||(c=0);c&&(b=c.onclick?""+c.onclick:"",0<=b.indexOf(".tl(")||0<=b.indexOf(".trackLink("))&&(c=0);return c};a.Gb=function(){var c,b,d=a.linkObject,f=a.linkType,e=a.linkURL,g,k;a.oa=1;d||(a.oa=0,d=a.clickObject);if(d){c=a.D(d);for(b=a.L(d);d&&!b&&"BODY"!=c;)if(d=d.parentElement?d.parentElement:d.parentNode)c=a.D(d),b=a.L(d);b&&"BODY"!=c||(d=0);if(d&&!a.linkObject){var m=d.onclick?""+d.onclick:"";if(0<=m.indexOf(".tl(")||0<=m.indexOf(".trackLink("))d=0}}else a.oa=1;!e&&d&&
(e=a.Ha(d));e&&!a.linkLeaveQueryString&&(g=e.indexOf("?"),0<=g&&(e=e.substring(0,g)));if(!f&&e){var l=0,p=0,n;if(a.trackDownloadLinks&&a.linkDownloadFileTypes)for(m=e.toLowerCase(),g=m.indexOf("?"),k=m.indexOf("#"),0<=g?0<=k&&k<g&&(g=k):g=k,0<=g&&(m=m.substring(0,g)),g=a.linkDownloadFileTypes.toLowerCase().split(","),k=0;k<g.length;k++)(n=g[k])&&m.substring(m.length-(n.length+1))=="."+n&&(f="d");if(a.trackExternalLinks&&!f&&(m=e.toLowerCase(),a.Ka(m)&&(a.linkInternalFilters||(a.linkInternalFilters=
h.location.hostname),g=0,a.linkExternalFilters?(g=a.linkExternalFilters.toLowerCase().split(","),l=1):a.linkInternalFilters&&(g=a.linkInternalFilters.toLowerCase().split(",")),g))){for(k=0;k<g.length;k++)n=g[k],0<=m.indexOf(n)&&(p=1);p?l&&(f="e"):l||(f="e")}}a.linkObject=d;a.linkURL=e;a.linkType=f;if(a.trackClickMap||a.trackInlineStats)a.e="",d&&(f=a.pageName,e=1,d=d.sourceIndex,f||(f=a.pageURL,e=0),h.s_objectID&&(b.id=h.s_objectID,d=b.type=1),f&&b&&b.id&&c&&(a.e="&pid="+a.escape(f.substring(0,255))+
(e?"&pidt="+e:"")+"&oid="+a.escape(b.id.substring(0,100))+(b.type?"&oidt="+b.type:"")+"&ot="+c+(d?"&oi="+d:"")))};a.zb=function(){var c=a.oa,b=a.linkType,d=a.linkURL,f=a.linkName;b&&(d||f)&&(b=b.toLowerCase(),"d"!=b&&"e"!=b&&(b="o"),a.pe="lnk_"+b,a.pev1=d?a.escape(d):"",a.pev2=f?a.escape(f):"",c=1);a.abort&&(c=0);if(a.trackClickMap||a.trackInlineStats||a.ActivityMap){var b={},d=0,e=a.cookieRead("s_sq"),g=e?e.split("&"):0,k,m,h,e=0;if(g)for(k=0;k<g.length;k++)m=g[k].split("="),f=a.unescape(m[0]).split(","),
m=a.unescape(m[1]),b[m]=f;f=a.account.split(",");k={};for(h in a.contextData)h&&!Object.prototype[h]&&"a.activitymap."==h.substring(0,14)&&(k[h]=a.contextData[h],a.contextData[h]="");a.e=a.r("c",k)+(a.e?a.e:"");if(c||a.e){c&&!a.e&&(e=1);for(m in b)if(!Object.prototype[m])for(h=0;h<f.length;h++)for(e&&(g=b[m].join(","),g==a.account&&(a.e+=("&"!=m.charAt(0)?"&":"")+m,b[m]=[],d=1)),k=0;k<b[m].length;k++)g=b[m][k],g==f[h]&&(e&&(a.e+="&u="+a.escape(g)+("&"!=m.charAt(0)?"&":"")+m+"&u=0"),b[m].splice(k,
1),d=1);c||(d=1);if(d){e="";k=2;!c&&a.e&&(e=a.escape(f.join(","))+"="+a.escape(a.e),k=1);for(m in b)!Object.prototype[m]&&0<k&&0<b[m].length&&(e+=(e?"&":"")+a.escape(b[m].join(","))+"="+a.escape(m),k--);a.cookieWrite("s_sq",e)}}}return c};a.Ab=function(){if(!a.Kb){var c=new Date,b=p.location,d,f,e=f=d="",g="",k="",h="1.2",l=a.cookieWrite("s_cc","true",0)?"Y":"N",n="",q="";if(c.setUTCDate&&(h="1.3",(0).toPrecision&&(h="1.5",c=[],c.forEach))){h="1.6";f=0;d={};try{f=new Iterator(d),f.next&&(h="1.7",
c.reduce&&(h="1.8",h.trim&&(h="1.8.1",Date.parse&&(h="1.8.2",Object.create&&(h="1.8.5")))))}catch(r){}}d=screen.width+"x"+screen.height;e=navigator.javaEnabled()?"Y":"N";f=screen.pixelDepth?screen.pixelDepth:screen.colorDepth;g=a.w.innerWidth?a.w.innerWidth:a.d.documentElement.offsetWidth;k=a.w.innerHeight?a.w.innerHeight:a.d.documentElement.offsetHeight;try{a.b.addBehavior("#default#homePage"),n=a.b.Qb(b)?"Y":"N"}catch(s){}try{a.b.addBehavior("#default#clientCaps"),q=a.b.connectionType}catch(u){}a.resolution=
d;a.colorDepth=f;a.javascriptVersion=h;a.javaEnabled=e;a.cookiesEnabled=l;a.browserWidth=g;a.browserHeight=k;a.connectionType=q;a.homepage=n;a.Kb=1}};a.Q={};a.loadModule=function(c,b){var d=a.Q[c];if(!d){d=h["AppMeasurement_Module_"+c]?new h["AppMeasurement_Module_"+c](a):{};a.Q[c]=a[c]=d;d.cb=function(){return d.hb};d.ib=function(b){if(d.hb=b)a[c+"_onLoad"]=b,a.ia(c+"_onLoad",[a,d],1)||b(a,d)};try{Object.defineProperty?Object.defineProperty(d,"onLoad",{get:d.cb,set:d.ib}):d._olc=1}catch(f){d._olc=
1}}b&&(a[c+"_onLoad"]=b,a.ia(c+"_onLoad",[a,d],1)||b(a,d))};a.p=function(c){var b,d;for(b in a.Q)if(!Object.prototype[b]&&(d=a.Q[b])&&(d._olc&&d.onLoad&&(d._olc=0,d.onLoad(a,d)),d[c]&&d[c]()))return 1;return 0};a.Cb=function(){var c=Math.floor(1E13*Math.random()),b=a.visitorSampling,d=a.visitorSamplingGroup,d="s_vsn_"+(a.visitorNamespace?a.visitorNamespace:a.account)+(d?"_"+d:""),f=a.cookieRead(d);if(b){b*=100;f&&(f=parseInt(f));if(!f){if(!a.cookieWrite(d,c))return 0;f=c}if(f%1E4>b)return 0}return 1};
a.R=function(c,b){var d,f,e,g,k,h;for(d=0;2>d;d++)for(f=0<d?a.Aa:a.g,e=0;e<f.length;e++)if(g=f[e],(k=c[g])||c["!"+g]){if(!b&&("contextData"==g||"retrieveLightData"==g)&&a[g])for(h in a[g])k[h]||(k[h]=a[g][h]);a[g]=k}};a.Ua=function(c,b){var d,f,e,g;for(d=0;2>d;d++)for(f=0<d?a.Aa:a.g,e=0;e<f.length;e++)g=f[e],c[g]=a[g],b||c[g]||(c["!"+g]=1)};a.ub=function(a){var b,d,f,e,g,k=0,h,l="",n="";if(a&&255<a.length&&(b=""+a,d=b.indexOf("?"),0<d&&(h=b.substring(d+1),b=b.substring(0,d),e=b.toLowerCase(),f=0,
"http://"==e.substring(0,7)?f+=7:"https://"==e.substring(0,8)&&(f+=8),d=e.indexOf("/",f),0<d&&(e=e.substring(f,d),g=b.substring(d),b=b.substring(0,d),0<=e.indexOf("google")?k=",q,ie,start,search_key,word,kw,cd,":0<=e.indexOf("yahoo.co")&&(k=",p,ei,"),k&&h)))){if((a=h.split("&"))&&1<a.length){for(f=0;f<a.length;f++)e=a[f],d=e.indexOf("="),0<d&&0<=k.indexOf(","+e.substring(0,d)+",")?l+=(l?"&":"")+e:n+=(n?"&":"")+e;l&&n?h=l+"&"+n:n=""}d=253-(h.length-n.length)-b.length;a=b+(0<d?g.substring(0,d):"")+
"?"+h}return a};a.$a=function(c){var b=a.d.visibilityState,d=["webkitvisibilitychange","visibilitychange"];b||(b=a.d.webkitVisibilityState);if(b&&"prerender"==b){if(c)for(b=0;b<d.length;b++)a.d.addEventListener(d[b],function(){var b=a.d.visibilityState;b||(b=a.d.webkitVisibilityState);"visible"==b&&c()});return!1}return!0};a.ea=!1;a.I=!1;a.kb=function(){a.I=!0;a.j()};a.ca=!1;a.V=!1;a.gb=function(c){a.marketingCloudVisitorID=c;a.V=!0;a.j()};a.fa=!1;a.W=!1;a.lb=function(c){a.visitorOptedOut=c;a.W=!0;
a.j()};a.Z=!1;a.S=!1;a.Wa=function(c){a.analyticsVisitorID=c;a.S=!0;a.j()};a.ba=!1;a.U=!1;a.Ya=function(c){a.audienceManagerLocationHint=c;a.U=!0;a.j()};a.aa=!1;a.T=!1;a.Xa=function(c){a.audienceManagerBlob=c;a.T=!0;a.j()};a.Za=function(c){a.maxDelay||(a.maxDelay=250);return a.p("_d")?(c&&setTimeout(function(){c()},a.maxDelay),!1):!0};a.da=!1;a.H=!1;a.xa=function(){a.H=!0;a.j()};a.isReadyToTrack=function(){var c=!0,b=a.visitor,d,f,e;a.ea||a.I||(a.$a(a.kb)?a.I=!0:a.ea=!0);if(a.ea&&!a.I)return!1;b&&
b.isAllowed()&&(a.ca||a.marketingCloudVisitorID||!b.getMarketingCloudVisitorID||(a.ca=!0,a.marketingCloudVisitorID=b.getMarketingCloudVisitorID([a,a.gb]),a.marketingCloudVisitorID&&(a.V=!0)),a.fa||a.visitorOptedOut||!b.isOptedOut||(a.fa=!0,a.visitorOptedOut=b.isOptedOut([a,a.lb]),a.visitorOptedOut!=n&&(a.W=!0)),a.Z||a.analyticsVisitorID||!b.getAnalyticsVisitorID||(a.Z=!0,a.analyticsVisitorID=b.getAnalyticsVisitorID([a,a.Wa]),a.analyticsVisitorID&&(a.S=!0)),a.ba||a.audienceManagerLocationHint||!b.getAudienceManagerLocationHint||
(a.ba=!0,a.audienceManagerLocationHint=b.getAudienceManagerLocationHint([a,a.Ya]),a.audienceManagerLocationHint&&(a.U=!0)),a.aa||a.audienceManagerBlob||!b.getAudienceManagerBlob||(a.aa=!0,a.audienceManagerBlob=b.getAudienceManagerBlob([a,a.Xa]),a.audienceManagerBlob&&(a.T=!0)),c=a.ca&&!a.V&&!a.marketingCloudVisitorID,b=a.Z&&!a.S&&!a.analyticsVisitorID,d=a.ba&&!a.U&&!a.audienceManagerLocationHint,f=a.aa&&!a.T&&!a.audienceManagerBlob,e=a.fa&&!a.W,c=c||b||d||f||e?!1:!0);a.da||a.H||(a.Za(a.xa)?a.H=!0:
a.da=!0);a.da&&!a.H&&(c=!1);return c};a.o=n;a.u=0;a.callbackWhenReadyToTrack=function(c,b,d){var f;f={};f.pb=c;f.ob=b;f.mb=d;a.o==n&&(a.o=[]);a.o.push(f);0==a.u&&(a.u=setInterval(a.j,100))};a.j=function(){var c;if(a.isReadyToTrack()&&(a.jb(),a.o!=n))for(;0<a.o.length;)c=a.o.shift(),c.ob.apply(c.pb,c.mb)};a.jb=function(){a.u&&(clearInterval(a.u),a.u=0)};a.eb=function(c){var b,d,f=n,e=n;if(!a.isReadyToTrack()){b=[];if(c!=n)for(d in f={},c)f[d]=c[d];e={};a.Ua(e,!0);b.push(f);b.push(e);a.callbackWhenReadyToTrack(a,
a.track,b);return!0}return!1};a.wb=function(){var c=a.cookieRead("s_fid"),b="",d="",f;f=8;var e=4;if(!c||0>c.indexOf("-")){for(c=0;16>c;c++)f=Math.floor(Math.random()*f),b+="0123456789ABCDEF".substring(f,f+1),f=Math.floor(Math.random()*e),d+="0123456789ABCDEF".substring(f,f+1),f=e=16;c=b+"-"+d}a.cookieWrite("s_fid",c,1)||(c=0);return c};a.t=a.track=function(c,b){var d,f=new Date,e="s"+Math.floor(f.getTime()/108E5)%10+Math.floor(1E13*Math.random()),g=f.getYear(),g="t="+a.escape(f.getDate()+"/"+f.getMonth()+
"/"+(1900>g?g+1900:g)+" "+f.getHours()+":"+f.getMinutes()+":"+f.getSeconds()+" "+f.getDay()+" "+f.getTimezoneOffset());a.visitor&&a.visitor.getAuthState&&(a.authState=a.visitor.getAuthState());a.p("_s");a.eb(c)||(b&&a.R(b),c&&(d={},a.Ua(d,0),a.R(c)),a.Cb()&&!a.visitorOptedOut&&(a.analyticsVisitorID||a.marketingCloudVisitorID||(a.fid=a.wb()),a.Gb(),a.usePlugins&&a.doPlugins&&a.doPlugins(a),a.account&&(a.abort||(a.trackOffline&&!a.timestamp&&(a.timestamp=Math.floor(f.getTime()/1E3)),f=h.location,a.pageURL||
(a.pageURL=f.href?f.href:f),a.referrer||a.Va||(a.referrer=p.document.referrer),a.Va=1,a.referrer=a.ub(a.referrer),a.p("_g")),a.zb()&&!a.abort&&(a.visitor&&!a.supplementalDataID&&a.visitor.getSupplementalDataID&&(a.supplementalDataID=a.visitor.getSupplementalDataID("AppMeasurement:"+a._in,a.expectSupplementalData?!1:!0)),a.Ab(),g+=a.yb(),a.Fb(e,g),a.p("_t"),a.referrer=""))),c&&a.R(d,1));a.abort=a.supplementalDataID=a.timestamp=a.pageURLRest=a.linkObject=a.clickObject=a.linkURL=a.linkName=a.linkType=
h.s_objectID=a.pe=a.pev1=a.pev2=a.pev3=a.e=a.lightProfileID=0};a.za=[];a.registerPreTrackCallback=function(c){for(var b=[],d=1;d<arguments.length;d++)b.push(arguments[d]);"function"==typeof c?a.za.push([c,b]):a.debugTracking&&a.P("DEBUG: Non function type passed to registerPreTrackCallback")};a.bb=function(c){a.wa(a.za,c)};a.ya=[];a.registerPostTrackCallback=function(c){for(var b=[],d=1;d<arguments.length;d++)b.push(arguments[d]);"function"==typeof c?a.ya.push([c,b]):a.debugTracking&&a.P("DEBUG: Non function type passed to registerPostTrackCallback")};
a.ab=function(c){a.wa(a.ya,c)};a.wa=function(c,b){if("object"==typeof c)for(var d=0;d<c.length;d++){var f=c[d][0],e=c[d][1];e.unshift(b);if("function"==typeof f)try{f.apply(null,e)}catch(g){a.debugTracking&&a.P(g.message)}}};a.tl=a.trackLink=function(c,b,d,f,e){a.linkObject=c;a.linkType=b;a.linkName=d;e&&(a.l=c,a.A=e);return a.track(f)};a.trackLight=function(c,b,d,f){a.lightProfileID=c;a.lightStoreForSeconds=b;a.lightIncrementBy=d;return a.track(f)};a.clearVars=function(){var c,b;for(c=0;c<a.g.length;c++)if(b=
a.g[c],"prop"==b.substring(0,4)||"eVar"==b.substring(0,4)||"hier"==b.substring(0,4)||"list"==b.substring(0,4)||"channel"==b||"events"==b||"eventList"==b||"products"==b||"productList"==b||"purchaseID"==b||"transactionID"==b||"state"==b||"zip"==b||"campaign"==b)a[b]=void 0};a.tagContainerMarker="";a.Fb=function(c,b){var d,f=a.trackingServer;d="";var e=a.dc,g="sc.",h=a.visitorNamespace;f?a.trackingServerSecure&&a.ssl&&(f=a.trackingServerSecure):(h||(h=a.account,f=h.indexOf(","),0<=f&&(h=h.substring(0,
f)),h=h.replace(/[^A-Za-z0-9]/g,"")),d||(d="2o7.net"),e=e?(""+e).toLowerCase():"d1","2o7.net"==d&&("d1"==e?e="112":"d2"==e&&(e="122"),g=""),f=h+"."+e+"."+g+d);d=a.ssl?"https://":"http://";e=a.AudienceManagement&&a.AudienceManagement.isReady()||0!=a.usePostbacks;d+=f+(a.PREFIX||'/b/ss/')+a.account+"/"+(a.mobile?"5.":"")+(e?"10":"1")+"/JS-"+a.version+(a.Jb?"T":"")+(a.tagContainerMarker?"-"+a.tagContainerMarker:"")+"/"+c+"?AQB=1&ndh=1&pf=1&"+(e?"callback=s_c_il["+a._in+"].doPostbacks&et=1&":"")+b+"&AQE=1";a.bb(d);
a.sb(d);a.ka()};a.Ta=/{(%?)(.*?)(%?)}/;a.Nb=RegExp(a.Ta.source,"g");a.tb=function(c){if("object"==typeof c.dests)for(var b=0;b<c.dests.length;++b){var d=c.dests[b];if("string"==typeof d.c&&"aa."==d.id.substr(0,3))for(var f=d.c.match(a.Nb),e=0;e<f.length;++e){var g=f[e],h=g.match(a.Ta),l="";"%"==h[1]&&"timezone_offset"==h[2]?l=(new Date).getTimezoneOffset():"%"==h[1]&&"timestampz"==h[2]&&(l=a.xb());d.c=d.c.replace(g,a.escape(l))}}};a.xb=function(){var c=new Date,b=new Date(6E4*Math.abs(c.getTimezoneOffset()));
return a.k(4,c.getFullYear())+"-"+a.k(2,c.getMonth()+1)+"-"+a.k(2,c.getDate())+"T"+a.k(2,c.getHours())+":"+a.k(2,c.getMinutes())+":"+a.k(2,c.getSeconds())+(0<c.getTimezoneOffset()?"-":"+")+a.k(2,b.getUTCHours())+":"+a.k(2,b.getUTCMinutes())};a.k=function(a,b){return(Array(a+1).join(0)+b).slice(-a)};a.ta={};a.doPostbacks=function(c){if("object"==typeof c)if(a.tb(c),"object"==typeof a.AudienceManagement&&"function"==typeof a.AudienceManagement.isReady&&a.AudienceManagement.isReady()&&"function"==typeof a.AudienceManagement.passData)a.AudienceManagement.passData(c);
else if("object"==typeof c&&"object"==typeof c.dests)for(var b=0;b<c.dests.length;++b){var d=c.dests[b];"object"==typeof d&&"string"==typeof d.c&&"string"==typeof d.id&&"aa."==d.id.substr(0,3)&&(a.ta[d.id]=new Image,a.ta[d.id].alt="",a.ta[d.id].src=d.c)}};a.sb=function(c){a.i||a.Bb();a.i.push(c);a.ma=a.C();a.Ra()};a.Bb=function(){a.i=a.Db();a.i||(a.i=[])};a.Db=function(){var c,b;if(a.ra()){try{(b=h.localStorage.getItem(a.pa()))&&(c=h.JSON.parse(b))}catch(d){}return c}};a.ra=function(){var c=!0;a.trackOffline&&
a.offlineFilename&&h.localStorage&&h.JSON||(c=!1);return c};a.Ia=function(){var c=0;a.i&&(c=a.i.length);a.q&&c++;return c};a.ka=function(){if(a.q&&(a.B&&a.B.complete&&a.B.F&&a.B.va(),a.q))return;a.Ja=n;if(a.qa)a.ma>a.N&&a.Pa(a.i),a.ua(500);else{var c=a.nb();if(0<c)a.ua(c);else if(c=a.Fa())a.q=1,a.Eb(c),a.Ib(c)}};a.ua=function(c){a.Ja||(c||(c=0),a.Ja=setTimeout(a.ka,c))};a.nb=function(){var c;if(!a.trackOffline||0>=a.offlineThrottleDelay)return 0;c=a.C()-a.Oa;return a.offlineThrottleDelay<c?0:a.offlineThrottleDelay-
c};a.Fa=function(){if(0<a.i.length)return a.i.shift()};a.Eb=function(c){if(a.debugTracking){var b="AppMeasurement Debug: "+c;c=c.split("&");var d;for(d=0;d<c.length;d++)b+="\n\t"+a.unescape(c[d]);a.P(b)}};a.fb=function(){return a.marketingCloudVisitorID||a.analyticsVisitorID};a.Y=!1;var q;try{q=JSON.parse('{"x":"y"}')}catch(u){q=null}q&&"y"==q.x?(a.Y=!0,a.X=function(a){return JSON.parse(a)}):h.$&&h.$.parseJSON?(a.X=function(a){return h.$.parseJSON(a)},a.Y=!0):a.X=function(){return null};a.Ib=function(c){var b,
d,f;a.fb()&&2047<c.length&&("undefined"!=typeof XMLHttpRequest&&(b=new XMLHttpRequest,"withCredentials"in b?d=1:b=0),b||"undefined"==typeof XDomainRequest||(b=new XDomainRequest,d=2),b&&(a.AudienceManagement&&a.AudienceManagement.isReady()||0!=a.usePostbacks)&&(a.Y?b.Ba=!0:b=0));!b&&a.Sa&&(c=c.substring(0,2047));!b&&a.d.createElement&&(0!=a.usePostbacks||a.AudienceManagement&&a.AudienceManagement.isReady())&&(b=a.d.createElement("SCRIPT"))&&"async"in b&&((f=(f=a.d.getElementsByTagName("HEAD"))&&f[0]?
f[0]:a.d.body)?(b.type="text/javascript",b.setAttribute("async","async"),d=3):b=0);b||(b=new Image,b.alt="",b.abort||"undefined"===typeof h.InstallTrigger||(b.abort=function(){b.src=n}));b.Da=function(){try{b.F&&(clearTimeout(b.F),b.F=0)}catch(a){}};b.onload=b.va=function(){a.ab(c);b.Da();a.rb();a.ga();a.q=0;a.ka();if(b.Ba){b.Ba=!1;try{a.doPostbacks(a.X(b.responseText))}catch(d){}}};b.onabort=b.onerror=b.Ga=function(){b.Da();(a.trackOffline||a.qa)&&a.q&&a.i.unshift(a.qb);a.q=0;a.ma>a.N&&a.Pa(a.i);
a.ga();a.ua(500)};b.onreadystatechange=function(){4==b.readyState&&(200==b.status?b.va():b.Ga())};a.Oa=a.C();if(1==d||2==d){var e=c.indexOf("?");f=c.substring(0,e);e=c.substring(e+1);e=e.replace(/&callback=[a-zA-Z0-9_.\[\]]+/,"");1==d?(b.open("POST",f,!0),b.send(e)):2==d&&(b.open("POST",f),b.send(e))}else if(b.src=c,3==d){if(a.Ma)try{f.removeChild(a.Ma)}catch(g){}f.firstChild?f.insertBefore(b,f.firstChild):f.appendChild(b);a.Ma=a.B}b.F=setTimeout(function(){b.F&&(b.complete?b.va():(a.trackOffline&&
b.abort&&b.abort(),b.Ga()))},5E3);a.qb=c;a.B=h["s_i_"+a.replace(a.account,",","_")]=b;if(a.useForcedLinkTracking&&a.J||a.A)a.forcedLinkTrackingTimeout||(a.forcedLinkTrackingTimeout=250),a.ha=setTimeout(a.ga,a.forcedLinkTrackingTimeout)};a.rb=function(){if(a.ra()&&!(a.Na>a.N))try{h.localStorage.removeItem(a.pa()),a.Na=a.C()}catch(c){}};a.Pa=function(c){if(a.ra()){a.Ra();try{h.localStorage.setItem(a.pa(),h.JSON.stringify(c)),a.N=a.C()}catch(b){}}};a.Ra=function(){if(a.trackOffline){if(!a.offlineLimit||
0>=a.offlineLimit)a.offlineLimit=10;for(;a.i.length>a.offlineLimit;)a.Fa()}};a.forceOffline=function(){a.qa=!0};a.forceOnline=function(){a.qa=!1};a.pa=function(){return a.offlineFilename+"-"+a.visitorNamespace+a.account};a.C=function(){return(new Date).getTime()};a.Ka=function(a){a=a.toLowerCase();return 0!=a.indexOf("#")&&0!=a.indexOf("about:")&&0!=a.indexOf("opera:")&&0!=a.indexOf("javascript:")?!0:!1};a.setTagContainer=function(c){var b,d,f;a.Jb=c;for(b=0;b<a._il.length;b++)if((d=a._il[b])&&"s_l"==
d._c&&d.tagContainerName==c){a.R(d);if(d.lmq)for(b=0;b<d.lmq.length;b++)f=d.lmq[b],a.loadModule(f.n);if(d.ml)for(f in d.ml)if(a[f])for(b in c=a[f],f=d.ml[f],f)!Object.prototype[b]&&("function"!=typeof f[b]||0>(""+f[b]).indexOf("s_c_il"))&&(c[b]=f[b]);if(d.mmq)for(b=0;b<d.mmq.length;b++)f=d.mmq[b],a[f.m]&&(c=a[f.m],c[f.f]&&"function"==typeof c[f.f]&&(f.a?c[f.f].apply(c,f.a):c[f.f].apply(c)));if(d.tq)for(b=0;b<d.tq.length;b++)a.track(d.tq[b]);d.s=a;break}};a.Util={urlEncode:a.escape,urlDecode:a.unescape,
cookieRead:a.cookieRead,cookieWrite:a.cookieWrite,getQueryParam:function(c,b,d){var f;b||(b=a.pageURL?a.pageURL:h.location);d||(d="&");return c&&b&&(b=""+b,f=b.indexOf("?"),0<=f&&(b=d+b.substring(f+1)+d,f=b.indexOf(d+c+"="),0<=f&&(b=b.substring(f+d.length+c.length+1),f=b.indexOf(d),0<=f&&(b=b.substring(0,f)),0<b.length)))?a.unescape(b):""}};a.G="supplementalDataID timestamp dynamicVariablePrefix visitorID marketingCloudVisitorID analyticsVisitorID audienceManagerLocationHint authState fid vmk visitorMigrationKey visitorMigrationServer visitorMigrationServerSecure charSet visitorNamespace cookieDomainPeriods fpCookieDomainPeriods cookieLifetime pageName pageURL referrer contextData currencyCode lightProfileID lightStoreForSeconds lightIncrementBy retrieveLightProfiles deleteLightProfiles retrieveLightData".split(" ");
a.g=a.G.concat("purchaseID variableProvider channel server pageType transactionID campaign state zip events events2 products audienceManagerBlob tnt".split(" "));a.na="timestamp charSet visitorNamespace cookieDomainPeriods cookieLifetime contextData lightProfileID lightStoreForSeconds lightIncrementBy".split(" ");a.O=a.na.slice(0);a.Aa="account allAccounts debugTracking visitor visitorOptedOut trackOffline offlineLimit offlineThrottleDelay offlineFilename usePlugins doPlugins configURL visitorSampling visitorSamplingGroup linkObject clickObject linkURL linkName linkType trackDownloadLinks trackExternalLinks trackClickMap trackInlineStats linkLeaveQueryString linkTrackVars linkTrackEvents linkDownloadFileTypes linkExternalFilters linkInternalFilters useForcedLinkTracking forcedLinkTrackingTimeout trackingServer trackingServerSecure ssl abort mobile dc lightTrackVars maxDelay expectSupplementalData usePostbacks registerPreTrackCallback registerPostTrackCallback AudienceManagement".split(" ");
for(l=0;250>=l;l++)76>l&&(a.g.push("prop"+l),a.O.push("prop"+l)),a.g.push("eVar"+l),a.O.push("eVar"+l),6>l&&a.g.push("hier"+l),4>l&&a.g.push("list"+l);l="pe pev1 pev2 pev3 latitude longitude resolution colorDepth javascriptVersion javaEnabled cookiesEnabled browserWidth browserHeight connectionType homepage pageURLRest".split(" ");a.g=a.g.concat(l);a.G=a.G.concat(l);a.ssl=0<=h.location.protocol.toLowerCase().indexOf("https");a.charSet="UTF-8";a.contextData={};a.offlineThrottleDelay=0;a.offlineFilename=
"AppMeasurement.offline";a.Oa=0;a.ma=0;a.N=0;a.Na=0;a.linkDownloadFileTypes="exe,zip,wav,mp3,mov,mpg,avi,wmv,pdf,doc,docx,xls,xlsx,ppt,pptx";a.w=h;a.d=h.document;try{if(a.Sa=!1,navigator){var v=navigator.userAgent;if("Microsoft Internet Explorer"==navigator.appName||0<=v.indexOf("MSIE ")||0<=v.indexOf("Trident/")&&0<=v.indexOf("Windows NT 6"))a.Sa=!0}}catch(w){}a.ga=function(){a.ha&&(h.clearTimeout(a.ha),a.ha=n);a.l&&a.J&&a.l.dispatchEvent(a.J);a.A&&("function"==typeof a.A?a.A():a.l&&a.l.href&&(a.d.location=
a.l.href));a.l=a.J=a.A=0};a.Qa=function(){a.b=a.d.body;a.b?(a.v=function(c){var b,d,f,e,g;if(!(a.d&&a.d.getElementById("cppXYctnr")||c&&c["s_fe_"+a._in])){if(a.Ca)if(a.useForcedLinkTracking)a.b.removeEventListener("click",a.v,!1);else{a.b.removeEventListener("click",a.v,!0);a.Ca=a.useForcedLinkTracking=0;return}else a.useForcedLinkTracking=0;a.clickObject=c.srcElement?c.srcElement:c.target;try{if(!a.clickObject||a.M&&a.M==a.clickObject||!(a.clickObject.tagName||a.clickObject.parentElement||a.clickObject.parentNode))a.clickObject=
0;else{var k=a.M=a.clickObject;a.la&&(clearTimeout(a.la),a.la=0);a.la=setTimeout(function(){a.M==k&&(a.M=0)},1E4);f=a.Ia();a.track();if(f<a.Ia()&&a.useForcedLinkTracking&&c.target){for(e=c.target;e&&e!=a.b&&"A"!=e.tagName.toUpperCase()&&"AREA"!=e.tagName.toUpperCase();)e=e.parentNode;if(e&&(g=e.href,a.Ka(g)||(g=0),d=e.target,c.target.dispatchEvent&&g&&(!d||"_self"==d||"_top"==d||"_parent"==d||h.name&&d==h.name))){try{b=a.d.createEvent("MouseEvents")}catch(l){b=new h.MouseEvent}if(b){try{b.initMouseEvent("click",
c.bubbles,c.cancelable,c.view,c.detail,c.screenX,c.screenY,c.clientX,c.clientY,c.ctrlKey,c.altKey,c.shiftKey,c.metaKey,c.button,c.relatedTarget)}catch(n){b=0}b&&(b["s_fe_"+a._in]=b.s_fe=1,c.stopPropagation(),c.stopImmediatePropagation&&c.stopImmediatePropagation(),c.preventDefault(),a.l=c.target,a.J=b)}}}}}catch(p){a.clickObject=0}}},a.b&&a.b.attachEvent?a.b.attachEvent("onclick",a.v):a.b&&a.b.addEventListener&&(navigator&&(0<=navigator.userAgent.indexOf("WebKit")&&a.d.createEvent||0<=navigator.userAgent.indexOf("Firefox/2")&&
h.MouseEvent)&&(a.Ca=1,a.useForcedLinkTracking=1,a.b.addEventListener("click",a.v,!0)),a.b.addEventListener("click",a.v,!1))):setTimeout(a.Qa,30)};a.Qa();a.loadModule("ActivityMap")}
/*
function s_gi(a){var h,n=window.s_c_il,p,l,r=a.split(","),s,q,u=0;if(n)for(p=0;!u&&p<n.length;){h=n[p];if("s_c"==h._c&&(h.account||h.oun))if(h.account&&h.account==a)u=1;else for(l=h.account?h.account:h.oun,l=h.allAccounts?h.allAccounts:l.split(","),s=0;s<r.length;s++)for(q=0;q<l.length;q++)r[s]==l[q]&&(u=1);p++}u||(h=new AppMeasurement);h.setAccount?h.setAccount(a):h.sa&&h.sa(a);return h}AppMeasurement.getInstance=s_gi;window.s_objectID||(window.s_objectID=0);
function s_pgicq(){var a=window,h=a.s_giq,n,p,l;if(h)for(n=0;n<h.length;n++)p=h[n],l=s_gi(p.oun),l.setAccount(p.un),l.setTagContainer(p.tagContainerName);a.s_giq=0}s_pgicq();
*/
/**** End SiteCatalyst ******/
