/**
 * @returns {string}
 */
function getCookieDomain() {
    // use pre-parsed location, remove anything above 2 TLDs
    return window.location.hostname.replace(/^.*(\.[\w-]+\.[\w-]+)$/, '$1');
}

/**
 * Stupid-simple Cookie extraction.
 *
 * Please note that createCookie() doesn't encodeURIComponent() the name or value.
 * However, readCookie() does because it's used in places where cookie values are
 * set via Apache.
 *
 * @param {string} name
 * @returns {string|undefined}
 * @see http://stackoverflow.com/questions/10730362/get-cookie-by-name
 */
function readCookie(name) {
    // Prepend cookie value with token to ensure split() works on first value
    var parts = ("; " + document.cookie).split("; " + name + "=");
    if (parts.length >= 2) {
        return decodeURIComponent(parts[1].split(";").shift());
    }
}

/**
 * Create a new cookie.
 *
 * @param {string} name
 * @param {string} value
 * @param {string} domain
 */
function createCookie(name, value, domain) {
    // A cookie is name=value;expires=XX;path=/;domain=.domain.com
    if (domain) {
        domain = "domain=" + domain + ";";
    }
    document.cookie = name + "=" + value + ";path=/;" + domain;
}

/**
 * Delete a cookie.
 *
 * @param {string} name
 * @param {string} domain Optional; Will use getCookieDomain() if missing
 */
function deleteCookie(name, domain) {
    document.cookie = name + "=;expires=" + new Date(Date.now() - 60000).toUTCString() + ";" + (domain ? "domain=" + domain : '');
}
