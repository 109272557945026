/* global $, readCookie, createCookie, getCookieDomain, require, arrStateNames, loadApro, staticCall, Errsnag */
(function(window, document) {
    "use strict";
    var location = document.location || window.location,
        onReady = window.jQuery || window.$,
        windowWidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth,
        rootUrl = window.rootUrl, // always generated in global_vars.js
        setTimeout = window.setTimeout,
        // some code snippets use document, some window, here we hide that difference by
        // bringing the variable local (which saves space when this function is minified).
        protocol = (location.protocol || ''),
        log = window.console.log
    ;

    if (log.bind) {
        log = log.bind(window.console);
        /*IE>8*/
    }

    function loadJs(src, prepend, sync) {
        var e = document.createElement('script'),
            body = document.body || document.getElementsByTagName('body')[0];
        if (!body) {
            body = document.documentElement || document.head;
            prepend = 0;
        }
        e.type = 'text/javascript';
        e.async = !sync;
        e.src = src;
        if (prepend) {
            body.insertBefore(e, body.firstChild);
        } else {
            body.appendChild(e);
        }
        return e;
    }

    // @see https://github.com/filamentgroup/loadCSS/blob/master/loadCSS.js
    function loadCSS(href, media) {
        var ss = document.createElement("link"),
            ref = document.head || document.getElementsByTagName('head')[0];
        ss.rel = "stylesheet";
        ss.href = href;
        // temporarily, set media to something non-matching to ensure it'll fetch without blocking render
        ss.media = "only x";

        function isLoaded() {
            ss.media = media || 'all';
        }

        if ('onload' in ss) {
            ss.onload = isLoaded;
        } else {
            // This function sets the link's media back to `all` so that the stylesheet applies once it loads
            // It is designed to poll until document.styleSheets includes the new sheet.
            var onready = function() {
                var sheets = document.styleSheets,
                    i = sheets.length -1;
                for (; i >= 0; i--) {
                    if (sheets[i].href === ss.href) {
                        isLoaded();
                        return;
                    }
                }
                setTimeout(onready, 50);
            };
            setTimeout(onready);
        }

        if (ref) {
            ref.appendChild(ss);
        } else {
            // Note: `insertBefore` is used instead of `appendChild`, for safety re: http://www.paulirish.com/2011/surefire-dom-element-insertion/
            ref = document.getElementsByTagName('script')[0];
            ref.parentNode.insertAfter(ss, ref);
        }
    }

    function checkIfFontIsInstalled(font) {
        // a font will be compared against all the three default fonts.
        // and if it doesn't match all 3 then that font is not available.
        var baseFonts = ['monospace', 'sans-serif', 'serif'],
            h = document.body || document.getElementsByTagName("body")[0],
            // create a SPAN in the document to get the width of the text we use to test
            el = document.createElement("div"),
            s = el.style,
            defaultWidths = [],
            defaultHeights = [],
            i = 0, cur;

        // use m or w because these two characters take up the maximum width
        // use a LLi so that the same matching fonts can get separated
        el.innerHTML = "mmmmmmmmmmlli";
        // using 72px font size, larger the better so discrepancies are more obvious
        s.fontSize = '72px';
        s.position = 'absolute';
        s.opacity = 0;

        // get the default width for the three base fonts
        for (; i<baseFonts.length; i++) {
            cur = getDimensions(baseFonts[i]);
            defaultWidths[i] = cur[0];
            defaultHeights[i] = cur[1];
        }

        function getDimensions(font) {
            s.fontFamily = font;
            h.appendChild(el);
            var matched = [el.offsetWidth, el.offsetHeight];
            h.removeChild(el);
            return matched;
        }
        function detect(font) {
            for (var i=0, matched; i<baseFonts.length; i++) {
                // name of the font along with the base font for fallback
                matched = getDimensions("'" + font + "'," + baseFonts[i]);
                if (matched[0] !== defaultWidths[i] || matched[1] !== defaultHeights[i]) return true;
            }
        }

        // Re-define and run the first time
        return (checkIfFontIsInstalled = detect)(font);
    }

    //// PHASE ONE: Run immediately-relevant logic

    // FONT mainly used in the homepage and footer
    //loadCSS('https://fonts.googleapis.com/css?family=Oswald:300,400');
    // Main body font (DISABLED: produces very noticeable flash)
    //if (!checkIfFontIsInstalled('Roboto')) {
    //    loadCSS('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
    //}

    // Update the lastsel_state cookie before additonal requests are made
    function makeStateCookie() {
        //JD: quick-fix for times when lastsel_state cookie becomes out of sync.
        //    The cookie will only be current (aka synced with the current page)
        //    when the browser makes a full request to the server and does NOT
        //    re-use cached data.  Because the PHP code is driving this cookie
        //    change and not JavaScript, we might need to update the value here.
        var stateId = parseInt( (document.body || document.getElementsByTagName('body')[0]).getAttribute('data-stateid') );

        if (isNaN(stateId)) {
            stateId = parseInt( readCookie('lastsel_state') );
        }
        if (isNaN(stateId) || location.pathname === '/') {
            // if on homepage or if we didn't get a good ID, force stateid to 0
            stateId = 0;
        }
        // getCookieDomain() works but is broken sometimes. please fix it!
        createCookie('lastsel_state', stateId, getCookieDomain());

        if (/^local\./.test(location.host)) {
            createCookie('last_localurl', location.pathname, getCookieDomain());
        }
    }
    if (document.body) {
        // Executing within the body?
        makeStateCookie();
    } else {
        // Still within the head, wait for DOMComplete
        onReady(makeStateCookie);
    }


    //// Load Top Priority Scripts

    //// BEGIN Krux Control Tag
    // Source: /controltag?confid=qxe5akfd5&site=Drivers-license.DMV.org&edit=1
    // Source: /controltag?confid=qxg98ti31&site=Local.DMV.org&edit=1
    // Source: /controltag?confid=qxe2yllfn&site=DMV.org&edit=1
    var Krux = window.Krux,
        code = {
            'drivers-license': 'qxe5akfd5',
            local: 'qxg98ti31',
            www: 'qxe2yllfn'
        },
        m, src = (m = location.href.match(/\bkxsrc=([^&]+)/)) && decodeURIComponent(m[1]);
    m = /^[\w-]+/.exec(location.hostname);
    if (!Krux) {
        Krux = window.Krux = function(){ Krux.q.push(arguments); };
        Krux.q=[];
    }
    //TODO: this enables completely overwriting the control tag source!
    loadJs(/^https?:\/\/([\w.-]+\.)?krxd\.net(:\d{,5})?\/controltag\//i.test(src)
        ? src
        : (src === "disable"
        ? ""
        : location.protocol+"//cdn.krxd.net/controltag?confid="+((m && code[m[0]]) || code.www)
    ), 1);
    var kruxHasLocalStorage = (function(){
        try {
            return window.localStorage;
        } catch(e) {}
    })();
    function kruxRetrieve(n) {
        var m;
        n = 'kxdmv_' + n;
        if (kruxHasLocalStorage) {
            m = kruxHasLocalStorage[n] || "";
        } else if (navigator.cookieEnabled) {
            m = (m = document.cookie.match(n+'=([^;]*)')) && decodeURIComponent(m[1]);
        }
        return m || '';
    }

    // Used in global.js:dfp()
    Krux.user = kruxRetrieve('user');
    Krux.segments = (m = kruxRetrieve('segs')) ? m.split(',') : [];

    Krux('ns:dmv','page:load', function(err) {
        /* Optional, called just after the tags are finished loading. 
              If err is not null, then something went wrong. (err will be an instanceof Error or null.)
           */
        if (err) {
            window.console.error(err);
        }
    }, {pageView: true /* Set to false if you don't want this counted as a page view. */});

    // https://konsole.zendesk.com/hc/en-us/articles/360000754674-JavaScript-Consent-Tag-Spec
    if (window._G) {
        var kflag = window._G.isEU ? 0 : 1,
            kparams = {
                dc: kflag,
                tg: kflag,
                al: kflag,
                cd: kflag,
                sh: kflag,
                re: 0 // this is always zero
            };
        // BEGIN Salesforce DMP JavaScript Consent Tag for "DMV.org"
        Krux('ns:dmv', 'consent:set', kparams, function(errors, body) {
            if (errors) {
                window.console.error(errors);
            }
        });
        // END Salesforce DMP JavaScript Consent Tag
    }

    // Google Analytics
    window.GoogleAnalyticsObject = 'ga';
    var ga = window.ga;
    if (!ga) {
        // Object definition abstracted from GA's provided tracking code
        ga = window.ga = function () {
            window.ga.q.push(arguments);
        };
        ga.l = 1 * new Date();
        ga.q = [];
    }
    ga('create', 'UA-9998628-1', 'auto');
    // Enhanced Link Attribution plugin (don't know how it works, so testing it out on one domain first)
    if (/^local\./.test(location.hostname)) {
        ga('require', 'linkid', 'linkid.js');
    }

    // begin GA traffic segments (in priority order hence the else ifs)
    // Paid Search Detection
    var medium;
    if (s.eVar31 && s.eVar30 == 'SEM') { // src && mktgtype == 'SEM'
        medium = 'ppc';
    }
    // Paid Social Detection
    else if (s.eVar31 && s.eVar30 == 'social') { // src && mktgtype == 'social'
        medium = 'paid social';
    }
    // Email
    else if (s.eVar30 == 'Email') { // mktgtype == 'Email'
        medium = 'email';
    }
    // Affiliate
    else if (/^(bmv\.com|enom_domain_redirects|go_daddy_domain_redirects|IDriveSafely)$/.test(s.eVar31)) { // src
        medium = 'affiliate';
    }
    // Visitor Support
    else if (('' + s.campaign).indexOf('vs-') === 0) { // s.campaign set when 'cid' cookie is found
        medium = 'visitor support';
    }
    // Display
    else if (s.eVar30 == 'Display') { // mktgtype == 'Display'
        medium = 'display';
    }

    if (medium) {
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/custom-dims-mets
        ga('set', {
            campaignSource: s.eVar31,
            campaignName: s.eVar40,
            campaignMedium: medium
        });
    }
    // end GA traffic segments

    // now passing custom dimensions for SuperSection, Section, and Topic to GA pageview
    ga('send', 'pageview', {
        dimension1: window.pagesupersection,
        dimension2: window.pagesection,
        dimension3: window.pagetopic
    }); // note: had to use window object because variables are not declared when in /articles
    // we still have IE7 visitors, so using location.protocol match
    loadJs(protocol + '//www.google-analytics.com/analytics.js', 1);

    //loadCSS(protocol + '//www.' + /(\w+\.\w+)$/.exec(location.hostname)[1] + '/res/dmv_org_print.css', 'print');

    // Facebook Social plugins (required for login pages, on other pages only required for Event.subscribe() tracking of Like events)
    window.fbAsyncInit = function () {
        FB.Event.subscribe('edge.create', function () {
            // Like button was clicked (action might be Like or Unlike)
            ac(null, 'misc_FBLike');
        });
        // Will be set on member-registration or login widget pages
        $(document).trigger('fbloaded');
    };


    //// PHASE TWO: Run required functionality on DOM-ready

// Desktop initialization
onReady(function () {

    // Add top search box to the footer on small screens
    if (windowWidth < 760) {
        return;
    }

    function menuHide() {
        $overlay.clearQueue().fadeOut(100);
    }

    var $overlay = $('<div id="megaScreen"/>').insertBefore('#mega').click(function() {
            menuHide();
            $('#mega>li').trigger('mouseout');
        }),
        clickDelay = 1;
    $overlay.parent().css('position', 'relative');

    $('#mega>li')
        // skip the Home link
        .slice(1)
        // set up mega menu effects
        .hover(
            function () {
                // Force the hover event to happen
                if (clickDelay) {
                    clearTimeout(clickDelay);
                }
                clickDelay = setTimeout(function () {
                    clickDelay = 0;
                }, 'ontouchstart' in document.documentElement // is touch device?
                    ? 400 // must be higher than "click" threshold, iOS=~300ms
                    : 0   // will protect against direct clicks when activating window
                );
                $('>div', this)
                    .stop(true)//.hide()
                    .delay(500).fadeIn(300);
                $overlay.clearQueue().delay(500).fadeIn(500);
            },
            function (e) {
                if (e.target && e.target.nodeName === 'SELECT') {
                    // IE/Firefox will trigger a mouseleave event when mouse enters
                    // a SELECT element even though the element is inside a tab (WHY??).
                    return;
                }
                clickDelay = 1;
                $('>div', this)
                    .stop(true).hide();
                // Close the menu, on-hover will
                $overlay.clearQueue().delay(5).queue(menuHide);
            }
        )
        .click(function() {
            if (clickDelay) { // use single marker to mark when mouse has entered element
                $('>div', this).stop(true).show();
                return false;
            }
        });

    // jQuery HTML5 placeholder fix
    placeholderFix();
});

// Perform load-time initialization, lots of stuff
onReady(function() {

    addStateToLinks();

    // state dropdown in header
    //function build_state_dropdown(el, searchInput) {
    $(".selectstatestd").each(function () {
        // Attempt to find a text-input element
        // If found, it must be non-empty for the select-change event to navigate the user
        var el = this,
            searchInput = el.parentNode,
            isSelect = el.nodeName === 'SELECT',
            arrStateName = window.arrStateName,
            stateId = parseInt(readCookie("lastsel_state")) || 0,
            selOpt, i, jEl, k, l, $parent;

        if (searchInput && (searchInput = searchInput.parentNode)) {
            searchInput = $('input[type="text"]', searchInput)[0];
        }

        if (isSelect) {
            $parent = $(el.parentNode);
            jEl = $(el);
        } else {
            $parent = $(el);
            // remove any existing select boxes
            $('>.stateselect', el).remove();
            jEl = $(document.createElement('select'))
                .attr('name', el.getAttribute('data-name') || 'state');
        }

        if (!(selOpt = jEl.children()[0])) {
            selOpt = $(document.createElement('option')).text('Choose Your State').val('');
            jEl.append(selOpt);
        }

        for (i=0; i<arrStateName.length; i++) {
            k = arrStateAbbr[i];
            jEl.append(l = $(document.createElement('option'))
                .val(k)
                .attr({'data-abbr':k,'data-stateid':i+1})
                .text(arrStateName[i]));
            if (i+1 === stateId) {
                selOpt = l;
                jEl.val(k);
            }
        }

        jEl.change(function() {
            /**
             * Send user to same page in another state, ONLY IF the user has NOT entered in a search term yet.
             *
             * (ss=StateSpecific)
             *
             * WWW or Drivers-License:
             *   /a.php (ss=1) -> (stateid=0) /a.php (set cookie=0)
             *   /a.php (ss=1) -> (stateid=1) /al-alabama/a.php
             *   /al-alabama/a.php (ss=1) -> (stateid=0) /a.php (set cookie=0)
             *   /al-alabama/a.php (ss=1) -> (stateid=2) /ak-alaska/a.php (set cookie=2)
             *   /b.php (ss=0) -> (stateid=0) /b.php
             *   /b.php (ss=0) -> (stateid=1) /b.php (set cookie=1)
             * Edge case:
             *   / (ss=0) -> (stateid=1) /al-alabama/ (set cookie=1)   (the homepage is non-statespecific, should be merged with StateHome pages)
             *
             * Local:
             *   - set cookie only
             *
             * Search:
             *   - set cookie only
             */

            var selOpt = this.childNodes[this.selectedIndex],
                stateid = parseInt($(selOpt).attr('data-stateid'), 10), // cast to integer
                navid = parseInt(window.navid, 10),
                // pgType (string) 1=stateSpecific, 0=non-stateSpecific
                stayOnCurrentURL = !navid || (searchInput && searchInput.value),
                // ONE EXCEPTION: homepage and state-homepages are different navIDs
                isStateSpecific = !!window.pgType || (navid === 239);

            if (location.host.indexOf('local.') === 0) {
                var path = location.pathname.split('/'),
                    trigger = path[path.length-1],
                    statePath = '';
                // handle when user selects "Choose Your State" (resets select box)
                if (typeof window.arrLocalStateKeys[stateid - 1] !== 'undefined') {
                    statePath = window.arrLocalStateKeys[stateid - 1] + '/';
                } else {
                    createCookie('lastsel_state', 0, getCookieDomain());
                }
                location.href = '/' + statePath + (trigger.indexOf('.php') !== -1 ? trigger : '');
                return;
            }

            if (stayOnCurrentURL || isStateSpecific !== !!stateid) { // if both are true or false
                // set to destination state (will be 0 if going to non-state)
                createCookie('lastsel_state', stateid || 0, getCookieDomain());
            }

            $(this).prev('.selTxt').text($(selOpt).text());

            // Stop if the user has entered a search term
            if (stayOnCurrentURL) {
                return;
            }

            // when we merge these NavIDs, please remove this exception!
            if (navid === 239 || navid === 240) {
                navid = stateid ? 240/* State Home */ : 239/* Home */;
            }

            location.href = rootUrl + 'zip2state.php?navid=' + navid + '&state=' + stateid;
        });

        // Return to document
        $parent.append(jEl);

        if ($parent.hasClass('sel')) {
            $parent.prepend('<span class="selTxt">' + selOpt.text() + '</span>');
        }
    });

    // Track Site Search submits
    $('form.slisearch').submit(function(e) {
        // If action="" has a '?', this will need to be updated
        var form = this,
            url = form.action + '?' + $(form).serialize(),
            qEl = form.q;
        function end() {
            if (url) {
                location.href = url;
                url = 0;
            }
        }
        if (!qEl || $.trim(qEl.value)) {
            e.preventDefault();
            var $el = $('option:selected', form),
                code = $el.attr('data-stateid');
            if (code) {
                // Update cookie value to new selected state
                createCookie('lastsel_state', code, getCookieDomain());
            }
            setTimeout(end, 200);
            // add tracking within a specific timeout
            fc(form, 'misc_search_submit', {
                eVar3: qEl && qEl.value || '',
                eVar22: $el.val() ? $el.text() : 'NO-STATE' // to stay consistent
            }, end);
        }
    });

    // add misc tracking to the tip box links
    $('.tipsBox a').click(function () {
        ac(this, 'misc_TipTitle_' + (window.section || 'N').replace(/[^\d\w]+/g, '') + '_' + (window.statecode || 'NS'));
    });

    // Content Element from redactor-contenteditor.js
    $('.nav-tabs a').each(function() {
        if ($(this).parent().is(':first-child')) {
            $(this).parent().addClass('active');
        } else {
            $(this.getAttribute('href')).hide();
        }
    }).click(function(e) {
        e.preventDefault();
        var $tabs = $(this).closest('.nav-tabs');
        $('.active', $tabs).removeClass('active');
        $(this).parent().addClass('active');
        $('a', $tabs).each(function() {
            $(this.getAttribute('href')).hide();
        });
        $(this.getAttribute('href')).fadeIn();
    });

    // Set target=_blank for external links
    try { // if RegExp fails to compile, it shouldn't kill the page!
        // Domain must be a suffix like ".dmv.org"; use 'i' flag
        var cookieRegex = new RegExp(location.hostname.replace(/^.*\.([\w-]+)\.([\w-]+)$/, '$1\\.$2\$'), 'i');
        $('a[href^=http]').each(function () {
            // If has a domain and is external
            if (this.hostname && !cookieRegex.test(this.hostname)) {
                this.target = "_blank";
            }
        });
    } catch (e) {
        log('regex:' + e);
    }


    if (windowWidth < 600) {
        // Setup Wikipedia-style H2 nav
        $('.col-middle h2:not(.nocollapse),.collapsible')//TODO: remove ".col-middle" reference
            .not('.nocollapse h2')// don't affect H2's that are within a .topicslist listing page
            .filter(function () {
                var $el = $(this).nextUntil('.nocollapse,h2');
                // overflow: hide <p> margins so there is no animation jitter
                // Note: also used by dfp.mobile()
                // Only run if found at least one element
                return $el[0] && $el.wrapAll('<div class="collapseWrap"/>');
            })
            .addClass('collapsed') // Note: the .collapsed class is used in dfp.mobile() to select and add DFP ads on-click
            .click(function () {
                var $this = $(this).toggleClass('opened'),
                    isOpen = $this.hasClass('opened');
                $this
                    .next('.collapseWrap')
                    [isOpen ? 'show' : 'hide']()
                    .trigger(isOpen ? 'shown' : 'hidden');
            });

        //make the whole disclaimer clickable on mobile
        // Disabled Sept-2019 $('#disclaimer').click(showDisclaimerModal);
    }

    /* Disabled Sept-2019
    // Add a message under the Disclaimer
    // This is NOT within the source just in case a scraper decides to use the first sentence from the HTML source
    $('#disclaimer-link').click(showDisclaimerModal);

    function showDisclaimerModal(){
        ac(this, 'misc_disclaimer');
        $('<div class="modal fade" id="disclaimer-modal"><div class="modal-dialog"><div class="modal-content">' +
            '<div class="modal-header"><span class="siteLogoDark"></span><a onclick="ac(this,\'misc_IntCancel\')" href="javascript:" class="modal-close" data-dismiss="modal"></a></div>' +
            '<div class="modal-body">' +
            '<p>An independent company since 1999, DMV.ORG continues to have one clear mission: <b>to simplify the DMV experience</b> for drivers across the country, adding that human touch to help you better understand and complete your tasks. <b>Simply put, we’re here to help.</b></p>' +
            '<p class="a">DMV.ORG is a <b>valuable supplement to your state DMV agency</b>, providing you with resources you need before you even get there.</p>' +
            '<p class="b">Our site is <b>100% secure</b>, and we <b>don’t accept payment of any type</b> on our pages— your information is always protected.</p>' +
            '<p class="c">We <b>partner with thoroughly vetted companies</b>, including licensed and state DMV-approved organizations, to offer you additional ways to expedite your driver- or vehicle-related tasks.</p>' +
            '<p>By preparing you for an interaction with the DMV, we\'re reducing visits, shortening lines, and providing a more rewarding experience for everyone.</p>' +
            '<div class="disclaimer-close"><a onclick="ac(this,\'misc_IntCancel\')" href="javascript:" class="bluCTA" data-dismiss="modal">Close</a></div>' +
            '</div>' +
            '<div class="modal-footer">' +
            '<h2>So, how can we help you?</h2>' +
            '<p>Learn more about <a href="/our-company/">who we are</a> and <a href="/why-we-exist.php">why we do what we do.</a></p>' +
            '</div>' +
            '</div></div></div>').appendTo(document.body || 'body').modal();
    }*/
});

// listen to external links, show interstitial page on exit
$(document).on('click.interstitial', "a.intPage", function (e) {
    var href = $(this).attr('href'),
        $markup = $('<div class="modal fade modal-intlink-survey"><div class="modal-dialog"><div class="modal-content modal-intlink">'
        + '<a onclick="ac(this,\'misc_IntCancel\')" href="javascript:" class="modal-close" data-dismiss="modal"></a>'
        + '<span class="siteLogo"></span>'
        + '<h3>You are now exiting DMV.ORG</h3>'
        + '<p>Please click below to continue: <a target="_blank" class="markup-intlink" rel="noopener"></a></p>'
        + '<p>Thank you for visiting our website. We hope that you found the information you were looking for from DMV.ORG.</p>'
        + '</div>'
        // + '<div class="pop_feedback"><p>Tell us about your experience and help us improve. Take a quick survey (opens in a new window).</p>'
        // + '<a class="white-ghostCTA" href="' + rootUrl + 'survey/" onclick="ac(this, \'misc_IntSurvey\')" target="_blank">Take Survey</a>'
        // + '</div>'
        + '</div></div>');
    $('a.markup-intlink', $markup)
        .attr('href', href)
        .html(href)
        .click(function() {
            ac(this, 'misc_IntExit');
            $markup.modal('hide');
        });
    $markup
        .appendTo('body')
        .modal()
        .on('hidden.bs.modal', function() {
            $(this).remove();
        });

    // Track feature impression
    ic("misc_IntExit,misc_IntCancel,misc_IntRedirect,misc_IntSurvey");
    // Lastly, stop the external link from navigating
    e.preventDefault();
});

// Ad-Server fallback waterfall listener
$(document).on('waterfall', 'aside[data-placement]', function() {
    var $this = $(this);
    //$('[data-cid="' + $this.attr('data-cid') + '"]').remove();
    $.post('/ajax/adv/resume_waterfall', {
        navidpath: window.navidpath,
        statecode: window.statecode,
        transId: window.transID,
        name: $this.attr('data-placement'),
        position: $this.attr('data-position'),
        cid: $this.attr('data-cid')
        // , json: 1 << could also request a JSON object
    }, function(data) {
        var evt = $.Event('waterfall-response');
        $this.trigger(evt, data);
        if (evt.isDefaultPrevented()) {
            return;
        }
        if (data) { // can return "204 No Content"
            $this.hide();
            $(data)
                .hide()
                .insertAfter($this)
                // slowly open up the visual
                .filter('aside').slideDown();
        }
    });
});




//// PHASE THREE: Delayed page-init (after page starts rendering)
onReady(function() {

    // Loaded less-relevant assets later
    setTimeout(function() {
        var $all = $('.unveil');
        unveil($all, 400, function () {
            var $self = $(this);
            function done() {
                $self.addClass('unveiled');
            }
            $self.on('load', done);
            setTimeout(done, 900);// if still isn't loaded after long time, then show anyway
        });
        if (windowWidth > 1030) {
            // Run immediately, we'll expect the desktop browser to be able to handle it
            $all.trigger('unveil');
        }
    });

    // Load external JS assets
    setTimeout(function () {

        //// #2: Second-Tier Scripts, UI-related

        // Google Plus (replace with static HTML)
        //loadJs('https://apis.google.com/js/plusone.js');

        require('fb', true);
        //loadJs('//connect.facebook.net/en_US/sdk.js');

        // Qualaroo now w/ custom properties for targeting
        var _kiq = window._kiq = (window._kiq || []);
        _kiq.push(['set', {
            'superSection': (window.pagesupersection || '').replace('&','and'), // Qualaroo can't handle "&" in their admin UI
            'section': (window.pagesection || '').replace('&','and'),
            'topic': (window.pagetopic || '').replace('&','and'),
            'statecode': window.statecode || 'NS',
            'swimlane': s.eVar59 || '',
            'mktgType': s.eVar30 || '',
            'source': s.eVar31 || '',
            'visitorID': s.eVar44 || '',
            'halo': s.eVar67 === 'Halo' ? 'true' : '',
            'accuracystamp': 'off',
            'testVariableDesktop': s.eVar54 || '',
            'testVariableMobile': s.eVar55 || '',
            'testVariableUX': s.eVar56 || ''
        }]);
        loadJs("//s3.amazonaws.com/ki.js/65425/fe3.js");


        //// #3: Low-Priority Scripts, stats-related

        setTimeout(function() {

            // Facebook Ads, Remarketing Tag
            var _fbq = window._fbq || (window._fbq = []);
            _fbq.push(['addPixelId', '729609693827696']); // ID obtained from 3Q Digital
            _fbq.push(['track', 'PixelInitialized', {}]);

            // Facebook Ads, Remarketing Tag
            if (!_fbq.loaded) {
                loadJs('//connect.facebook.net/en_US/fbds.js');
                _fbq.loaded = true;
            }

            // Google Remarketing (http://google.com/ads/remarketingsetup)
            // Uses document.write(), so it MUST be non-async. Please fix this, Google!
            //w.google_conversion_id = 1066174917;
            //w.google_custom_params = w.google_tag_params || {};
            //w.google_remarketing_only = true;
            //loadJs("//www.googleadservices.com/pagead/conversion_async.js", 0, 1)
            // Using async impl instead: https://developers.google.com/adwords-remarketing-tag/asynchronous/
            //loadJs("//www.googleadservices.com/pagead/conversion_async.js").onload = function () {
            //    w.google_trackConversion({
            //        google_conversion_id: 1066174917, // Tracking ID obtained from David@dmv.org
            //        google_custom_params: {},
            //        google_remarketing_only: true
            //    });
            //};
            // Using image version (https://developers.google.com/adwords-remarketing-tag/image/)
            new Image().src = protocol + "//googleads.g.doubleclick.net/pagead/viewthroughconversion/1066174917/?value=0&guid=ON&script=0";

            // Audience Accelerator
            // (paused right now because unsure of privacy concerns)
            //new Image().src = protocol + "//ads.yahoo.com/pixel?id=" + (protocol === 'https:' ? "2557865" : "2557864") + "&t=2";

            // June-2020: Remove, no longer used
            //loadJs('/res/visittrack.js?20240530');

            // Quantcast tracking
            (window._qevents = window._qevents || []).push({
                qacct: "p-s_aNqFS0D2k_x",
                labels: window.quantcastLabels
            });
            loadJs((protocol === "https:" ? "https://secure" : "http://edge") + ".quantserve.com/quant.js");

            /* Unfortunately, Alexa's confirmation scripts seem to be looking for the <script> element on our pages.
             This means that we have to move this from init.js into the actual footer of every page. This is highly
             undesirable, but seems the only option right now.
             // Alexa traffic tracking
             // their script just loads in the pixel, so adding the pixel directly is just as effective.
             // the downside -- the JS adds the referrer to the tracking pixel. Using pixel alone only gives hits.
             //w._atrk_opts = { atrk_acct:"dEKRi1a8Dy00q7", domain:"dmv.org",dynamic: true};
             //loadJs("//d31qbv1cthcecs.cloudfront.net/atrk.js");
             // Usually markup is wrapped in <noscript>, which is removed here cuz we're replacing the JS above
             $(body).append('<img src="https://d5nxst8fruw4z.cloudfront.net/atrk.gif?account=dEKRi1a8Dy00q7" style="display:none" height="1" width="1" alt=""/>');
             */

            // Load Google Surveys for certain pages (blacklist template id 19)
            //if (window.tplid != 19) {
            //    loadJs("//survey.g.doubleclick.net/async_survey?site=yjfryr56msbjq");
            //}

            // Hubspot tracker
            //var h = loadJs('//js.hs-scripts.com/2192310.js');
            //h.id = 'hs-script-loader';

            // Bing Remarketing Tag: Universal Event Targeting (UET)
            require("//bat.bing.com/bat.js", function() {
                var o = { ti: "27000196", q: window.uetq };// our account ID
                o = new UET(o);
                window.uetq = o;
                o.push("pageLoad");
            });
        });
    }, 300 /* chose a random time to delay */);

    setTimeout(function() {

        $(".dynApro:not(.loaded)").each(function() {
            var $this = $(this);
            loadApro($this, $this.data('zid'), readCookie('lastsel_state'));
            $this.addClass("loaded");
        });

        /*** Sculpting *************************
         * Sculpting is now generalized to affect Mega Menu or any other
         * markup with the class .remotecontent. This reduces AJAX calls.
         */

        // Sculpt a couple classes
        $('.remotecontent').each(function () {
            // Determine if this will be a content area or a straight-up method call
            var $this = $(this),
                func = $this.attr('data-src-method'),
                args = {},
                cur, i = 0, attrs, l;
            if (!func) {
                if (!(args.name = $this.attr('data-src-name'))) {
                    // catch exit condition
                    return;
                }
                // fallback to using renderContentArea syntax
                func = "Content::area";
                args.stateid = readCookie('lastsel_state');
            }
            // Visibility trick! offsetParent will be empty if the element is hidden with CSS.
            // @see https://stackoverflow.com/questions/19669786/check-if-element-is-visible-in-dom
            if (!this.offsetParent && !$this.parents('.collapseWrap')[0]) {
                // If the element is hidden with CSS or something, then simply skip the call
                // Must not have been hidden the the mobile .collapseWrap method
                return;
            }
            // Look for any data-var- attributes
            for (attrs = this.attributes, l = attrs.length; i < l; i++) {
                cur = attrs.item(i);
                if (cur.nodeName.indexOf('data-var-') === 0) {
                    args[cur.nodeName.substr(9)] = cur.value;
                }
                if (cur.nodeName.indexOf('data-cookievar-') === 0) {
                    args[cur.nodeName.substr(15)] = readCookie(cur.value) || '';
                }
            }

            // Mega-Menu fly-outs are disabled!
            // Primary Nav should not be injected, just replaced
            //staticCall(func, args, $this.attr('data-src-name') === 'Primary Navigation Expanded' ? menuSuccess : this);
            staticCall(func, args, this);
        });

        function menuSuccess(src) {
            // This content area is used only for it's <li> content.
            // Don't put <script|style> tags in this area -- it won't make it onto the page.
            // jQuery will parse a multi-tag string into an array of elements. We need to wrap it
            // in a <div> to ensure our .find() call will work.
            var $megaMenu = $('#mega');
            $('<div>' + src + '</div>').find('>ul>li>div').each(function () {
                var cls = (''+this.parentNode.className).split(' ')[0];
                if (cls) {
                    $(">." + cls, $megaMenu).append(this);
                }
            });

            setTimeout(addStateToLinks, 50);
        }
    });

    // Add persistent navigation bar to top of screen when user scrolls past header
    setTimeout(function() {
        return;// DISABLE sticky-nav ENTIRELY!
        if (windowWidth < 760) {
            return; // persistent nav is for desktop English only
        }

        var $mega = $('#mega>li'),
            $staticMega = $('#sticky-nav'),
            $mini = $('#header .slisearch:eq(0)'),
            isFocused,
            isActive = false;

        // when admin bar exists, move the persistent bar just below it so it is usable
        if ($('#adminBtns').length) {
            $staticMega.css('top', $('#adminBtns').height() + 'px');
        }

        if ($staticMega[0] && $mega[0] && $mini[0]) {
            $staticMega.html('<ul>'
                + '<li><a href="' + $('a', $mega[0]).attr('href') + '" class="siteLogoDark"></a></li>'
                + $.map(
                    $mega.slice(1), // skip the first entry
                    function(t) {
                        t = t.children[0];
                        return '<li><a href="' + t.href + '">' + t.textContent + '</a></li>';
                    }
                ).join('')
                + '<li class="search"></li></ul>');

            $mini = $mini.clone();
            // remove the state-selector
            $('select', $mini).remove();

            // Mobile Safari (tablet): if position:fixed element is active, will scroll to top
            // Must stop element from hiding if is focused
            $('input', $mini).on('focus', function() {
                isFocused = 1;
            }).on('blur', function() {
                isFocused = 0;
            });

            // Inject into the DOM
            $mini.appendTo($staticMega[0].children[0].lastChild);

            // Bind events
            // Any function that runs on scroll should always use cached objects
            var $window = $(window).scroll(function () {
                // arbitrary number of pixels to push past the header
                var a = isFocused || $window.scrollTop() > 280;
                if (a !== isActive) { // only touch DOM if is changing
                    isActive = a;
                    $staticMega.toggleClass('on', a);
                }
            });
        }
    });

    //mobile sticky header
    setTimeout(function () {
        var $header = $('#header'),
            padding = $header.offset().top,
            $headerPad,
            open = false,
            $window = $(window).scroll(function () {
                // Save hitting a jQuery method on-scroll
                var shouldOpen = $window.scrollTop() > padding;
                if (shouldOpen !== open) {
                    open = shouldOpen;
                    if (!$headerPad) {
                        $headerPad = $('<div class="fixedMarker">').insertAfter($header);
                    }
                    if (shouldOpen) {
                        $headerPad.css('height', $header.height() + 'px');
                    }
                    $header.toggleClass('fixed', shouldOpen);
                }
            });
    });

    // Run static sculpting calls
    setTimeout(function() {
        // Separate the scultping exec() into its own closure so that if there are any script errors on the page,
        // the errors won't kill any additional functionality.
        try {
            // Run sculpting immediately
            staticCall.exec();
        } catch (e) {
            log('Sculpting:' + e);
        }
    });

    // Intercept #hashtag links and use smooth-scrolling instead of jumping
    setTimeout(function() {
        /**
         * Check an href for an anchor. If exists, and in document, scroll to it.
         * If href argument omitted, assumes context (this) is HTML Element,
         * which will be the case when invoked by jQuery after an event
         * @see http://stackoverflow.com/questions/10732690/offsetting-an-html-anchor-to-adjust-for-fixed-header
         */
        function scroll_if_anchor(href, skipPushState) {
            if (typeof href !== 'string') {
                if (href && href.isDefaultPrevented()) {
                    return;// stop!
                }
                href = $(this).attr('href');
            }

            // Must deal with non-empty string
            if (!href) {
                return;
            }

            // Could make this dynamic, but for now hard-code usual height of persistent navbar
            var fromTop = 60,

            // If href points to valid, non-empty anchor, and is on the same page (e.g. #foo)
            // Legacy jQuery and IE7 may have issues: http://stackoverflow.com/q/1593174
                $target = $(href);

            if (!$target.length) {
                // If no ID was found, then search for anchor tag
                $target = $('a[name="' + href.replace(/^#/, '') + '"]');
            }
            if ($target.length) {
                // FYI: body=Chrome, html=Firefox
                $('body,html')[
                    // Set the property once instead of animating if the screen is too small
                    $(window).width() > 768 ? 'animate' : 'prop'
                    ]({ scrollTop: $target.offset().top - fromTop });

                // SPECIAL CASE: if it's a scroll-to-top link, don't follow the href at all
                if (href === '#disclaimer') {
                    return false;
                }

                // Expand h2 section if necessary
                ($target.hasClass('collapsed') && $target || $('>.collapsed:first-child', $target)).addClass('opened');

                // Use HTML5-History API if available
                if (skipPushState !== true && window.history && "pushState" in history) {
                    history.pushState({}, document.title, location.pathname + href);
                    return false; // don't follow link
                }
                // Older browsers without pushState might flicker here, as they momentarily
                // jump to the wrong position (IE < 10) and then $.animate() takes over again
            }
        }

        // When our page loads, check to see if it contains an anchor
        // We have ads that appear at the top of the page, so the element might not align exactly correctly.
        // However, adding a timeout and waiting for the ads is not a good idea
        scroll_if_anchor(location.hash, true);

        if ("onhashchange" in window) { // does the browser support the hashchange event?
            $(window).on('hashchange', function() {
                scroll_if_anchor(location.hash);
            });
        } // this was added for local map listings

        // Intercept all anchor clicks
        $(document.body || 'body').on('click', 'a[href^="#"]', scroll_if_anchor);
    });

    //register the service
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/sw.js');
    }
});


// IAB AdBlock detector
onReady(function() {
    var hasDfp, adblockSent,
        require = window.require,
        loaded = require && require.loaded,
        map = require && require.map;

    // If the page has asked for the DFP library
    if (loaded && map && typeof loaded[map.dfp] !== 'undefined') {
        // Then listen for the loading to complete
        require('dfp', function() {
            hasDfp = !!(window.googletag && googletag._loadStarted_);
            if (!hasDfp) {
                adblockAfter(1);
            }
        });
    }
    function adblockAfter(isDFP) {
        if (adblockSent) return;
        adblockSent = 1;
        ac(null, 'misc_adblocked', {eVar8:'ad block ' + (isDFP ? 'dfp' : 'yes')})
    }

    // Run
    if ($.isFunction(window.adblockDetector)) {
        adblockDetector({
            found: adblockAfter,
            notfound: function() {
                if (hasDfp === false) {
                    adblockAfter(1);
                }
            }
        });
    }
});

    // load post load images and divs...
    /*$window.load(function(){

        /* Removing in favor of DMV_Stat project
        var p = window.performance, timing, entries, startTime, i, t, round = Math.round, type, start;
        if (p // has HTML5 Performance API?
            && Math.floor(Math.random()*100) === 1 // is within sampling interval?
        ) {
            t = '';
            if (p.getEntriesByType && (entries = p.getEntriesByType('resource')) instanceof Array) {
                // API supported. Hurray!
                t = [];
                for (i in entries) {
                    timing = entries[i];
                    type = timing.initiatorType;
                    if (timing.name !== "document"
                        && type !== "img" && type !== "css" // skip assets from CSS cuz there's a lot
                    ) {
                        // for the document refer to window.performance.timing instead,
                        /*var o = {
                            connectEnd: 0,
                            connectStart: 0,
                            domainLookupEnd: 0,
                            domainLookupStart: 0,
                            duration: 1287.7849999999994,
                            entryType: "resource",
                            fetchStart: 3690.8800000000006,
                            initiatorType: "xmlhttprequest",
                            name: "https://www.sitepoint.com/wp-admin/admin-ajax.php",
                            redirectEnd: 0,
                            redirectStart: 0,
                            requestStart: 0,
                            responseEnd: 4978.665,
                            responseStart: 0,
                            secureConnectionStart: 0,
                            startTime: 3690.8800000000006,
                            workerStart: 0
                        };/**
                        // resource is cached, some entries are zero,
                        // we default to fetchStart instead
                        start = round(timing.fetchStart || timing.requestStart);
                        t.push({
                            name: timing.name,
                            type: type,
                            start: start,
                            network: start - round(timing.startTime),
                            request: round(timing.responseStart) - round(timing.requestStart),
                            response: round(timing.responseEnd) - round(timing.responseStart)
                        });
                    }
                }
            }

            timing = p.timing;
            startTime = timing.requestStart;

            Errsnag('Performance ping', {
                type: p.navigation.type,
                pre: timing.fetchStart - timing.navigationStart, // unload, redirect
                redirectCount: p.navigation.redirectCount,
                connect: startTime - timing.fetchStart, // fetch, DNS, connect, SSL
                firstByte: timing.responseStart - startTime,
                allBytes: timing.responseEnd - startTime,
                domInteractive: timing.domInteractive - startTime,
                domLoaded: timing.domContentLoadedEventEnd - startTime,
                domComplete: timing.domComplete - startTime,
                //loaded: timing.loadEventEnd - startTime, loadEventEnd always is smaller than startTime
                loaded: timing.loadEventStart - startTime,
                /*domainLookupEnd: startTime - timing.domainLookupEnd,
                connectEnd: startTime - timing.connectEnd,
                requestStart: startTime - timing.requestStart,
                responseStart: startTime - timing.responseStart,
                domInteractive: startTime - timing.domInteractive,
                domContentLoadedEventEnd: startTime - timing.domContentLoadedEventEnd,
                domComplete: startTime - timing.domComplete,
                loadEventEnd: startTime - timing.loadEventEnd/**
                entries: t
            }, 'notice');
        }/**
    });*/

})(window, document);
