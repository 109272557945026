/* global FB, member, ac, ic, call, console, alert */
var member;
(function(window) {
    "use strict";

    var lastFbAppId;
    window.initFB = function(fbAppId) {
        if (fbAppId && fbAppId !== lastFbAppId) {
            lastFbAppId = fbAppId;
            FB.init({
                appId: fbAppId,
                version: 'v2.8',
                status: true,
                cookie: true,
                xfbml: false
            });
            if (window.member) {
                FB.getLoginStatus(function (response) {
                    member.onFacebook = response.status === "connected";
                });
            }
        }
    };

// @private
function logoutOfFacebook() {
    try {
        FB.getLoginStatus(function (response) {
            if (response.status === "connected") {
                FB.logout(logoutOfDMVorg);
            } else {
                logoutOfDMVorg();
            }
        });
    } catch (e) {
        logoutOfDMVorg();
        console.error(e);
    }
}

// @private
function logoutOfDMVorg() {
    $.post('/ajax/membership/logout').always(function() {
        window.location.reload(true);
    });
}

window.showLogin = registrationPopup;

window.postToFB = function(opt, onSuccess, onCancel) {
    if (!member) {
        throw "Member not set";
    }
    if (!member.facebookId) {
        throw "No Facebook account set.";
    }
    FB.getLoginStatus(function (response) {
        if (response.status !== "connected") {
            throw "Member is not logged into facebook";
        }
        FB.ui($.extend({}, {
            // message, linkText, url, pictureUrl, caption, description
                method: 'feed'
                //name: linkText,
                //link: url,
                //picture: pictureUrl,
                //caption: caption,
                //description: message + ' ' + (typeof description !== 'undefined' ? description : '') // cannot leave undefined since message is prepended
//        message: message // message is no longer supported
            }, opt),
            function (response) {
                var f = response && response.post_id ? onSuccess : onCancel;
                if (f) {
                    setTimeout(f, 0);
                }
            }
        );
    });
};

window.renderMemberControls = renderMemberControls;

function renderMemberControls($container) {
    $container = $($container || '#registered-user');// ensure is a jQuery object
    if (window.member && member.fullName) {
        $container.html('<div id="register" class="clearfix">'
        + '<div id="account">'
        + '<img src="' + member.userImage + '">'
        + '</div>'
        + '<div id="account-name">'
        + member.fullName
        + '</div>'
        + '<span id="account-controls">'
        + '<div class="account-logout"><a href="javascript:">Logout</a></div>'
        + '</span>'
        + '</div>');

        $('.account-logout a', $container).on('click', function(e) {
            e.preventDefault();
            ac(this, 'misc_member_logout');
            logoutOfDMVorg();
        });

        if (member.facebookId) {
            $("#account-controls", $container).empty().append($('<div class="account-logout">').append($('<a href="javascript:">Logout</a>').on('click', function() {
                ac(this, 'misc_member_logoutFacebook');
                logoutOfFacebook();
            })));
        }
    } else {
        ic("misc_member_loginLink,misc_member_registerLink");
        $container.empty().append($('<div id="register" class="login">')
                .append($('<a href="javascript:" class="yelbutton">Register</a>')
                    .on('click', function() {
                        ac(this,'misc_member_registerLink');
                        registrationPopup('register');
                    }))
                .append(' ')
                .append($('<a href="javascript:">Already a Member? Log in now!</a>')
                    .click(function() {
                        ac(this,'misc_member_loginLink');
                        registrationPopup();
                    }))
        );
    }
}

/**
 *
 * @private
 */
function registrationPopup(action) {
    var regpp = $('<div><div id="modal-login" style="text-align:center;color:#666;padding:2em;">Loading...</div></div>').modalInit()
            .on('hidden.bs.modal', function () {
                renderMemberControls();
                regpp.remove();
                regpp = null;
            })
            .modal();
    $.get('/ajax/membership/form?mode=' + (action || ''), function(data) {
        $('#modal-login', regpp).replaceWith(data);
    });
}

})(this);
