/* global $ */
(function(window, document) {
    "use strict";

    ////
    // Utilities
    ////

    var hasLevel = {},
        eventNames = {
            1: 27, // Level 1: "Not Engaged Yet"
            2: 28, // Level 2: "Has Scrolled"
            3: 29, // Level 3: "On Page Engagement"
            4: 30, // Level 4: "External Link"
            5: 31, // Level 5: "CPA/CPC ad" << triggered via Processing Rules
            6: 'eVar70' // Level X: "Any engagement to prove IS_HUMAN"
        },
        valuesToSend = { 6: 'Is Human' },
        levelsToSend = {};

    /**
     * @param {number} lvl
     */
    function sendLevelLater(lvl) {
        if (hasLevel[lvl] || !eventNames[lvl]) {
            return;
        }
        hasLevel[lvl] = levelsToSend[lvl] = 1;
        setTimeout(sendLevel, 5000);
    }

    /**
     * @param {number=} lvl
     */
    function sendLevel(lvl) {
        if (typeof lvl === 'number' && !hasLevel[lvl] && eventNames[lvl]) {
            hasLevel[lvl] = levelsToSend[lvl] = 1;
        }
        var s = window.s,
            mustCallTrackLink = s.hasTrackedPageView,
            props = mustCallTrackLink ? {} : s,
            i, cur;
        for (i in levelsToSend) {
            if (levelsToSend.hasOwnProperty(i)) {
                cur = eventNames[i];
                if (typeof cur === 'number') {
                    props.events = (props.events ? props.events + ',' : '') + 'event' + cur;
                    if (mustCallTrackLink) {
                        props.linkTrackEvents = props.events;// must duplicate to s.tl() picks up s.events
                    }
                } else {
                    props[cur] = valuesToSend[i];
                    if (mustCallTrackLink) {
                        props.linkTrackVars = (props.linkTrackVars ? props.linkTrackVars + ',' : '') + cur;
                    }
                }
            }
        }
        levelsToSend = {};
        if (cur && mustCallTrackLink) {// looped over one thing?
            props.linkTrackEvents = props.events;// Must duplicate one property
            // Must explicitly call s.tl()
            s.tl(true, 'o', 'Engagement', props);
        }
    }

    function addUniqueValue(key, val) {
        var a = valuesToSend[key];
        if (!a) valuesToSend[key] = val;
        else if (a.indexOf(val) < 0) {
            valuesToSend[key] += (a.indexOf(':') < 0 ? ': ' : ', ') + val;
        }
    }

    // Enable triggering this within Optimizely or AdvertPro
    window.Engagement = init;

    var cookieName = 'engagementPage',
        pageNum = parseInt(readCookie(cookieName)) || 0;

    // Sample, only trigger engagement metric for 1%
    // Swimlanes 01-10 are used by Shane for testing, so 00 is preferred
    // Special case: If we're on page=2, then we need to run to find Level-5.
    if (pageNum > 0 || readCookie('swimlane') === '00') {
        init();
    }

    /**
     * Start recording engagement metrics for this page view.
     * Call this in-line if possible to try to modify the Page Impression beacon instead of sending a second one.
     * Pass forcedStart=true to skip the
     *
     * @param {bool=} forcedStart
     */
    function init(forcedStart) {
        // Only use modern browsers
        // Don't initialize twice
        if (!document.addEventListener || window.hasEngagementTracking) {
            return;
        }

        ////
        // Level #5: "Internal Link"
        ////
        // Trigger lvl5 on the SECOND navigational page
        // Store the page number
        pageNum++;
        if (pageNum === 2) {// hit second page?
            sendLevel(5);
        }
        if (forcedStart) {
            pageNum = 1;// reset to first-page so we count Level5 AGAIN on the next page-view
        }
        document.cookie = cookieName + '=' + pageNum
            + ';path=/;domain=' + getCookieDomain()
            // +1 day from now
            + ';expires=' + new Date(Date.now() + 1000 * 60 * 60 * 24).toUTCString();
        if (pageNum > 1) {
            return;// Stop measuring!
        }

        // User is a part of the engagement metric!
        // Will trigger different functionality within ac()
        window.hasEngagementTracking = true;


        ////
        // Level #1: "Not Engaged Yet"
        ////
        sendLevel(1);


        ////
        // Level #2 "Has Scrolled"
        ////
        var winHeight = $(window).height();
        window.addEventListener('scroll', windowScrollListener);
        function windowScrollListener() {
            addUniqueValue(6, 'Scroll');
            sendLevelLater(6);// this is definitely a human
            if ((window.scrollTop || window.scrollY) > winHeight / 2) {
                sendLevelLater(2);
                window.removeEventListener('scroll', windowScrollListener);
            }
        }


        ////
        // Levels #3 "On Page Engagement" & #4 "External Link"
        ////
        document.addEventListener('click', function (e) {
            var target = e.target;
            // Must have a parent AND mouse coordinates (this weeds out programmatic click events)
            if (!target.parentNode || !(e.screenX || e.screenY)) {
                return;
            }

            addUniqueValue(6, 'Click');
            sendLevelLater(6); // if there's screen coordinates, it's probably a human

            // Go 3 levels deep
            var nodes = [target, target.parentNode];
            if (nodes[1].parentNode) {
                nodes.push(nodes[1].parentNode);
            }

            // Remove elements that are globally used as wrappers
            var $els = [], cur, i = 0;
            for (; i < nodes.length; i++) {
                cur = nodes[i];
                var nodeName = cur.nodeName,
                    id = cur.id;
                if (
                    cur !== document && cur !== document.body
                    && nodeName !== 'BODY' && nodeName !== '#document' && nodeName !== 'HTML'
                    && id !== 'page' && id !== 'disclaimer' && id !== 'page-wrapper' && id !== 'header' && id !== 'footer' && id !== 'mainContent'
                    && !cur.classList.contains('page-wrapper')
                ) {
                    $els.push(cur);
                }
            }
            if (!$els.length) {
                return;
            }
            $els = $($els);// turn into jQuery

            // Special case: .intPage is used on in-copy External Links
            if ($els.is('.intPage')) {
                sendLevel(4);
                return;
            }

            // Special case: .socialBar>a elements will have external URLs
            //  but they should always be tracked as in-page engagement
            var firstLink;
            if (!$els.is('.socialBar') && (firstLink = $els.filter('a[href]')[0])) {
                // are we leaving the page?
                if (window.location.href.split('#')[0] !== firstLink.href.split('#')[0]) {
                    sendLevel();// flush cache, we're probably navigating away
                    //if (getCookieDomain() === f[0].href.replace(/^[\w-:]*\/\/.*(\.[\w-]+\.[\w-]+)\/.*/, '$1')) {
                    // this is a link to on-domain page, Level 5 will trigger on page#2
                    //}
                    return;
                }
                // anchor link! we're staying here, do default action
            }

            sendLevelLater(3);

        }, true);// receive the event during "capture phase", meaning before entering the DOM

        // Listen for global "change" events, like from form elements
        // I'm using jQuery here because jQuery will fix non-bubbling events like 'change' in certain browsers
        $(document).one('change.engagement', function () {
            // Will triggered when <select> or <input> boxes are modified
            // This works MOST of the time, but not ALL the time
            sendLevelLater(3);// On Page Engagement
            addUniqueValue(6, 'Change');
            sendLevelLater(6);// this is probably a human
        }).one('mouseenter.engagement mouseleave.engagement mousemove.engagement touchstart.engagement touchcancel.engagement deviceorientation.engagement devicemotion.engagement', function(e) {
            addUniqueValue(6, e.type);
            sendLevelLater(6);// this is definitely a human
        });


        // Make sure levels get sent, even if the page is going away
        window.addEventListener('beforeunload', sendLevel);

    }

})(window, document);