/*global window, document*/
/**
 * Based on code from [Bugsnag](https://bugsnag.com).
 *
 * We are going to make some assumptions to pare down this file:
 * *) The name of the product is NOT BugSnag
 * *) We don't need a noConflict or undo function
 * *) Testing options aren't as useful since we can carefully control the source
 * *) Globally capturing everything isn't as important as a generic onerror handler
 *
 * @see https://bugsnag.com
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/stack
 * @see http://blog.trasatti.it/2012/12/measuring-the-speed-of-resource-loading-with-javascript-and-html5.measuring-the-speed-of-resource-loading-with-javascript-and-html5
 * @see http://blog.bugsnag.com/js-stacktraces
 * @see http://www.stacktracejs.com/#!/docs/error-stack-parser
 * @see https://blog.newrelic.com/2014/03/13/javascript-error-reporting-ajax-timing-new-relic/
 */
(function (window, document, undefined) {
    "use strict";

    var self,
        setTimeout = window.setTimeout,// This will be polyfill'd, so cache a local reference
        slice = Array.prototype.slice,
        JSON = window.JSON,
        lastEvent,// Last event from any wrapped function
        lastScript,// Last currentScript from any wrapped function
        previousNotification,// The last event to be logged, used for de-duplication
        shouldCatch = true,
        cache = [],
        sendTimer,
        ignoreOnError = 0,
        //isTesting = typeof TESTING !== 'undefined',
        synchronousScriptsRunning = document.readyState !== "complete",

    // We've seen cases where individual clients can infinite loop sending us errors
    // (in some cases 10,000+ errors per page). This limit is at the point where
    // you've probably learned everything useful there is to debug the problem,
    // and we're happy to under-estimate the count to save the client (and Bugsnag's) resources.
        eventsRemaining = 10, // Max events that can be sent in total
    // The default depth of attached metadata which is parsed before truncation. It
    // is configurable via the `maxDepth` setting.
        maxPayloadDepth = 5,

    // Compile regular expressions upfront.
        IGNORE_MSG_REGEX = /^Script error\.?/;

    /**
     * Log messages to the server.
     *
     * @param {string} msg
     * @param {{}}     [data=]
     * @param {string} [level=error]
     */
    window.Errsnag = self = function(msg, data, level) {
        data = data || {};
        if (data.msg) {
            data._msg = msg;
        }
        data.msg = msg;
        if (level) {
            data.level = level;
        }
        sendToServer(data);
    };
    self.timeout = 4000;
    self.endpoint = '/ajax/Log/js';


    // ### Bugsnag.refresh
    //
    // Resets the Bugsnag rate limit. If you have a large single-page app, you may
    // wish to call this in your router to avoid exception reports being thrown
    // away.
    //
    // By default Bugsnag aggressively limits the number of exception reports from
    // one page load. This protects both the client's browser and our servers in
    // cases where exceptions are thrown in tight loops or scroll handlers.
    self.refresh = function() {
        eventsRemaining = 10;
    };

    //
    // ### Manual error notification (public methods)
    //

    // #### Bugsnag.notify
    //
    // Notify Bugsnag about an error by passing in a `name` and `message`,
    // without requiring an exception.
    self.notify = function (name, msg, metaData, level) {
        sendToServer({
            name: name,
            msg: msg,
            stacktrace: generateStacktrace(),
            // These are defaults so that 'bugsnag.notify()' calls show up in old IE,
            // newer browsers get a legit stacktrace from generateStacktrace().
            file: window.location.toString(),
            line: 1,
            level: level || "warning",
            meta: metaData
        });
    };

    //
    // ### Script tag tracking
    //

    // To emulate document.currentScript we use document.scripts.last.
    // This only works while synchronous scripts are running, so we track
    // that here.
    function loadCompleted() {
        synchronousScriptsRunning = false;
    }

    // from jQuery. We don't have quite such tight bounds as they do if
    // we end up on the window.onload event as we don't try and hack
    // the .scrollLeft() fix in because it doesn't work in frames so
    // we'd need these fallbacks anyway.
    // The worst that can happen is we group an event handler that fires
    // before us into the last script tag.
    if (document.addEventListener) {
        document.addEventListener("DOMContentLoaded", loadCompleted, true);
        window.addEventListener("load", loadCompleted, true);
    } else {
        window.attachEvent("onload", loadCompleted);
    }

    function getCurrentScript() {
        var script = document.currentScript || lastScript,
            scripts;

        if (!script && synchronousScriptsRunning) {
            scripts = document.scripts || document.getElementsByTagName("script");
            script = scripts[scripts.length - 1];
        }

        return script;
    }

    function addScriptToMetaData() {
        var script = getCurrentScript();
        if (script) {
            return {
                src: script.src,
                content: getSetting("inlineScript", true) ? script.innerHTML : ""
            };
        }
    }

    //
    // ### Helpers & Setup
    //

    /**
     * Simple logging function that wraps `console.log` if available.
     * This is useful for warning about configuration issues
     * eg. forgetting to set an API key.
     */
    function log() {
        var console = window.console;
        if (console && console.log && console.log.apply && !getSetting('disableLog')) {
            console.log.apply(console, slice.call(arguments));
        }
    }

    /**
     * Deeply serialize an object into a query string. We use the PHP-style
     * nested object syntax, `nested[keys]=val`, to support heirachical
     * objects. Similar to jQuery's `$.param` method.
     *
     * @param {{}} obj
     * @param {string} [prefix=]
     * @param {number} [depth=]
     * @return {string}
     */
    function serialize(obj, prefix, depth) {
        var maxDepth = getSetting("maxDepth", maxPayloadDepth),
            encodeURIComponent = window.encodeURIComponent;

        if (depth >= maxDepth) {
            return encodeURIComponent(prefix) + "=-snip-";
        }
        depth = depth + 1 || 1;

        try {
            if (window.Node && obj instanceof window.Node) {
                return encodeURIComponent(prefix) + "=" + encodeURIComponent(targetToString(obj));
            }

            var str = [], p, k, v;
            for (p in obj) {
                if (obj.hasOwnProperty(p)) {
                    v = obj[p];
                    k = prefix ? prefix + "[" + p + "]" : p;
                    if (typeof v === "object") {
                        v = serialize(v, k, depth);
                    } else {
                        try {
                            v = encodeURIComponent(v);
                        } catch (e) {
                            v = "SERIALIZE_FAILED";
                        }
                        v = encodeURIComponent(k) + "=" + v;
                    }
                    str.push(v);
                }
            }
            return str.join("&");
        } catch (e) {
            return encodeURIComponent(prefix) + "=" + encodeURIComponent("" + e);
        }
    }

    /*
    // Deep-merge the `source` object into the `target` object and return
    // the `target`. Properties in source that will overwrite those in target.
    // Similar to jQuery's `$.extend` method.
    function merge(target, source, depth) {
        if (source == null) {
            return target;
        } else if (depth >= getSetting("maxDepth", maxPayloadDepth)) {
            return "[RECURSIVE]";
        }

        target = target || {};
        for (var key in source) {
            if (source.hasOwnProperty(key)) {
                try {
                    if (source[key].constructor === Object) {
                        target[key] = merge(target[key], source[key], depth + 1 || 1);
                    } else {
                        target[key] = source[key];
                    }
                } catch (e) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    }*/

    // Get configuration settings from either `self` (the `Bugsnag` object)
    // or `data` (the `data-*` attributes).
    function getSetting(name, fallback) {
        return self[name] !== undefined ? self[name] : fallback;
    }

    // Send an error to Bugsnag.
    function sendToServer(details) {
        // Validate the configured API key.
        if (!eventsRemaining) {
            return;
        }
        eventsRemaining -= 1;

        // Don't send multiple copies of the same error.
        // This fixes a problem when a client goes into an infinite loop,
        // and starts wasting all their bandwidth sending messages to bugsnag.
        var deduplicate = details.name+"|"+details.msg+"|"+details.stacktrace;
        if (deduplicate === previousNotification) {
            return;
        }
        previousNotification = deduplicate;

        if (details.line === 0 && IGNORE_MSG_REGEX.test(details.msg)) {
            // @see https://bugsnag.com/docs/notifiers/js/cors
            return log("Ignoring cross-domain script error.");
        }

        if (lastEvent) {
            details.lastEvent = {
                millisecondsAgo: new Date() - lastEvent.timeStamp,
                type: lastEvent.type,
                which: lastEvent.which,
                target: targetToString(lastEvent.target)
            };
        }

        for (var i in details) {
            if (details.hasOwnProperty(i)) {
                if (details[i] === undefined) {
                    delete details[i];
                }
            }
        }

        cache.push(details);
        if (!sendTimer) {
            sendTimer = setTimeout(flushToServer, self.timeout);
        }
    }

    function flushToServer() {
        clearTimeout(sendTimer);
        sendTimer = 0;
        if (!cache.length) {
            return;
        }
        var msgs = cache;// store local copy
        cache = [];// clear the cache right away

        /**
         * Make a HTTP request with given `url` and `params` object.
         * For maximum browser compatibility and cross-domain support, requests are
         * made by creating a temporary JavaScript `Image` object.
         * Additionally the request can be done via XHR (needed for Chrome apps and extensions)
         * To set the script to use XHR, you can specify data-notifyhandler attribute in the script tag
         * Eg. `<script data-notifyhandler="xhr">` - the request defaults to image if attribute is not set
         */
        // Make the HTTP request
        var url = self.endpoint,
            navigator = window.navigator || {},
            payload = {
                url: window.location.href,
                agent: navigator.userAgent,
                // might be helpful: language: navigator.language || navigator.userLanguage,
                msgs: msgs
            },
            method, postStr;

        if (typeof XMLHttpRequest === 'function') {
            try {
                if (JSON && JSON.stringify) {
                    try {
                        // Yay, we can post raw JSON!
                        method = "application/json";
                        // NOTE: this does not limit depth, so recursive references might cause a problem!
                        postStr = JSON.stringify(payload);
                    } catch (e) {
                        log(e, payload);
                    }
                }

                if (!postStr) {
                    // Use standard POST variables
                    method = "application/x-www-form-urlencoded";
                    postStr = serialize(payload);

                    if (!postStr) {
                        // Should never send an empty request!
                        throw 'Could not parse payload';
                    }
                }

                // Re-use local variable
                payload = new XMLHttpRequest();
                payload.open("POST", url, true);
                payload.setRequestHeader("Content-Type", method);
                payload.send(postStr);

                return;// success!

            } catch (e) {
                log(e, payload);
            }
        }

        // Fall-back to using GET parameters
        postStr = serialize(payload);
        if (!postStr) {
            // Should never send an empty request!
            log('Could not parse payload', payload);
            return;
        }

        // Image beacon
        new Image().src = url + "?" + postStr + "&_=" + new Date().getTime();
    }

    // Generate a browser stacktrace (or approximation) from the current stack.
    // This is used to add a stacktrace to `Bugsnag.notify` calls, and to add a
    // stacktrace approximation where we can't get one from an exception.
    function generateStacktrace() {
        var generated, stacktrace;

        // Try to generate a real stacktrace (most browsers, except IE9 and below).
        try {
            throw new Error("");
        } catch (exception) {
            generated = "<generated>\n";
            stacktrace = stacktraceFromException(exception);
        }

        // Otherwise, build a fake stacktrace from the list of method names by
        // looping through the list of functions that called this one (and skip
        // whoever called us).
        if (!stacktrace) {
            /*generated = "<generated-ie>\n";
            var functionStack = [], curr,
                FUNCTION_REGEX = /function\s*([\w\-$]+)?\s*\(/i,
                MAX_FAKE_STACK_SIZE = 10, ANONYMOUS_FUNCTION_PLACEHOLDER = "[anonymous]";
            try {
                curr = arguments.callee.caller.caller;
                while (curr && functionStack.length < MAX_FAKE_STACK_SIZE) {
                    functionStack.push(FUNCTION_REGEX.test(curr.toString())
                        ? RegExp.$1 || ANONYMOUS_FUNCTION_PLACEHOLDER
                        : ANONYMOUS_FUNCTION_PLACEHOLDER);
                    curr = curr.caller;
                }
            } catch (e) {
                log(e);
            }
            stacktrace = functionStack.join("\n");*/
            // ... MEH! this is such a small percentage, let's just ignore the stack trace.
            stacktrace = "<failed>";
        }

        // Tell the backend to ignore the first two lines in the stack-trace.
        // generateStacktrace() + window.onerror,
        // generateStacktrace() + notify,
        // generateStacktrace() + notifyException
        return generated + stacktrace;
    }

    // Get the stacktrace string from an exception
    function stacktraceFromException(exception) {
        return exception.stack || exception.backtrace || exception.stacktrace;
    }

    // Convert a DOM element into a string suitable for passing to Bugsnag.
    function targetToString(target) {
        if (target) {
            var attrs = target.attributes;

            if (attrs) {
                var ret = "<" + target.nodeName.toLowerCase(), i=0;
                for (; i < attrs.length; i++) {
                    if (attrs[i].value && attrs[i].value.toString() !== "null") {
                        ret += " " + attrs[i].name + "=\"" + attrs[i].value + "\"";
                    }
                }
                return ret + ">";
            } else {
                // e.g. #document
                return target.nodeName;
            }
        }
    }

    // Good idea I think, but probably will never work
    if (window.addEventListener) {
        window.addEventListener('unload', flushToServer);
    }

    //
    // ### Polyfilling
    //

    // Add a polyFill to an object
    function polyFill(obj, name, makeReplacement) {
        var original = obj[name];
        obj[name] = makeReplacement(original);

        /* Don't need this
        if (isTesting && window.undo) {
            window.undo.push(function () {
                obj[name] = original;
            });
        }*/
    }


    //
    // ### Automatic error notification
    //
    // Attach to `window.onerror` events and notify Bugsnag when they happen.
    // These are mostly js compile/parse errors, but on some browsers all
    // "uncaught" exceptions will fire this event.
    //
    polyFill(window, "onerror", function (_super) {
        /**
         * Handle uncaught exceptions by posting the error back to the server.
         * This is as close as we might get to "Real User Monitoring" in terms of error handling.
         *
         * @param {string} msg
         * @param {string} [url=]
         * @param {int}    [line=]
         * @param {int}    [col=]
         * @param {{}}     [error=]
         */
        return function bugsnag(msg, url, lineNo, charNo, exception) {
            // Note that col & error are new to the HTML 5 spec and may not be
            // supported in every browser.

            /*// IE 6+ support.
            if (!charNo && window.event) {
                charNo = window.event.errorCharacter;
            }*/

            var m = addScriptToMetaData();
            lastScript = null;

            if (!ignoreOnError) {
                sendToServer({
                    name: exception && exception.name || "-",
                    msg: msg,
                    file: url,
                    line: lineNo,
                    col: charNo,
                    stacktrace: (exception && stacktraceFromException(exception)) || generateStacktrace(),
                    //level: "error",
                    script: m
                });
            }

            // Fire the existing `window.onerror` handler, if one exists
            if (_super) {
                _super(msg, url, lineNo, charNo, exception);
            }

            // If you return true, then error alerts (like in older versions of
            // Internet Explorer) will be suppressed.
            //return false; do nothing for now
        };
    });


    /* Disabling for now
    // If this is the top frame, then don't polyfill additional functions
    if (window.top === window || window.self === window.top) return;

    // Disable catching on IE < 10 as it destroys stack-traces from generateStackTrace()
    if (!window.atob) {
        shouldCatch = false;

        // Disable catching on browsers that support HTML5 ErrorEvents properly.
        // This lets debug on unhandled exceptions work.
    } else if (window.ErrorEvent) {
        try {
            if (new window.ErrorEvent("test").colno === 0) {
                shouldCatch = false;
            }
        } catch(e) {
            // No action needed
        }
    }

    // #### Bugsnag.notifyException
    //
    // Notify Bugsnag about a given `exception`, typically that you've caught
    // with a `try/catch` statement or that you've generated yourself.
    //
    // It's almost always better to let an exception bubble rather than catching
    // it, as that gives more consistent behaviour across browsers. Consider
    // re-throwing instead of calling .notifyException.
    //
    // Since most JavaScript exceptions use the `Error` class, we also allow
    // you to provide a custom error name when calling `notifyException`.
    //
    // The default value is "warning" and "error" and "info" are also supported by the
    // backend, all other values cause the notification to be dropped; and you
    // will not see it in your dashboard.
    function notifyException(exception, name, metaData, level) {
        if (!exception) {
            return;
        }
        if (name && typeof name !== "string") {
            metaData = name;
            name = undefined;
        }
        if (!metaData) {
            metaData = {};
        }
        var m = addScriptToMetaData(metaData);
        if (m) {
            metaData.script = m;
        }

        sendToServer({
            name: name || exception.name,
            msg: exception.message || exception.description,
            stacktrace: stacktraceFromException(exception) || generateStacktrace(),
            file: exception.fileName || exception.sourceURL,
            line: exception.lineNumber || exception.line,
            col: exception.columnNumber ? exception.columnNumber + 1 : undefined,
            level: level || "warning",
            meta: metaData
        });
    }

    // Return a function acts like the given function, but reports
    // any exceptions to Bugsnag before re-throwing them.
    //
    // This is not a public function because it can only be used if
    // the exception is not caught after being thrown out of this function.
    //
    // If you call wrap twice on the same function, it'll give you back the
    // same wrapped function. This lets removeEventListener to continue to
    // work.
    function wrap(_super, options) {
        function passthru(event) {
            if (isEventHandler) {
                lastEvent = event;
            }
            lastScript = currentScript;

            // We set shouldCatch to false on IE < 10 because catching the error ruins the file/line as reported in window.onerror,
            // We set shouldCatch to false on Chrome/Safari because it interferes with "break on unhandled exception"
            // All other browsers need shouldCatch to be true, as they don't pass the exception object to window.onerror
            if (shouldCatch) {
                try {
                    return _super.apply(this, arguments);
                } catch (e) {
                    // We do this rather than stashing treating the error like lastEvent
                    // because in FF 26 onerror is not called for synthesized event handlers.
                    sendToServer({
                        name: e.name,
                        msg: e.message || e.description,
                        stacktrace: stacktraceFromException(e) || generateStacktrace(),
                        file: e.fileName || e.sourceURL,
                        line: e.lineNumber || e.line,
                        col: e.columnNumber ? e.columnNumber + 1 : undefined,
                        level: "error"
                    });

                    // If we've notified bugsnag of an exception in wrap, we don't want to
                    // re-notify when it hits window.onerror after we re-throw it.
                    ignoreOnError += 1;
                    setTimeout(function () {
                        ignoreOnError -= 1;
                    });

                    throw e;
                } finally {
                    lastScript = null;
                }
            } else {
                var ret = _super.apply(this, arguments);
                // in case of error, this is set to null in window.onerror
                lastScript = null;
                return ret;
            }
        }

        try {
            var currentScript,
                wrapper,
                isEventHandler = !!(options && options.eventHandler);
            if (typeof _super === "function") {
                wrapper = _super.errsnag;
                if (!wrapper) {
                    currentScript = getCurrentScript();
                    // fix recursion so we never wrap twice
                    passthru.errsnag = wrapper = _super.errsnag = passthru;
                }
                return wrapper;
            }
        } catch (e) {
            // This can happen if _super is not a normal javascript function.
            // For example, see https://github.com/bugsnag/bugsnag-js/issues/28
        }
        return _super;
    }

    function hijackTimeFunc(_super) {
        // Note, we don't do `_super.call` because that doesn't work on IE 8,
        // luckily this is implicitly window so it just works everywhere.
        //
        // setTimeout in all browsers except IE <9 allows additional parameters
        // to be passed, so in order to support these without resorting to call/apply
        // we need an extra layer of wrapping.
        return function (f, t) {
            if (typeof f === "function") {
                f = wrap(f);
                var args = slice.call(arguments, 2);
                return _super(function () {
                    f.apply(this, args);
                }, t);
            } else {
                return _super(f, t);
            }
        };
    }

    polyFill(window, "setTimeout", hijackTimeFunc);
    polyFill(window, "setInterval", hijackTimeFunc);

    if (window.requestAnimationFrame) {
        polyFill(window, "requestAnimationFrame", function (_super) {
            return function (callback) {
                return _super(wrap(callback));
            };
        });
    }

    if (window.setImmediate) {
        polyFill(window, "setImmediate", function (_super) {
            return function () {
                var args = slice.call(arguments);
                args[0] = wrap(args[0]);
                return _super.apply(this, args);
            };
        });
    }

    // EventTarget is all that's required in modern chrome/opera
    // EventTarget + Window + ModalWindow is all that's required in modern FF (there are a few Moz prefixed ones that we're ignoring)
    // The rest is a collection of stuff for Safari and IE 11. (Again ignoring a few MS and WebKit prefixed things)
    "EventTarget Window Node ApplicationCache AudioTrackList ChannelMergerNode CryptoOperation EventSource FileReader HTMLUnknownElement IDBDatabase IDBRequest IDBTransaction KeyOperation MediaController MessagePort ModalWindow Notification SVGElementInstance Screen TextTrack TextTrackCue TextTrackList WebSocket WebSocketWorker Worker XMLHttpRequest XMLHttpRequestEventTarget XMLHttpRequestUpload".replace(/\w+/g, function (global) {
        var prototype = window[global] && window[global].prototype;
        if (prototype && prototype.hasOwnProperty && prototype.hasOwnProperty("addEventListener")) {
            polyFill(prototype, "addEventListener", function (_super) {
                return function (e, f, capture, secure) {
                    // HTML lets event-handlers be objects with a handlEvent function,
                    // we need to change f.handleEvent here, as self.wrap will ignore f.
                    try {
                        if (f && f.handleEvent) {
                            f.handleEvent = wrap(f.handleEvent, {eventHandler: true});
                        }
                    } catch (err) {
                        // When selenium is installed, we sometimes get 'Permission denied to access property "handleEvent"'
                        // Because this catch is around Bugsnag library code, it won't catch any user errors
                        log(err);
                    }
                    return _super.call(this, e, wrap(f, {eventHandler: true}), capture, secure);
                };
            });

            // We also need to hack removeEventListener so that you can remove any
            // event listeners.
            polyFill(prototype, "removeEventListener", function (_super) {
                return function (e, f, capture, secure) {
                    _super.call(this, e, f, capture, secure);
                    return _super.call(this, e, wrap(f), capture, secure);
                };
            });
        }
    });*/

})(window, document);