/*global $, window*/
// This file should contain STATIC SYMBOLS ONLY!!

/**
 * Used in lots of Apro medias.
 *
 * @param {string} field
 * @returns {boolean}
 */
function validateZIP(field) {
    var valid = "0123456789-", hyphencount = 0;

    if (field.length != 5 && field.length != 10) {
        alert("Please enter your 5 digit ZIP code.");
        return false;
    }
    for (var i = 0; i < field.length; i++) {
        var temp = "" + field.substring(i, i + 1);
        if (temp == "-") hyphencount++;
        if (valid.indexOf(temp) == "-1") {
            alert("Invalid characters in your zip code.  Please try again.");
            return false;
        }
        if ((hyphencount > 1) || ((field.length == 10) && "" + field.charAt(5) != "-")) {
            alert("The hyphen character should be used with a properly formatted 5 digit+four zip code, like '12345-6789'.   Please try again.");
            return false;
        }
    }
    return true;
}

/**
 * Open up a modal dialog with an iframe to suggestions_popup.html
 * Parses suggestionTypeId from URL:
 *   Helpful/not-helpful flag: 1=helpful, 2=not-helpful, *=feedback
 * @param {string} link
 */
function suggestionPopup(link) {
    var url = ((link && link.href) || '').replace(/^https?:/, location.protocol), // make protocol-relative so works on secure pages
        suggestionTypeId = url.match(/suggestionTypeId=(\d*)/);
    suggestionTypeId = suggestionTypeId ? suggestionTypeId[1] : 0;
    if (!url) {
        // should never happen, but just in case...
        return;
    }
    ac(link,
        (suggestionTypeId == 1 ? 'helpful'
            : (suggestionTypeId == 2 ? 'not-helpful'
                : 'feedback')
        ),
        'event12');
    if (window.navigator && navigator.appName && navigator.appName.indexOf("Internet Explorer") !== -1) { // yeah, he's using IE
        var ver = parseInt(navigator.appVersion.replace(/^.*?MSIE (\d+).*$/, '$1'), 10);
        if (ver && ver < 9) {
            // IE 8/9 can't handle the modal
            location.href = url;
            return;
        }
    }
    var $container = $(
                '<div class="modal fade"><div class="modal-dialog" style="max-width:640px;margin-left:auto;margin-right:auto"><div class="modal-content">'
                + '<a href="javascript:" class="modal-close" data-dismiss="modal"></a>'
                + '<div class="loading" style="border-radius:8px;overflow:hidden"></div>'
                + '</div></div></div>'
            )
            .appendTo('body')// important to add to document, subsequent JS code relies on it
            .modal()
            .on('hide.bs.modal', function() {
                // Don't leave the markup laying around
                $container.remove();
            }),
        $inner = $('.modal-content>div', $container);
    $.ajax({
        url: url + '&ajax=1',
        success: function(data) {
            // JS code in data will be eval'd and needs to find elements
            // so make sure $container is already in DOM!
            $inner.html(data);
        },
        error: function() {
            $inner.html('Failed loading data. Close and try again.');
            // Manually navigate to the URL
            location.href = url;
        }
    });
    return false; // stop event propagation
}

/**
 * Used on Drivers-License content areas.
 */
function doSearch(id, domain, stateName) {
	var $searchForm = $(typeof id === 'string' ? ("#"+id) : id),
        keyword, len, maxLen=0, tstate, i,
        $input = $searchForm.find("input[type=text]:eq(0)");

	keyword = ('' + $input.val())
		.replace(/[~`!@#$%^&*()_+=|}{\\\]['";:\/?.>,< ]+/gi, " ") // remove bad and duplicate space chars
        .replace(/(^\s+|\s+$)/, '') // trim
        .toLowerCase();

    if (!keyword || keyword === 'search') {
        alert("Please enter a search term");
        $input.focus();
        return false;
    }

    stateName = stateName || $searchForm.find('select>:selected').text();
    // If keyword contains a stateName, then override the stateName
	for (i=0; i<arrStateName.length; i++) {
        tstate = arrStateName[i].toLowerCase();
		if (keyword.indexOf(tstate)===0 && (len=tstate.length) > maxLen) {
            maxLen = len;
            stateName = tstate;
        }
    }

    // Ensure stateName is formatted and missing from keyword
	if (stateName) {
        stateName = ('' + stateName).toLowerCase();
        keyword = keyword.replace(new RegExp(' *' + stateName + ' *'), "");

        // Format for URL
        stateName = stateName.replace(/ +/g, '-') + '/';
    }

    // Format for URL
    keyword = keyword.replace(/ +/g,"-");

    // Send user to new URL
    (top || window).location = 'http://' + (domain || 'search.dmv.org') + '/dmv/' + stateName + keyword;

    // Stop any normal form submission
	return false;
}


/**
 * Static Call
 *
 * This will use localStorage to cache static things and inject them into the page on load.
 */
(function(window, undefined) {
    "use strict";

    var queue = [],   // queue of calls yet to be made
        waiting = {}, // queue of calls made, waiting for response,
            // use string keys instead of integers because of browser
            // differences in Array implementation.
        execTimer,    // timer until next exec()
        uqid = 0,     // global-unique for generating keys
    // FYI: the "rXX" here is so that we can "cache-bust" when releasing a new feature that we're worried
    // the browser cache might break
        cachePrefix = 'staticCall_r1_', // prefix for storing keys in localStorage
        cacheTimeout = 60 * 60 * 1000, // number of milliseconds to timeout a key and refresh the data
        log = window.console.log;

    if (log.bind) {
        log = log.bind(window.console); /*IE>8*/
    }

    // Allow exec() by using window.staticCall.exec()
    call.exec = exec;
    // Save call() to global scope
    window.staticCall = call;

    /**
     * Queue up a component call.
     *
     * @param {string}     component
     * @param {string|object} args
     * @param {function}   [callback=] (optional)
     */
    function call(component, args, callback) {
        var req= {
            data: {
                func: component,
                args: typeof args === "string" ? parseQuery(args) : args
            }
        };

        if (typeof callback === 'function') {
            // exec function on complete
            req.success = callback;
        } else if (callback && callback.nodeType) {
            // dump string into element with template-replace
            req.el = callback;
        }

        queue.push(req);

        if (execTimer) {
            clearTimeout(execTimer);
        }
        setTimeout(exec, 99); // exec will get called on DOMReady in init.js
    }

    /**
     * Execute the queue of calls.
     */
    function exec() {
        // For logging purposes only: include unique methods as part of the URL so it appears in access_log
        var cur, key, cached, methods = {}, methodsArr = [],
            data = {};
        // clear the timer variable so we don't get notices
        execTimer = null;

        // Build data object from queue
        // use an explicit index of an object so that if the server responds empty
        // for a particular index, content from further in the array is not bumped
        while (cur = queue.shift()) {
            // Attempt to pull from cache
            key = hashString(JSON.stringify(cur.data));
            cached = cache(key);
            if (cached) {
                successItem(cached, cur);
            } else {
                // Allow argument to skip cache
                if (!(cur.data.args || {}).nocache) { // protect against empty arguments
                    cur.key = key; // save so we don't have to re-hash later
                }
                key = '_' + uqid++;
                data[key] = cur.data;
                waiting[key] = cur;
                // This is for debugging 404 issue with logo.gif, can be removed when issue fixed
                methods[cur.data.func + ((cur.data.args && cur.data.args.name && ('-' + cur.data.args.name)) || '')] = true;
            }
        }

        // De-dup method calls
        for (cur in methods) {
            methodsArr.push(cur);
        }

        // Only do AJAX request if required
        if (!methodsArr.length) {
            return;
        }

        $.ajax({
            // Use URL that includes protocol for search.dmv.org; all other domains are self-hosted
            url: "/ajax/multi/call?m=" + methodsArr.join(','),
            type: 'POST',
            // For security reasons, do not send array (edge-case browser security issue)
            data: { calls: data },
            dataType: 'json',
            success: success,
            error: function(a, b) {
                log('Error downloading sculpting data', a, b);
            }
        });
    }

    /**
     * Deal with JSON response from server.
     * @param {object} data
     */
    function success(data) {
        if (data && data.calls) {
            $.each(data.calls, function (i, curCall) {
                if (typeof curCall === 'string') {
                    var curDest = waiting[i];
                    if (curDest) {
                        // Remove from the waiting pool
                        delete waiting[i];
                        if (curDest.key) {
                            cache(curDest.key, curCall);
                        }
                        successItem(curCall, curDest);
                    } else {
                        log('Downloaded static data but missing destination: ' + i, waiting);
                    }
                } else {
                    // Components can return array of arguments/errors
                    log('Error in component', curCall);
                }
            });
        } else {
            log('Error parsing static data', data);
        }
    }

    /**
     * Deal with a single successful return.
     * @param {string} src
     * @param {object} dest
     */
    function successItem(src, dest) {
        if (dest.el) {
            // Replace variable names with their values
            var $div = $(dest.el),
                dataAttr = $div.data();
            // Curly braces template variable injection
            src = (''+src).replace(/\{\{([\w-]+)\}\}/g, function(i,j) {
                // IE requires using .attr() instead of data
                return dataAttr[j] || $div.attr('data-'+j);
            });
            if (src.indexOf('\\\\')>0) {
                Errsnag('Found backslashs in server-returned value', {
                    str: src
                });
            }
            // Inject the content into the DOMElement
            $div.html(src);
        }
        if (dest.success) {
            dest.success.call(dest.el || this, src);
        }
    }

    /**
     * Get/set keys in localStorage safely using a set prefix and timeout.
     * @param {string} key
     * @param {string} [value=] (optional)
     * @returns {null|string}
     */
    function cache(key, value) {
        var now = Date.now(), localStorage;
        key = cachePrefix + key;
        // protect against the key being too big
        try {
            // protected against missing localStorage
            localStorage = window.localStorage;
            if (!localStorage) {
                return null;
            }
            if (value === undefined) {
                // GET
                value = localStorage.getItem(key);
                // Evaluate JSON
                value = JSON.parse(value);
                if (value && value.time) {
                    if (value.time < now - cacheTimeout) {
                        // Timeout the data
                        value = null;
                    } else {
                        // Allow using this key
                        value = value.val;
                    }
                }
            } else {
                // SET
                // Store time with data
                localStorage.setItem(key, JSON.stringify({ time:now, val:value }));
            }
        } catch (e) {
            value = null;
            log(e);
        }
        return value;
    }

    /**
     * Abstraction layer to hide our hashing algo.
     * @param {string} str
     */
    function hashString(str) {
        //return window.md5(); kinda slow
        // Reduce maybe causing problems?
        //if (Array.prototype.reduce)
        //    return str.split("").reduce(_h, hash);
        if (!str/* || str.length === 0*/) {// WARNING: '0' is falsy
            return 0;
        }
        var hash = 0, i = 0, il = str.length;
        for (; i < il; i++) {
            hash = ((hash<<5)-hash) + str.charCodeAt(i);
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }

    /**
     * Convenience function for testing if element.
     * @param {object}
     * @returns {boolean}
     *
    function isElement(obj) {
        try {
            //Using W3 DOM2 (works for FF, Opera and Chrom)
            return obj instanceof HTMLElement;
        }
        catch(e){
            //Browsers not supporting W3 DOM2 don't have HTMLElement and
            //an exception is thrown and we end up here. Testing some
            //properties that all elements have. (works on IE7)
            return (typeof obj==="object") &&
                (obj.nodeType===1) && (typeof obj.style === "object") &&
                (typeof obj.ownerDocument ==="object");
        }
    }

    /**
     * Parse a query string into an object.
     * @param {string} qstr
     * @returns object
     */
    function parseQuery(qstr) {
        var query = {},
            allParams = qstr.split('&'),
            i = 0, decode = decodeURIComponent, curParam;
        for (; i < allParams.length; i++) {
            curParam = allParams[i].split('=', 2);
            query[decode(curParam[0])] = decode(curParam[1]);
        }
        return query;
    }

})(window);

/**
 * Retrieve a URL parameter from location.search.
 * @param {string} name
 * @returns {*}
 */
function getUrlParam(name) {
    // First time this function is run, parse the URL
    var all = location.search.substr(1).split('&'),
        parsed = {},
        i = 0,
        curSplit;
    for (; i < all.length; ++i) {
        curSplit = all[i].split('=', 2);
        parsed[curSplit[0]] = curSplit.length < 2 ? '' : decodeURIComponent(curSplit[1].replace(/\+/g, " "));
    }
    // Then redefine the global function with a quick one that can access private variables
    return ((getUrlParam = function(k) {
        return parsed[k];
    }))(name);
}

/**
 * Loads advertpro media dynamically.
 * Is used on search.dmv.org, so must use rootUrl for URL prefix.
 * @param {jQuery|string} $container If null/missing, will use a dummy <div> in #advContent
 * @param {int} zid
 * @param {int} stateId
 * @param {{}} [customVars=undefined]
 */
function loadApro($container, zid, stateId, customVars) {
    if (typeof customVars !== 'string') {
        customVars = $.param(customVars || {});
    }
    // Use the shortcut jQuery function to shave a few bytes
    $.get(rootUrl + 'ajax/adv/zone', {
        zid: zid,
        stateId: stateId,
        transId: window.transID,
        // concatenate aproCV and customVars, trim ampersands
        customVarStr: ((window.aproCV || '') + '&' + customVars).replace(/(?:^&|&$)/, '')
    }, handle).fail(function (xhr) {
        handle({error:xhr});
    });
    function handle(res) {
        if (!res) {
            console.log("loadApro() received empty ad", zid);
            return;
        }
        if (res.error) {
            // attempt to log the error
            console.error('Failed loadApro()', zid, res.error);
            return;
        }
        // ensure argument was a jQuery object; if already is, will return self
        $container = $($container);
        if (!$container.length) {
            // create a new container if one isn't provided
            var advContent = $("#advContent");
            if (!advContent.length) {
                advContent = $('<div id="advContent">').appendTo("body");
            }
            $container = $("<div>").addClass("zid_"+zid)
                .appendTo(advContent);
        }
        $container.html(res);
    }
}

// this function is similar to changeStateLinks but uses the css class of <a> rather than ajax
function addStateToLinks() {
	var stateFolder = arrStateCodes[parseInt(readCookie('lastsel_state'))-1];
    if (stateFolder) {
        $('a.ssLink').each(function() {
            var path = this.pathname;
            if (!/^\/?(\w\w-[\w-]{4,}|\w{10}-dc)\//.test(path)) {
                this.pathname = (path[0]==='/' ? '/' + stateFolder : stateFolder + '/') + path;
            }
        });
    }
}

/**
 * Listen for element to come into view via scrolling down or being set visible.
 * Does NOT account for element height.
 *
 * @param {Element|jQuery} el Element to listen to
 * @param {Function} func Called when element is within 33% of viewport
 * @param {Number=0.33} boundary How much of a margin; use integer for pixels or fraction for screen percentage
 */
function runOnVisible(el, func, boundary) {
    el = el[0] || el;
    if (!el || !el.getBoundingClientRect) throw 'No element to listen to';
    if (!func) throw 'No function provided';
    boundary = boundary || .33;

    var evtList = 'scroll resize',
        $window = $(window),
        // Start with NaN because it ALWAYS produces false
        maxOffset = NaN,
        // Cached values used in callbacks
        computeTimeout, hasRun,
        p, mutObserver, intObserver;

    // If element is hidden, then try to listen to DOM changes that might cause it to become visible
    if (!el.offsetParent && typeof MutationObserver === 'function') {
        // Go up the tree until we find a visible element
        p = el;
        while (p = p.parentElement && !p.offsetParent);
        if (p) {// did we find one?
            // try to listen to PARENT, otherwise listen to element
            mutObserver = new MutationObserver(scrollCb);
            mutObserver.observe(p.parentElement && p.parentElement !== document ? p2 : p, { attributes: true });
        }
    }

    if (typeof IntersectionObserver === 'function') {
        intObserver = new IntersectionObserver(function(rects) {
            // gets called immediately no matter what
            for (var i = 0; i < rects.length; i++) {
                if (rects[i].isIntersecting) {
                    i = -1;
                    break;
                }
            }
            if (i !== -1) return;// nothing happened yet, is initial invocation
            if (el.offsetParent) {
                show();
            } else {
                // It's within scroll view, but not visible!
                // Resort to on-scroll listening
                $window.on(evtList, scrollCb);
                scrollCb();// setTimeout(calc) if not already
            }
        }, {rootMargin: boundary > 1 ? boundary + 'px' : (boundary*100) + '%'});
        intObserver.observe(el);

    } else {
        $window.on(evtList, scrollCb);
        calc();// trigger once, might .off('scroll') immediately
    }

    function calc() {
        var hasTimeout = computeTimeout,
            winHeight, pos;
        if (hasTimeout) {
            clearTimeout(computeTimeout);
        }

        if (!el.offsetParent || (!(pos = $(el).offset()))) {
            pos = NaN;// this will ALWAYS be false in scrollCb()! easy way to say "hidden"!
        } else {
            winHeight = $window.height();
            pos = +pos.top - winHeight - (boundary > 1 ? boundary : winHeight*boundary);
        }
        if (pos !== maxOffset) {
            maxOffset = pos;
            scrollCb();
        }

        if (hasTimeout) {
            computeTimeout = 0;
        }
    }

    // Very lightweight scroll function
    function scrollCb() {
        // Alternative: (this will trigger repaint!)
        // if (el.offsetParent// is visible?
        //     && el.getBoundingClientRect().top < relativeOffset + winHeight) {
        if ($window.scrollTop() > maxOffset) {
            show();
        } else if (!computeTimeout) {
            computeTimeout = setTimeout(calc, 350);
        }
    }

    function show() {
        if (!hasRun) {
            hasRun = 1;
            if (mutObserver) mutObserver.disconnect();// stop listening!
            if (intObserver) intObserver.disconnect();
            $window.off(evtList, scrollCb);
            clearTimeout(computeTimeout);
            setTimeout(func);
        }
    }
}


/**
 * Inject a template into containers based on rules.
 * Used by dfp('fillrules'...);
 *
 * @param {jQuery|String} container
 * @param {jQuery|String=} $tpl
 * @param {boolean=false} [restrictInsertion]
 * @returns {jQuery} Collection of cloned-and-inserted divs
 */
function fillContainer(container, $tpl, restrictInsertion) {
    // ensure is jQuery object
    var c = $(container);
    if (!c[0]) {
        console.error('fillContainer: No containers provided', container);
        return c;
    }
    var opt = {
        // Maximum number of ads to insert overall
        insertCountLimit: 50,

        // [windowHeight] is the most powerful metric because it will determine ad density
        //     It must be constrained to a range or you get strange results.
        // Minimum window-height (too small and you get WAY too many ads)
        windowHeightMin: 500,
        // Maximum window-height (too large and very few ads appear)
        windowHeightMax: 1000,
        // Add this many pixels to the window-height measurement AFTER limiting the range
        windowHeightPad: 600,

        // Assumed minimum ad-size for purposes of determining if there's enough space left to insert an ad
        minimumAdSize: 200,

        // Extra padding used in many ways
        extraBoundary: 340,

        // List of safe tags to descend into when trying to find an insertion point
        containerTags: [ 'P', 'DIV', 'ARTICLE', 'ASIDE', 'UL', 'OL' ],
        // List of tags to strictly skip when found (without creating "avoidance zone")
        skipTags: ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'],

        // Classes around which to create "avoidance zones" (will also be outright-skipped if encountered)
        avoidClasses: [
            'tipbox', 'tipsBox', 'contentTable', 'pullquote', 'bblquote', 'nav-tabs',
            'table', 'figure', 'dfp', 'overview',
            'zdzone', 'stdssside-side', 'stateList'
        ],
        // Extra padding multiplied times window-height to apply to "avoidance zones"
        avoidMultipliers: {
            zdzone: 1,
            tipbox: .3,
            tipsBox: .3,
            'stdssside-side': .3,
            'nav-tabs': .3,
            table: .3,
            dfp: .8
        },
        // Selectors around which to create "avoidance zones" (uses "normal" padding instead of multiplier)
        avoidSelectors: [ '#options-box', 'img', 'video', 'div[style]' ],
        // How many pixels to avoid to the top of an avoided element
        avoidBoxTop: 20,
        // How many pixels to avoid to the bottom of an avoided element
        avoidBoxBottom: 10

    };
    var opt_ = window.fillContainerDefaults;
    if (opt_) {
        var arrayKeys = ['avoidClasses', 'avoidSelectors', 'containerTags', 'skipTags'];
        var objectKeys = ['avoidMultipliers'];
        $.each(arrayKeys, function(i, k) {
            var arr = opt_[k];
            if (!arr) return;
            if (-1 === arr.indexOf('_')) {
                opt[k] = arr;
            } else {
                for (var j = 0; j < arr.length; j++) {
                    opt[k].push(arr[j]);
                }
            }
        });
        $.each(objectKeys, function(_, k) {
            $.extend(opt[k], opt_[k]);
        });

        for (var opt_k in opt_) {
            if (opt_.hasOwnProperty(opt_k)
                && -1 === arrayKeys.indexOf(opt_k)
                && -1 === objectKeys.indexOf(opt_k)
            ) {
                opt[opt_k] = opt_[opt_k];
            }
        }
    }

    container = c;
    $tpl = $($tpl || '<div>');

    var addedAds = [],
        $window = $(window),
        windowHeight = Math.max(opt.windowHeightMin, Math.min($window.height(), opt.windowHeightMax)) + opt.windowHeightPad,
        windowWidth = $window.width(),
        dummy = $('<div>').hide().appendTo('body'),
        bgBaseline = dummy.css('background-color'),// used in lvl() below
        borderBaseline = dummy.css('border-right-width'),
        curOffset = -99999,
        maxOffset = -99999,
        limit = opt.insertCountLimit,
        extraBoundary = opt.extraBoundary, // after ads are rendered by DFP, they will get bigger, so add extra spacing
        minimumAdSize = opt.minimumAdSize,
        avoidBoxes = [/*{ top:0, height: windowHeight }*/];

    if (restrictInsertion && windowWidth < 700 && windowWidth < windowHeight) {
        // on mobile, remove some extra screen-height padding
        windowHeight -= 500;
    }

    dummy.remove();
    dummy = null;

    var hasGroup = window.console && console.group,
        trace = hasGroup ? console.info.bind(console) : function(){};
    if (hasGroup) {
        console.groupCollapsed('fillContainer()');
        trace('Gap between ads should be:', windowHeight);
    }

    var excludeClasses = opt.avoidClasses;
    var containerTags = opt.containerTags;
    var skipTags = opt.skipTags;
    // Will implicitly skip:
    // <nav> tags (not in whitelist of containers)
    // <table> tags (not in whitelist of containers)
    buildAvoidBoxes();

    // attempt to set an initial maxOffset
    var last = container.slice(-1)[0].getBoundingClientRect();
    if (last) {
        maxOffset = Math.max(maxOffset, last.bottom - (windowHeight * .3) - extraBoundary);
    }

    container.each(function(i) {
        var self = this,
            rect = self.getBoundingClientRect(),
            err;
        if (!rect) err = 'Container is hidden';
        if (!rect.height) err = 'Container has no height';
        if (err) {
            console.error(err, self);
            return;
        }

        if (i === 0) curOffset = Math.max(curOffset, rect.top);
        maxOffset = Math.max(maxOffset, rect.bottom - (windowHeight * .5) - extraBoundary);
        trace('Entering container: %o %o curOffset:%d maxOffset:%d', self, rect, curOffset, maxOffset);
        lvl(self, 0);
    });

    if (hasGroup) {
        console.groupEnd();
    }

    return $(addedAds);

    function buildAvoidBoxes() {
        avoidBoxes = [];

        // get bounding rectangles of blacklist items that must be multiplied
        var selector = '';
        if (excludeClasses.length) {
            selector = '.' + excludeClasses.join(',.');
        }
        if (opt.avoidSelectors.length) {
            selector = (selector ? selector + ',' : '') + opt.avoidSelectors.join(',');
        }
        if (!selector) return;
        $(selector, container).each(function () {
            try {
                var b = this.getBoundingClientRect(),
                    list, heightAdjust, topAdjust;
                if (b) {// is visible?
                    // the original Rect object is immutable, so must clone it
                    b = { top: b.top - opt.avoidBoxTop, bottom: b.bottom + opt.avoidBoxBottom, el: this };
                    avoidBoxes.push(b);

                    // classList is not implemented in older browsers, but we're within a try-catch :)
                    list = this.classList;
                    topAdjust = heightAdjust = 0;
                    if (this.tagName === 'IMG' || this.tagName === 'VIDEO') {
                        topAdjust = 100 + extraBoundary;
                        heightAdjust = windowHeight * .3 + extraBoundary;
                    } else {
                        var k, mults = opt.avoidMultipliers;
                        for (k in mults) {
                            if (mults.hasOwnProperty(k)) {
                                if (list.contains(k)) {
                                    topAdjust = windowHeight * mults[k] + extraBoundary;
                                    break;
                                }
                            }
                        }
                    }

                    trace('Will skip BlacklistItem{top:%d, bottom:%d}, element:', b.top, b.bottom, this);
                    b.bottom += heightAdjust;
                    b.top -= topAdjust;
                }
            } catch (e) {
                console.log('Error calculating dimensions:', e, { b:b,list:list, el:this });
            }
        });
    }

    /**
     * Recursive height-segmentation search.
     * @param {Element} el
     * @param {Number} level
     */
    function lvl(el, level) {
        // Explicitly skip nested classes
        if (el.classList) {
            for (var k = 0; k < excludeClasses.length; k++) {
                if (el.classList.contains(excludeClasses[k])) {
                    trace('-skip, has excluded class:', excludeClasses[k], el);
                    return;
                }
            }
        }

        var isContainer = -1 < containerTags.indexOf(el.tagName);
        if (isContainer) {
            // is valid container, loop over children
            var curChild = el.firstElementChild;
            if (curChild) {
                do {
                    try {
                        // skip styled containers
                        var elStyle = getComputedStyle(curChild),
                            float = elStyle.getPropertyValue('float'),
                            bg = elStyle.getPropertyValue('background-color'),
                            border = elStyle.getPropertyValue('border-right-width');
                        if (float !== 'left' && float !== 'right' && (!bg || bg === bgBaseline) && (!border || border === borderBaseline)) {
                            lvl(curChild, level + 1);
                        } else {
                            trace('-skip, element is styled: %o float:%s bg:%s border:%s', curChild, float, bg, border, curChild);
                        }
                    } catch (e) {
                        console.log('Error calculating styles:', e, el, curChild);
                    }
                } while (curChild = curChild.nextElementSibling);
            }
        }
        // skip headers at all cost!
        else if (-1 < skipTags.indexOf(el.tagName)) {
            trace('-skip, is skipTag', el);
            return;
        }
        // in restricted mode, can only add to end/beginning of container
        else if (restrictInsertion) {
            trace('-skip, is not container', el);
            return;
        }

        if (!level) return;
        if (addedAds.length >= limit) return;

        var rect = el.getBoundingClientRect();
        if (!rect || !rect.height) {
            trace('-skip, invisible', el);
            return;
        }
        var rtop = rect.top,
            rbottom = rect.bottom;

        if (rtop + minimumAdSize > maxOffset) {// at the bottom of container
            trace('-skip, reached maxOffset:%d, too near bottom of page', maxOffset, el);
            return;
        }

        // Push boundary beyond any appropriate box
        var nextBoundary = curOffset + windowHeight;
        for (var i = 0; i < avoidBoxes.length; i++) {
            var box = avoidBoxes[i];
            if (nextBoundary + extraBoundary >= box.top && nextBoundary < box.bottom) {
                nextBoundary = box.bottom;
                trace('must avoid BlackListItem! moving boundary to', nextBoundary, box.el);
            }
        }

        if (nextBoundary > rbottom - 50) {
            trace('-skip, must go further {bottomOfElement:%d, nextBoundary:%d}', rbottom - 50, nextBoundary, el);
            return;// stop if near the bottom
        }
        trace('nextBoundary:', nextBoundary);

        var prevEl = el.previousElementSibling;
        if (prevEl && /H[123]/.test(prevEl.tagName)) {
            //TODO: it's ok to add to bottom of el if el is tall enough
            trace('-skip, is after header', el);
            return;// don't add something right after a heading
        }

        var newad;
        if (rtop > nextBoundary && !restrictInsertion) {
            newad = $tpl.clone()[isContainer ? 'prependTo' : 'insertBefore'](el);
            trace('ADDING, PASSED BOUNDARY', el);

        } else if (rbottom > nextBoundary) {
            // inject mid-content by splitting a text node

            if (isContainer && !restrictInsertion) {
                var midNode = el.childNodes[el.childNodes.length - 1];
                if (midNode && midNode.nodeType === 3) {
                    var len = midNode.textContent.length;
                    // assume 120 characters per line
                    if (windowWidth > 700 && len > 120) {
                        midNode.splitText(len - 120);
                        newad = $tpl.clone().insertAfter(midNode);
                        trace('ADDING MID-TEXT', el);
                    } else {
                        newad = $tpl.clone().insertBefore(midNode);
                        trace('ADDING BEFORE', el);
                    }
                    curOffset = rbottom;
                } else {
                    // assume next-iteration will prepend the ad
                }
            } else if (el.nextElementSibling) { // is not end of parent?
                newad = $tpl.clone().insertAfter(el);
                trace('ADDING AFTER', el);
            }

        } else {
            // not enough height accumulated to do anything yet
            // keep iterating
            trace('-skip, must go further', el);
        }

        if (newad) {
            addedAds.push(newad[0]);
            curOffset = rbottom;
            trace('new curOffset:%d maxOffset:%d, re-generating BlackList...', curOffset, maxOffset);
            buildAvoidBoxes();// re-gen avoid boxes
        }
    }
}

/**
 * Zip-to-State Link Popup.
 *
 * Uses www.dmv.org/zip2state.php to convert a URL into a state-specific URL.
 *
 * @author Lance Yamada
 * @date Oct 2014
 * @see classes/model/ContentAreas.php:1470
 */
function zip2StatePopup(e) {
    // Replace the init-container with the actual function and execute it the first time
    return (zip2StatePopup = function(e) {
        var $link = $(e.target);

        if (!$link.hasClass('z2sLink-active')) {
            var j = 0, opts = '';
            for (; j < arrStateName.length; j++) {
                opts += '<option value="'+(j+1)+'">' + arrStateName[j] + '</option>';
            }
            var buttonMarkup = '<input class="z2sPopupBtn" type="button" value="&rsaquo;"/>',
                $markup = $('<div class="z2sPopup clearfix">'
                    + '<div class="z2sZipChooser">'
                    +   '<input type="tel" class="zip2StateZip" size="5" maxlength="5" placeholder="Enter A Zipcode"/>'
                    +   buttonMarkup
                    + '</div>'
                    + '<div class="z2sStateChooser">'
                    +   '<select class="z2sSelect" onchange="$(this).siblings(\'input\').trigger(\'click\')">'
                    +     '<option value="">Choose Your State</option>'
                    +     opts
                    +   '</select>'
                    +   buttonMarkup
                    + '</div>'
                    + '</div>')
                    // Register "submit" handler
                    .on('click', '.z2sPopupBtn', onClick)
                    // Stop click events from bubbling up when open
                    .on('click', onClickStop);

            $('.zip2StateZip', $markup).on('keypress', onEnter);
            placeholderFix($markup);

            // the next two lines are so only the newly created popup is the only one on the page
            onClose();

            // Push to DOM
            $link.after($markup);
            var $markupWidth = $markup.outerWidth(),
                $windowWidth = $(window).width(),
                fudge = 50; // is the width difference for iOS/Android devices to put the box back into view
            if (Math.round($markup.offset().left) + fudge + $markupWidth >= $windowWidth) {
                // make some corrections if we detected that the popup will hit the edge of the screen
                $markup.css('left', $windowWidth - $markupWidth - fudge);
            }

            // Only focus the first input if is NOT IE, IE does bad things with focus
            if (!/MSIE\b/.test(navigator.appVersion) && !/Trident.*rv[ :]*11\./.test(navigator.appVersion)) {
                $('input:first', $markup).focus();
            }

            // Omniture event
            ac(e.target, 'misc_z2s_popup');

            // Add single use body click handler to remove zip2state widgets
            $(document).one('click touchmove', onClose);

            $link.addClass('z2sLink-active'); // prevents link from creating more popups
        }

        e.preventDefault(); // this comes from onclick so do not navigate to href
        e.stopPropagation(); // this prevents the body click handler from erasing this popup

    })(e); // run first time

    function onClose() {
        $('.z2sPopup').remove();
        $('.z2sLink').removeClass('z2sLink-active');
    }

    function onEnter(event) {
        // handle hitting enter after zipcode for zip2state widget
        if (event.keyCode == 13) {
            event.preventDefault();
            $(event.target).parent().find('input[type=button]').trigger('click'); // click closest button
        }
    }

    function onClickStop(e) {
        // prevent click events from bubbling up and triggering onClose()
        e.stopPropagation();
    }

    function onClick() {
        var $btn = $(this),
            val = $btn.siblings('.zip2StateZip').val(),
            link = $btn.parent().parent().siblings('a.z2sLink-active'),
            finalDestination = $(link).attr('href'); // needed link for ac(), so now getting href from it

        if (/^\d{5}/.test(val)) {
            // don't leak PII to access logs! finalDestination += '&zip=' + val;
            // Use cookie instead, just like zip2state.php
            document.cookie = 'zip=' + val.substr(0, 5) + ';path=/;domain=' + getCookieDomain();
            ac(link, 'misc_z2s_text');
        }
        else {
            val = $btn.siblings('.z2sSelect').val();
            if (val) {
                finalDestination += '&state=' + val;
                ac(link, 'misc_z2s_dropdown');
            }
            else {
                alert('Please enter a valid zipcode or choose a state');
                return;
            }
        }

        onClose(); // force-remove popups
        window.location.href = finalDestination;
    }
}
