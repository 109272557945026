/**
 * RequireJS-style external resource loader.
 *
 * Intended to be used in AdvertPro media when using remote Ad Server files that might
 * end up getting referenced multiple times.
 *
 * Use it to load remote assets and wait for the asset to download
 *   require('//google.com/dfp.js', function() {
 *     dfp.loadAd({});
 *   });
 * OR
 *   <script>require('//google.com/dfp.js')</script>
 *   <script>
 *     // script separation is necessary to ensure file is downloaded
 *     // before script is run
 *     dfp.loadAd({});
 *   </script>
 *
 * Use it to load asynchronous scripts
 *   require('//myhost/my_init_routine.js', true);
 *
 * One caveat -- this will inject the JavaScript asset into the top of the <body> tag,
 * which means this can only be used by scripts loaded inside the <body> or after DomReady.
 *
 * @date Aug 2014
 * @author Jesse Decker <jesse.decker@dmv.org>
 * @see https://github.com/requirejs/requirejs/blob/master/require.js
 */
(function(window) {
    // Closure to hide variables
    "use strict";

    var document = window.document,
        loaded = {},   // loaded sources
        handlers = {}, // handlers and required sources
        //Oh the tragedy, detecting opera. See the usage of isOpera for reason. (from RequireJS source)
        isOpera = typeof opera !== 'undefined' && opera.toString() === '[object Opera]',
        origRequire;

    // map of key-to-URL
    require.map = {
        // Google AdSense for Publishers
        dfp: '//www.googletagservices.com/tag/js/gpt.js',
        prebid: '/res/prebid-1.x.js?undefined',
        promises: '/res/polyfill-promise.js?35bdb9a4',
        sonobi: '//mtrx.go.sonobi.com/morpheus.dmv.js',
        twitter: '//platform.twitter.com/widgets.js',
        fb: '//connect.facebook.net/en_US/sdk.js',
        gapi: '//apis.google.com/js/api.js',
        jquery: function() {
            // fast-return if any version of jQuery has already been defined
            return window.jQuery || require('//ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js');
        }
    };
    // map of whether libraries are loaded/being loaded (used by adblockDetector right now)
    require.loaded = loaded;
    //require.trigger = triggerLoad; // allow manually triggering "is loaded" NEVER USED!
    if (origRequire && origRequire.q && origRequire.q.length) {
        for (var q=origRequire.q,i=0; i<q.length; i++) {
            require.apply(null, Array.prototype.slice.call(q[i]));// convert arguments object into array
        }
    }
    window.require = require;

    /**
     * Ensure a file is downloaded.
     *
     * @param libs {Array|string} String or list of libraries (mapped or full URLs)
     * @param init= {function|boolean} Whether to run synchronously. A provided function will be run onload.
     * @returns {boolean} Whether the file has been downloaded already
     */
    function require(libs, init) {
        var canRun = true, alias, src, i, isError;
        if (typeof libs === 'string') {
            libs = [libs];
        }
        for (i=0; i < libs.length; i++) {
            alias = ''+libs[i];// force string-type
            if (isError = (alias.indexOf('err:') === 0)) {
                alias = alias.substr(4);
            }
            // Turn alias into URL
            src = require.map[alias];
            if (src && typeof src !== 'string') {
                canRun = canRun && src(alias);// must be a function
                continue;
            }
            // Fix protocol-relative URLs so that they continue to work on old IE
            src = libs[i] = (src || alias).replace(/^\/\//, location.protocol + '//');
            if (isError) {
                libs[i] = 'err:' + src;
            } else if (typeof loaded[src] === 'undefined') {
                try {
                    // Look in the DOM for a hard-coded or previously-added <script> element
                    // Compatible with modern browsers, IE9+, FF30+, Saf5+
                    var el = document.querySelector('script[src^="'+src+'"]');
                    if (!el && src.indexOf('//') > 0) {
                        // Look for protocol-agnostic too
                        var u = src.replace(/^.+?\//, '/');
                        el = document.querySelector('script[src^="'+u+'"],script[src^="http:'+u+'"],script[src^="https:'+u+'"]');
                    }
                    // If we found it and it's not async/defer, then it's already been run/parsed
                    if (el && !el.async && !el.defer) {
                        loaded[src] = true;
                    }
                } catch (e) {
                    // lots of reasons might fail, just forget it
                }
            }
            if (!loaded[src]) { // not all libs loaded
                canRun = false;
                if (!isError && loaded[src] !== false) { // is undefined?
                    // load the lib
                    loaded[src] = false;
                    loadJs(src, alias);
                }
            }
        }
        // have an init function and can't call it yet
        if (typeof init === 'function') {
            if (canRun) {
                // Run the handler from the event dispatching chain instead of directly.
                setTimeout(init, 0); // run ASAP after this event
            } else {
                for (i=0; i < libs.length; i++) {
                    src = libs[i];
                    if (!loaded[src]) {
                        (handlers[src] || (handlers[src] = [])).push([init, libs]);
                    }
                }
            }
        }

        return canRun;
    }

    function loadJs(src, name) {
        var el = document.createElement('script');
        el.type = 'text/javascript';
        el.async = true;
        el.setAttribute('data-moduleurl', src);
        if (name) {
            el.setAttribute('data-modulename', name);
        }

        /* Moving to RequireJS-style which uses addEventListener() instead of legacy el.onload
        // Attach handlers for all browsers, modeled after jQuery's $.getScript()
        el.onload = el.onreadystatechange = function() {
            var readyState = this.readyState;
            if ( !done && (!readyState ||
                readyState === "loaded" || readyState === "complete")
            ) {
                // use local variable for status (yes, this is separate from loaded[name], maybe shouldn't?)
                done = true;
                // Handle memory leak in IE
                el.onload = el.onreadystatechange = null;
                //body.removeChild(el); << might cause race condition where script is downloaded but not executed
                // Handle load
                triggerLoad(name);
            }
        };
        /**/

        // From RequireJS source:
        //
        //Set up load listener. Test attachEvent first because IE9 has
        //a subtle issue in its addEventListener and script onload firings
        //that do not match the behavior of all other browsers with
        //addEventListener support, which fire the onload event for a
        //script right after the script execution. See:
        //https://connect.microsoft.com/IE/feedback/details/648057/script-onload-event-is-not-fired-immediately-after-script-execution
        //UNFORTUNATELY Opera implements attachEvent but does not follow the script
        //script execution mode.

        if (el.attachEvent &&
            //Check if node.attachEvent is artificially added by custom script or
            //natively supported by browser
            //read https://github.com/requirejs/requirejs/issues/187
            //if we can NOT find [native code] then it must NOT natively supported.
            //in IE8, node.attachEvent does not have toString()
            //Note the test for "[native code" with no closing brace, see:
            //https://github.com/requirejs/requirejs/issues/273
            !(el.attachEvent.toString && el.attachEvent.toString().indexOf('[native code') < 0) &&
            !isOpera) {
            //Probably IE. IE (at least 6-8) do not fire
            //script onload right after executing the script, so
            //we cannot tie the anonymous define call to a name.
            //However, IE reports the script as being in 'interactive'
            //readyState at the time of the define call.
            //useInteractive = true; << not using AMD.define, so don't have to deal with anonymous defines

            el.attachEvent('onreadystatechange', onScriptLoad);
            //It would be great to add an error handler here to catch
            //404s in IE9+. However, onreadystatechange will fire before
            //the error handler, so that does not help. If addEventListener
            //is used, then IE will fire error before load, but we cannot
            //use that pathway given the connect.microsoft.com issue
            //mentioned above about not doing the 'script execute,
            //then fire the script load event listener before execute
            //next script' that other browsers do.
            //Best hope: IE10 fixes the issues,
            //and then destroys all installs of IE 6-9.
            //node.attachEvent('onerror', context.onScriptError);
        } else {
            el.addEventListener('load', onScriptLoad, false);
            el.addEventListener('error', onScriptLoad, false);
        }

        el.src = src;// to be safe, should be after handlers are set

        loaded[name] = false;
        // <head> is safer than <body>: If executed within the <head>, document.body might not have been initialized
        // Since everything is async it shouldn't matter.
        (document.head || document.getElementsByTagName('head')[0]).appendChild(el);
    }

    function onScriptLoad(evt) {
        //Using currentTarget instead of target for Firefox 2.0's sake. Not
        //all old browsers will be supported, but this one was easy enough
        //to support and still makes sense.
        var node = evt.currentTarget || evt.srcElement,
            moduleSrc = node.getAttribute('data-moduleurl'),
            readyState = node.readyState,
            type = evt.type,
            isError = type === 'error';
        if (isError || type === 'load' || readyState === 'complete' || (navigator.platform !== 'PLAYSTATION 3' && readyState === 'loaded')) {
            removeListeners(node);
            if (isError) {
                isError = 'Could not load module: ' + ((type = node.getAttribute('data-modulename')) && type !== moduleSrc ? '[alias:' + type + '] ': '') + moduleSrc;
                // AdBlockers trigger a TON of these, let's skip this
                //if (window.Errsnag) Errsnag(isError);
                if (window.console) console.error(isError);
                else throw isError;
                moduleSrc = 'err:' + moduleSrc;
            }
            triggerLoad(moduleSrc);
        }
    }

    /**
     * @param {Node} node
     */
    function removeListeners(node) {
        //Favor detachEvent because of IE9
        if (node.detachEvent && !isOpera) {
            //Probably IE. If not it will throw an error, which will be useful to know.
            node.detachEvent('onreadystatechange', onScriptLoad);
        } else {
            node.removeEventListener('load', onScriptLoad, false);
            node.removeEventListener('error', onScriptLoad, false);
        }
    }

    function triggerLoad(src) {
        loaded[src] = true;
        var h = handlers[src], libs, i = 0, j;
        if (h) {
            // remove the reference, save memory
            delete handlers[src];
            // loop over all registered handlers
            for (; i < h.length; i++) {
                libs = h[i][1];
                // ensure all required libs are loaded
                for (j=0; j < libs.length; j++) {
                    if (!loaded[libs[j]]) {
                        j = -1;
                        break;
                    }
                }
                if (j > -1) {
                    // Run the handler from the event dispatching chain instead of directly.
                    setTimeout(h[i][0], 4); // run ASAP after this event
                }
            }
        }
    }

})(this);
