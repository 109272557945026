/*! global window, location, FB */
/**
 * Authentication service JavaScript implementation.
 *
 * @author Lance Yamada
 * @author Jesse Decker
 * @since Oct-2018
 */
'use strict';
(function (window) {
    'use strict';

    // CONSTANTS
    var apiBase = '/ajax/auth/';
    var localStoragePrefix = 'dmvorg_auth_';

    var auth = {
            fbAppId: '183805261662234',
            gClientId: '953617598741-3jg51oop12v0o6lga2eubo8l4dsh95o9.apps.googleusercontent.com',
        // Login platforms
            getPlatforms: platform_iterateNames,
            init: platform_makeExecutor('init'),
            login: platform_makeExecutor('login'),
            logout: logoutCurrentUser,
        // User methods
            getUser: getUser,
            putData: userData_save,
            getData: userData_query,
        // UI methods
            showLogin: loginUi_show,
            hideLogin: loginUi_hide
        },
        userDef = null,
        handlers = {};

    // Expose publicly
    window.auth = auth;


    //
    // Facebook API Login
    //
    platform_createHandler('fb', {
        /**
         * @param {function} cb
         */
        init: function (cb) {
            require('fb', function () {
                FB.init({
                    appId: auth.fbAppId,
                    version: 'v2.8',
                    status: true,
                    cookie: true,
                    xfbml: false
                });

                auth.connected = false;
                FB.getLoginStatus(function (response) {
                    if (response.status === "connected") {
                        auth.connected = true;
                    }
                    cb(auth.connected);
                });
            });
        },
        /**
         * @param {function} cb
         */
        login: function (cb) {
            function afterLogin() {
                FB.getLoginStatus(function (response) {
                    if (response.status === "connected") {
                        validatePlatformLogin('fb', response.authResponse.accessToken).then(function() {
                            if (cb && getUser().email) {
                                // API /me call has run
                                cb(response);
                                cb = null;
                            }
                        });
                        FB.api('/me', {fields: 'first_name,email,picture'}, function (response) {
                            setUser({
                                firstname: response.first_name,
                                email: response.email,
                                icon_url: response.picture.data.url,
                                platform: 'fb'
                            });
                            if (cb && getUid()) {
                                // validatePlatformLogin() has completed
                                cb(response);
                                cb = null;
                            }
                        });

                    } else {
                        cb(response);
                    }
                });
            }

            if (auth.connected) {
                afterLogin();
            } else {
                // FB.Event.subscribe('auth.login', function (response) {
                //     if (response.status === "connected") {
                //         afterLogin();
                //     }
                // });
                FB.login(function (response) {
                    if (response.status === 'connected') {
                        afterLogin();
                    } else {
                        cb(response);
                    }
                }, {scope: 'email'});
            }
        },
        /**
         * @param {function} cb
         */
        logout: function(cb) {
            FB.logout(cb);
        }
    });


    //
    // Google OAuth Handler
    //
    platform_createHandler('google', {
        /**
         * @param {function} cb
         */
        init: function (cb) {
            require('gapi', function () {
                gapi.load('auth2', function () {
                    gapi.auth2.init({
                        client_id: auth.gClientId,
                        scope: 'profile email'
                    }).then(function () {
                        var auth2 = gapi.auth2.getAuthInstance(),
                            user = auth2.currentUser.get();

                        auth.connected = false;
                        if (user) {
                            var resp = user.getAuthResponse();
                            if (resp && resp.id_token) {
                                auth.connected = true;
                            }
                        }
                        cb(auth.connected);
                    });
                });

            });
        },
        /**
         * @param {function} cb
         */
        login: function (cb) {
            function afterLogin() {
                var user = auth2.currentUser.get(),
                    resp = user.getAuthResponse(),
                    profile = user.getBasicProfile();
                setUser({
                    firstname: profile.getGivenName(),
                    email: profile.getEmail(),
                    icon_url: profile.getImageUrl(),
                    platform: 'google'
                });
                validatePlatformLogin('google', resp.id_token)
                    .then(cb, cb);
            }
            var auth2 = gapi.auth2.getAuthInstance();

            if (auth.connected && auth2.currentUser && auth2.currentUser.get()) {
                afterLogin();
            } else {
                // Listen for sign-in state changes.
                // auth2.isSignedIn.listen(function (isSignedIn) {
                //     if (isSignedIn) {
                //         afterLogin();
                //     }
                // });
                auth2.signIn({
                    scope: 'profile email'
                }).then(function(currentUser) {
                    if (currentUser) afterLogin();
                    else cb(currentUser);
                });
            }
        },
        /**
         * @param {function} cb
         */
        logout: function(cb) {
            var auth2 = gapi.auth2.getAuthInstance();
            auth2.signOut().then(cb);
        }
    });

    /**
     * Register handler.
     * @param {string} name
     * @param {{}} opts
     * @internal
     */
    function platform_createHandler(name, opts) {
        handlers[name.toLowerCase()] = opts;
    }

    /**
     * Enumerate the platforms we currently authenticate against.
     * @return string[]
     */
    function platform_iterateNames() {
        var list = [], k;
        for (k in handlers) {
            if (handlers.hasOwnProperty(k)) {
                list.push(k);
            }
        }
        return list;
    }

    /**
     * Create executor proxy.
     *
     * @param {string} action
     * @returns {function(string, function=): Promise}
     */
    function platform_makeExecutor(action) {
        return function(platform, cb) {
            return platform_doAction(platform, action, cb);
        }
    }

    /**
     * Call platform action.
     *
     * @param {string} platform
     * @param {string} action
     * @param {function} [cb=]
     * @returns {Promise}
     */
    function platform_doAction(platform, action, cb) {
        var handler = handlers[platform.toLowerCase()];
        if (!handler) {
            console.error('auth.js: platform not found:', platform);
            if (cb) setTimeout(cb);// be consistent, never call synchronously
            return Promise.reject('platform not found: ' + platform);
        }
        var func = handler[action];
        if (!func) {
            console.error('auth.js: platform[' + platform + '] has no action:', action);
            if (cb) setTimeout(cb);// be consistent, never call synchronously
            return Promise.reject('platform[' + platform + '] has no action:' + action);
        }

        return new Promise(function (resolve, reject) {
            function doAction() {
                var isPromise = false;
                var p = func.call(handler, function(data) {
                    if (!isPromise) {
                        // is callback-style
                        resolve(data);
                        if (cb) cb(data);
                    }
                });
                if (p && p.then) {
                    // is Promise-style
                    p.then(resolve, reject);
                    if (cb) p.then(cb, cb);
                    isPromise = true;
                }
            }

            if (action !== 'init' && typeof auth.connected === 'undefined') {
                // ensure init() is called on platform before anything else happens
                platform_doAction(platform, 'init').then(doAction, doAction);
            } else {
                doAction();
            }
        });
    }

    function validatePlatformLogin(platform, token) {
        return ajaxJson(apiBase + 'login', {
            platform: platform,
            token: token
        }).then(function (data) {
            storage_set('uid', data.uid);
            setUser({ platform: platform });
        });
    }

    /**
     * Delete local user data and clear server cookies with AJAX request.
     *
     * @param {function} [cb]
     * @returns {Promise}
     */
    function logoutCurrentUser(cb) {
        return new Promise(function(resolve, reject) {
          storage_remove('uid');
          var userData = getUser();
          setUser(null);
          if (userData.platform) {
            platform_doAction(userData.platform, 'logout');
          }

          var xhr = ajaxJson(apiBase + 'logout');
          xhr.then(resolve, reject);
          if (cb) xhr.then(cb);
        });
    }


    /**
     * Merge provided data into the user definition.
     *
     * @param {{}|null} userData
     * @returns {boolean} Success
     */
    function setUser(userData) {
        if (!userData) {
            return storage_remove('user');
        }
        var curData = getUser();
        if (userData.email) {
            // this is a new login
            userData.last_login = Date.now();
            userData.logged_in = true;// internal key
        }
        extend(curData, userData);
        return storage_set('user', curData);
    }

    /**
     * Retrieve the current user definition.
     * This will return an object, but the object modifications will not be tracked.
     *
     * @returns {{}|null}
     */
    function getUser() {
        return userDef
            || (userDef = storage_get('user'))
            || { logged_in: false };
    }

    /**
     * Retrieve the User ID for the last-verified user.
     *
     * @returns {number} UID, 0 if not logged in
     */
    function getUid() {
        var uid = storage_get('uid');
        if (uid) {
            var int = parseInt(uid, 10);
            if (int > 0) return int;
            else console.error("auth.js: cannot parse uid:", uid);
        }
        return 0;
    }

    /**
     * Save data for the currently logged-in user.
     *
     * @param {string|Array} namespace
     * @param {string=} event
     * @param {{}} data
     * @return {Promise<{}>}
     */
    function userData_save(namespace, event, data) {
        var eventList;
        if (arguments.length === 1) {
            if (!Array.isArray(namespace)) throw 'First argument must be array';
            eventList = namespace;
        } else {
            if (arguments.length === 2) {
                data = event;
                event = '';
            }
            if (typeof data !== 'string') data = JSON.stringify(data);
            eventList = [{
                namespace: namespace,
                event: event,
                value: data
            }]
        }

        var uid = getUid();
        if (!uid) return Promise.resolve(null);

        return ajaxJson(apiBase + 'put', {
            uid: uid,
            events: JSON.stringify(eventList)
        }, 'POST', 2).then(null, function(xhr) {
            var json = xhr.responseJson || xhr;
            console.error("auth.setData: Query failed:", json.error || xhr, eventList);
            return json;
        });
    }

    /**
     * Retrieve saved events for the current user.
     *
     * @param {{}} queryObj
     * @return {Promise<{}>}
     */
    function userData_query(queryObj) {
        // {
        //         ns: '',
        //         event: [],
        //         DO WE NEED THIS? it must be implemented here: keys: [],
        //         limit: 5,
        //         after: '-30 days',
        //         after: Date.now()/1000 - 60*60*24*30 << within 30 days
        //     }

        var uid = getUid();
        if (!uid) return Promise.resolve(null);

        return ajaxJson(apiBase + 'get', {
            uid: uid,
            query: JSON.stringify(queryObj)
        }, 'POST', 3).then(function(data) {
            if (!data.data) {
                console.error("auth.getData: bad response from server:", data);
                throw data;
            }

            var d = data.data,
                arr = d && typeof d.length === 'number' ? d : [d],
                i = 0, cur;
            for (; i<arr.length; i++) {
                try {
                    cur = arr[i];
                    cur.value = JSON.parse(cur.value);
                } catch (e) {
                    console.error('auth.getData: JSON.parse failed:', e);
                }
            }

            return d;
        }, function(xhr) {
            var json = xhr.responseJson || xhr;
            console.error("auth.getData: Query failed:", json.error || xhr, queryObj);
            return json;
        });
    }


    //
    // Utility functions
    //


    /**
     * Safely retrieve a JSON or string value from localStorage.
     *
     * @param {string} key
     * @returns {false|*} False on failure
     * @internal
     */
    function storage_get(key) {
        try {
            var val = window.localStorage.getItem(localStoragePrefix + key);
            return JSON.parse(val);
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    /**
     * Safely save a object (as JSON) or string into localStorage.
     *
     * @param {string|{}} keyOrMap
     * @param {string|{}=} value
     * @returns {boolean} Success
     * @internal
     */
    function storage_set(keyOrMap, value) {
        var vals;
        if (typeof keyOrMap === 'object') vals = keyOrMap;
        else {
            vals = {};
            vals[keyOrMap] = value;
        }

        var lastErr;
        for (var k in vals) {
            if (!vals.hasOwnProperty(k)) continue;
            try {
                var cur = vals[k];
                cur = JSON.stringify(cur);
                window.localStorage.setItem(localStoragePrefix + k, cur);
            } catch (e) {
                console.error(e);
                lastErr = e;
            }
        }
        return !lastErr;
    }

    /**
     * Safely remove a key from localStorage.
     *
     * @param {string} key
     * @returns {boolean} Success
     */
    function storage_remove(key) {
        try {
            window.localStorage.removeItem(localStoragePrefix + key);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    /**
     * @param {Object} a
     * @param {Object} b
     * @returns {Object} a
     */
    function extend(a, b) {
        if (b && typeof b === 'object')
            for (var i in b)
                if (b.hasOwnProperty(i)) a[i] = b[i];
        return a;
    }

    /**
     * Create an XMLHttpRequest. It has a Promise property that gets resolved/rejected when the request completes.
     *
     * @returns {XMLHttpRequest}
     */
    function ajax() {
        var req = new XMLHttpRequest(),
            // set async
            resolve, reject;

        function done() {
            if (!req) {
                return;// has already run once
            }
            if (!resolve) {
                // must wait for Promise to initialize
                setTimeout(done, 1);
                return;
            }
            if (req.status === 200) {
                resolve(JSON.parse(req.responseText));
            } else {
                reject(req);
            }
            // cleanup/remove references
            req.onreadystatechange = null;
            req = null;
        }
        req.onreadystatechange = function () {
            if (req.readyState === 4) {
                done(req);
            }
        };
        req.promise = new Promise(function(res, rej) {
            resolve = res;
            reject = rej;
        });
        return req;
    }

    /**
     * Make an AJAX-JSON request.
     *
     * @param {string} url
     * @param {{}} [data={}]
     * @param {string} [method=POST]
     * @param {number} [attemptLimit=1]
     * @return {Promise<{}>}
     */
    function ajaxJson(url, data, method, attemptLimit) {
        var attemptNumber = 0;
        attemptLimit = attemptLimit || 1;

        function start() {
            var req = ajax();
            req.open(method || 'POST', url);
            req.setRequestHeader('Accept', 'application/json, */*');
            req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

            attemptNumber++;
            if (attemptNumber > 1) {
                if (data) data._retry = attemptNumber - 1;
                req.setRequestHeader('X-Retry-Attempt', data._retry);
            }

            var d = null;
            if (data) {
                d = '';
                for (var i in data) {
                    if (data.hasOwnProperty(i)) {
                        d += (d.length ? '&' : '') +
                            encodeURIComponent(i) + '=' + encodeURIComponent(data[i]);
                    }
                }
            }

            req.send(d);
            return req;
        }

        return new Promise(function(resolve, reject) {
            start().promise.then(function(req) {
                resolve(req);
            }, function(req) {
                // for timeouts or 500 responses, we can safely try again
                if ((req.status === 0 || req.status > 499) && attemptNumber < attemptLimit) {
                    console.error("auth.makeJsonReq: retrying", url);
                    start();
                } else {
                    if (req && req.responseText) {
                        try {
                            req.responseJson = JSON.parse(req.responseText);
                        } catch (e) {
                            console.error(e);
                        }
                    }
                    console.error(req);
                    reject(req);
                }
            });
        });
    }


    //
    // UI functions
    //

    function loginUi_show() {
        var $modal = $('<div id="modal-auth-login" style="text-align:center;color:#666;padding:2em;">Loading...</div>')
            .modalInit()
            .attr('id', 'modal-auth')
            .on('hidden.bs.modal', function () {
                if ($modal) {
                    $modal.remove();
                    $modal = null;
                }
            })
            .modal();
        $.get(apiBase + 'form', function (html) {
            $('#modal-auth-login', $modal).replaceWith(html);
        });
        return $modal;
    }

    function loginUi_hide() {
        $('#modal-auth').modal('toggle');
    }

})(window);
